import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";
import { IGame } from "../../../../models/IGame";
import { IGameDetail } from "../../../../models/IGameDetail";

let name: string = "stCalendarGame";

interface IGameResolver {
  game: IGame;
  gameDetail: IGameDetail;
}

@Component({
  selector: name,
  template,
})
export class TeamCalendarGame {
  static Name: string = name;
  static $inject = [];

  @Input()
  resolve: IGameResolver;

  @Output()
  close: ({ $value }) => {};

  @Output()
  dismiss: ({ $value }) => {};
  game: IGame;
  gameDetail: IGameDetail;

  /**
   *
   */
  constructor() {}

  save() {
    this.close({ $value: this.gameDetail });
  }

  cancel() {
    this.dismiss({ $value: "cancel" });
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    if (changesObj.resolve && this.resolve) {
      this.game = this.resolve.game;
      this.gameDetail = this.resolve.gameDetail || ({} as IGameDetail);
      this.gameDetail.gameId = this.stripGameKey(this.game.id);
    }
  }

  stripGameKey(id: string) {
    return id.substr("game:".length);
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
