import { UIComponent } from "../../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./list.template.html";
import { ILeague } from "../../../../models/ILeague";
import { LeagueService } from "../../../../services/LeagueService";
import { IRegistrationProgram } from "../../../../models/IRegistrationProgram";

const name = "stRegistrationList";

@Component({
  selector: name,
  template,
})
export class RegistrationList extends UIComponent {
  static $inject: string[] = ["LeagueService"];
  static Name: string = name;
  public registrationPrograms: IRegistrationProgram[];

  @Input() league: ILeague;

  constructor(private leagueService: LeagueService) {
    super();
  }

  $onInit() {}

  $onChanges(changeObj) {
    if (this.league) {
      this.leagueService
        .getRegistrationPrograms(this.league)
        .then((programs) => {
          this.registrationPrograms = programs;
        });
    }
  }
}
