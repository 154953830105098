import { IAgeGroup } from '../models/IAgeGroup';
import { IDivision } from '../models/IDivision';
import { IFilter } from '../models/IFilter';
import { ILeagueScope } from '../models/ILeagueScope';
import { IRootStateService } from '../models/IRootStateService';
import { IService } from 'restangular';
import { ITeamLevel } from '../models/ITeamLevel';
import { ITeamRegistration } from '../models/ITeamRegistration';
import { StateService } from '@uirouter/angularjs';

export class AssignDivisionsController {
    static $inject = ['$rootScope', '$state', '$scope', 'Restangular', '$timeout'];
    ageGroups: IAgeGroup[];
    ageGroup: IAgeGroup;
    teamLevels: ITeamLevel[];
    teamLevel: ITeamLevel;
    registrations: ITeamRegistration[];
    divisions: IDivision[];

    constructor(private $rootScope: IRootStateService, private $state: StateService, private leagueScope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService) {
        let me = this;
        me.leagueScope.$watch(
            function() { return me.leagueScope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.refresh();
                }
            },
            true
        );
    }

    refresh() {
        let me = this;

        let svc = this.Restangular.one('seasons', this.leagueScope.league.currentSeason.id).all('agegroups');
        svc.getList<IAgeGroup>()
            .then(function(agegroups) {
                me.ageGroups = agegroups;
            });
    }

    load() {
        let me = this;
        let filter: IFilter = {
            fields: { name: true, id: true, teamId: true, ageGroupId: true, teamlevelId: true, divisionId: true },
            include: [
                {
                    relation: 'registrations',
                    scope: {
                        fields: ['id', 'teamId', 'ageGroupId', 'teamlevelId', 'divisionId'],
                        include:
                        [
                            {
                                relation: 'team',
                                scope: {
                                    fields: ['id', 'name', 'urlKey']
                                }
                            },
                            {
                                relation: 'ageGroup',
                                scope: {
                                    fields: ['id', 'name']
                                }
                            },
                            {
                                relation: 'teamLevel',
                                scope: {
                                    fields: ['id', 'name']
                                }
                            },
                            {
                                relation: 'division',
                                scope: {
                                    fields: ['id', 'name']
                                }
                            }
                        ]
                    },

                },
                'divisions'
            ]

        };

        let svc = this.Restangular.one('agegroups', this.ageGroup.id).all('teamlevels');
        svc.getList<ITeamLevel>({ filter })
            .then(function(teamLevel) {
                me.teamLevels = teamLevel;

            });
    }

    save(registration: ITeamRegistration) {
        let me = this;
        me.Restangular.one('teamregistrations', registration.id).customPATCH({ divisionId: registration.division.id });
    }

}
