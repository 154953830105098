import { ICollection, IService } from 'restangular';
import { IFilter } from '../models/IFilter';
import { ILeagueScope } from '../models/ILeagueScope';
import { IPage } from '../models/IPage';
import { StateService } from '@uirouter/angularjs';

export class PagesController {
    static $inject = ['$state', '$scope', 'Restangular', '$timeout', 'Upload'];
    newPage: IPage;
    dataService: ICollection;
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService, private Upload: angular.angularFileUpload.IUploadService) {
        this.newPage = {} as IPage;
        this.dataService = this.Restangular.one('leagues', $scope.league.id).all('pages');
        let me = this;
        this.dataService.getList()
            .then(function(pages) {
                me.$scope.league.leagueData.pages = pages;
            });
    }

    add(page: IPage) {
        let me = this;
        let filter: IFilter = {
            include: ['image']
        };
        me.dataService.post(page)
            .then(function(carousel) {
                me.Restangular.one('leagues', me.$scope.league.id).one('pages', carousel.id).get({ filter })
                    .then(function(response: IPage) {
                        me.$scope.league.leagueData.pages.push(response);
                    });
            });
    }

    edit(page: IPage) {
        this.setEditing(page, true);
    }

    setEditing(page: IPage, editing: boolean) {
        if (!page.__uistate) page.__uistate = { editing: false };
        page.__uistate.editing = editing;
    }

    delete(page: IPage) {
        let me = this;
        me.Restangular.one('leagues', me.$scope.league.id).one('pages', page.id).remove()
            .then(function(result) {
                let idx = me.$scope.league.leagueData.pages.indexOf(page);
                me.$scope.league.leagueData.pages.splice(idx, 1);
            });
    }

    save(page: IPage) {
        let me = this;
        me.Restangular.one('leagues', me.$scope.league.id).one('pages', page.id).customPUT(page)
            .then(function(response) {
                me.setEditing(page, false);
            });
    }
}
