import { Component, Input, Output } from "angular-ts-decorators";
import { Wizard } from "./wizard";
import template from "./step.html";

@Component({
  selector: "step",
  require: {
    wizard: "^wizard",
  },
  transclude: true,
  template,
})
export class Step {
  wizard: Wizard;
  selected: boolean;
  index: number;
  completed: boolean;

  @Input("@") public title: string;

  @Input() public canExit: boolean;

  @Output() public onEnter: () => {};

  constructor() {}

  $onInit() {
    this.wizard.addStep(this);
  }
}
