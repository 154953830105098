import { IElement, IService } from 'restangular';
import { ILeagueScope } from '../models/ILeagueScope';
import { IPage } from '../models/IPage';
import { StateService } from '@uirouter/angularjs';

export class PageController {
    static $inject = ['$state', '$scope', 'Restangular', '$timeout', 'Upload'];
    pageInfo: IPage;
    dataService: IElement;
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService, private Upload: angular.angularFileUpload.IUploadService) {
        let me = this;
        this.dataService = this.Restangular.one('leagues', $scope.league.id).one('pages', $state.params.pageId);
        this.dataService.get()
            .then(function(page) {
                me.pageInfo = page;
            });
    }
}
