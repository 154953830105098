import { IFile } from "../../../models/IFile";
import * as angular from "angular";
import { UserService } from "../../../services/UserService";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IUser } from "../../../models/IUser";
import { IUserPreferences } from "../../../models/IUserPreferences";
import { PermissionsService } from "../../../services/PermissionsService";
import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";

import template from "./UserProfile.template.html";
import _ from "lodash";

let name: string = "stUserProfile";

@Component({
  selector: name,
  template,
})
export class UserProfile extends UIComponent {
  static $inject: string[] = ["UserService", "notify"];
  static Name: string = name;
  @Input()
  user: IUser;
  @Input("@")
  cancelState: string;
  @Input()
  pageMode: boolean;

  password: string;
  preferences: IUserPreferences;
  doUpdatePreferences: boolean = true;

  /**
   * Creates an instance of StaffManager.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf StaffManager
   */
  constructor(
    private userService: UserService,
    private notify: angular.cgNotify.INotifyService
  ) {
    super();
  }

  save(user: IUser) {
    let me = this;
    me.userService.save(user).then(() => {
      me.notify({ message: "profile updated", classes: "message-success" });
    });
  }

  updatePassword(user: IUser, password: string) {
    let me = this;
    me.userService.save({ id: user.id, password } as IUser).then(() => {
      me.notify({
        message: "password was updated",
        classes: "message-success",
      });
      me.password = "";
    });
  }

  updatePreferences(user: IUser, preferences: IUserPreferences) {
    let me = this;
    if (!me.doUpdatePreferences) {
      me.userService.addPreferences(user, preferences).then((newPrefs) => {
        me.preferences = newPrefs;
        me.doUpdatePreferences = true;
        me.notify({
          message: "updated preferences",
          classes: "message-success",
        });
      });
    } else {
      me.userService
        .updatePreferences(user, preferences)
        .then((prefs: IUserPreferences) => {
          me.notify({
            message: "updated preferences",
            classes: "message-success",
          });
        });
    }
  }

  /**
   *
   */
  loadPreferences() {
    let me = this;
    me.userService
      .getPreferences(me.user)
      .then((preferences: IUserPreferences) => {
        me.preferences = _.defaults<IUserPreferences>(preferences, {
          emailNotify: true,
          cellNotify: false,
        });
      })
      .catch((error) => {
        if (
          error &&
          error.data &&
          error.data.error &&
          error.data.error.code === "MODEL_NOT_FOUND"
        ) {
          me.doUpdatePreferences = false;
          me.preferences = {
            emailNotify: true,
            cellNotify: false,
          } as IUserPreferences;
        }
      });
  }

  imageChange(image: IFile) {
    this.user.userPhotoId = image.id;
  }

  $onChanges(changesObj) {
    if (this.user) {
      this.loadPreferences();
    }
  }
}
