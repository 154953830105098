import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

let name: string = "stPageTitle";

@Component({
  selector: name,
  template,
})
export class PageTitle {
  static Name: string = name;
  static $inject = [];

  @Input()
  title: string;
  /**
   *
   */
  constructor() {}

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
