import { Component, Input, Output } from "angular-ts-decorators";
import { IFile } from "../../../models/IFile";
import { IAddress } from "../../../models/IAddress";
import { StaffManager } from "../staff/StaffManager.component";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IUser } from "../../../models/IUser";
import { PermissionsService } from "../../../services/PermissionsService";
import { ITeam } from "../../../models/ITeam";
import { ISponsor } from "../../../models/ISponsor";
import { TeamService } from "../../../services/TeamService";
import { UIComponent } from "../../../core/UIComponent";
import { SponsorModal } from "./Sponsor.modal.component";
import template from "./Sponsor.template.html";

@Component({
  selector: "stSponsors",
  template,
})
export class SponsorComponent extends UIComponent {
  static $inject: string[] = [
    "TeamService",
    "PermissionsService",
    "$uibModal",
    "$sce",
  ];
  @Input()
  team: ITeam;
  @Input()
  user: IUser;
  @Input()
  roles: IRoleInfo;
  @Input()
  mode: string;

  sponsors: ISponsor[];
  /**
   * Creates an instance of StaffManager.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf StaffManager
   */
  constructor(
    private teamService: TeamService,
    private permissionsService: PermissionsService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $sce: angular.ISCEService
  ) {
    super();
  }

  private openModal(resolver: any): ng.IPromise<ISponsor> {
    return this.modal<ISponsor>(
      this.$uibModal,
      require("./Sponsor.modal.template.html").default,
      SponsorModal,
      resolver
    );
  }

  /**
   *
   *
   *
   * @memberOf VideosComponent
   */
  add() {
    let me = this;
    me.openModal({
      item() {
        return {};
      },
    }).then(function (result: ISponsor) {
      me.teamService
        .addSponsor(me.team, result)
        .then((item) => me.sponsors.push(item));
    });
  }

  /**
   *
   *
   * @param {IFacility} video
   *
   * @memberOf VideosComponent
   */
  edit(item: ISponsor) {
    let me = this;
    me.openModal({
      item() {
        return item;
      },
    }).then((result) => me.teamService.updateSponsor(me.team, result));
  }

  /**
   *
   *
   * @param {IVideo} video
   *
   * @memberOf VideosComponent
   */
  remove(item: ISponsor) {
    if (!confirm("are you sure you want to remove this sponsor?")) return false;
    let me = this;
    me.teamService.removeSponsor(me.team, item);
    let idx = me.sponsors.indexOf(item);
    if (idx >= 0) me.sponsors.splice(idx, 1);
  }

  /**
   *
   *
   * @param {IAddress} location
   * @returns
   *
   * @memberOf Facilities
   */
  mapUrl(location: IAddress) {
    let addressString: string = "{street} {city} {state}, {zip}"
      .replace("{street}", location.street || "")
      .replace("{city}", location.city || "")
      .replace("{state}", location.state || "")
      .replace("{zip}", location.zip);
    let url: string =
      "https://www.google.com/maps/embed/v1/search?q={address}&key=AIzaSyBmLgI11kbyhMm3ggHgV9_Ged41jFWTQcc".replace(
        "{address}",
        encodeURIComponent(addressString)
      );
    return this.$sce.trustAsResourceUrl(url);
  }

  private cleanseUrl(url: string) {
    if (url.indexOf("http") !== 0) url = "http://" + url;
    return url;
  }

  private trust(url: string) {
    return this.$sce.trustAsResourceUrl(this.cleanseUrl(url));
  }

  $onChanges(changesObj) {
    let me = this;
    if (me.team) {
      me.teamService.getSponsors(me.team).then((item) => (me.sponsors = item));
    }
  }
}
