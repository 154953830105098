import { IAgeGroup } from "../models/IAgeGroup";
import { IFilter } from "../models/IFilter";
import { IGame } from "../models/IGame";
import { Injectable } from "angular-ts-decorators";
import { ISeason } from "../models/ISeason";
import { IService, ICollectionPromise } from "restangular";
import { ITeamLevel } from "../models/ITeamLevel";
import { IHttpService } from "angular";
import { ITeamRegistration } from "../models/ITeamRegistration";
import _ from "lodash";
import { filter } from "@uirouter/angularjs";
import { endOfMonth, startOfMonth } from "date-fns";

@Injectable("SeasonService")
export class SeasonService {
  static $inject = ["Restangular", "$http"];
  constructor(private Restangular: IService, private $http: IHttpService) {}

  get(id: string): ng.IPromise<ISeason> {
    return this.Restangular.one("seasons", id).get();
  }

  update(season: ISeason): ng.IPromise<ISeason> {
    return this.Restangular.one("seasons", season.id).customPATCH(season);
  }

  remove(season: ISeason): ng.IPromise<ISeason> {
    return this.Restangular.one("seasons", season.id).remove();
  }

  paymentsBySeason(seasonId: string) {
    const filter: IFilter = {
      where: {
        seasonId,
      },
      include: [
        {
          relation: "payments",
          scope: {},
        },
        {
          relation: "ageGroup",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "teamLevel",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "team",
          scope: {
            fields: ["name"],
          },
        },
      ],
    };

    return this.$http
      .get<ITeamRegistration[]>(
        `/api/teamregistrations?filter=${JSON.stringify(filter)}`
      )
      .then((response) => response.data);
  }

  paymentsByRange(seasonId: string, rangeStart: Date, rangeEnd: Date) {
    const filter: IFilter = {
      where: {
        created: {
          between: [rangeStart, rangeEnd],
        },
      },
      include: [
        {
          relation: "payments",
          scope: {},
        },
        {
          relation: "ageGroup",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "teamLevel",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "team",
          scope: {
            fields: ["name"],
          },
        },
      ],
    };

    return this.$http
      .get<ITeamRegistration[]>(
        `/api/seasons/${seasonId}/teamRegistrations?filter=${JSON.stringify(
          filter
        )}`
      )
      .then((response) => response.data);
  }

  getOpenRegistrationSeason(leagueId: string): ng.IPromise<ISeason> {
    const filter: IFilter = {
      where: {
        registrationOpen: {
          lt: new Date(),
        },
        registrationClose: {
          gt: new Date(),
        },
      },
    };
    return this.$http
      .get<ISeason[]>(
        `/api/leagues/${leagueId}/seasons?filter=${JSON.stringify(filter)}`
      )
      .then((response) => response.data)
      .then((seasonList: ISeason[]) => {
        let orderedSeasons = _.orderBy(
          seasonList,
          ["registrationOpen", "asc"],
          ["registrationClosed", "asc"]
        );
        return orderedSeasons && orderedSeasons.length && orderedSeasons[0];
      });
  }

  getAgeGroups(season: ISeason | string): ng.IPromise<IAgeGroup[]> {
    const filter: IFilter = {
      include: "teamlevels",
    };
    if (typeof season === "string") {
      return this.$http
        .get<IAgeGroup[]>(
          `/api/seasons/${season}/agegroups?filter=${JSON.stringify(filter)}`
        )
        .then((response) => {
          return response.data;
        });
    }
    return this.$http
      .get<IAgeGroup[]>(
        `/api/seasons/${season.id}/agegroups?filter=${JSON.stringify(filter)}`
      )
      .then((response) => {
        return response.data;
      });
  }

  getTeams(
    season: ISeason | string,
    agegroup?: IAgeGroup
  ): ng.IPromise<ITeamRegistration[]> {
    const filter: IFilter = {
      include: [
        "ageGroup",
        "teamLevel",
        "roster",
        { team: ["manager", "members", { positions: "user" }] },
      ],
    };

    if (agegroup) {
      filter.where = { ageGroupId: agegroup.id };
    }
    const seasonId = typeof season === "string" ? season : season.id;

    return this.$http
      .get<ITeamRegistration[]>(
        `/api/seasons/${seasonId}/teamregistrations?filter=${JSON.stringify(
          filter
        )}`
      )
      .then((response) => {
        return response.data;
      });
  }

  getUpcomingGames(
    seasonId: string,
    ageGroupId: string
  ): ICollectionPromise<IGame> {
    let me = this;
    let filter: IFilter = {
      include: [
        {
          relation: "homeTeam",
          scope: {
            fields: ["id", "teamId"],
            include: {
              relation: "team",
              scope: {
                fields: ["name", "teamPhotoId", "urlKey"],
                include: {
                  relation: "teamPhoto",
                  scope: {
                    fields: ["url"],
                  },
                },
              },
            },
          },
        },
        {
          relation: "awayTeam",
          scope: {
            fields: ["id", "urlKey", "teamId"],
            include: {
              relation: "team",
              scope: {
                fields: ["name", "teamPhotoId", "urlKey"],
                include: {
                  relation: "teamPhoto",
                  scope: {
                    fields: ["url"],
                  },
                },
              },
            },
          },
        },
        {
          relation: "teamlevel",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "agegroup",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "date",
        },
      ],
      order: "date.datetime ASC",
    };
    if (ageGroupId) {
      filter.where = { agegroupId: ageGroupId };
    }
    return me.Restangular.one("seasons", seasonId).getList<IGame>("games", {
      filter,
    });
  }
}
