import {IFacility} from '../../../models/IFacility';

export class FacilitiesModal {
    static $inject: string[] = ['item'];
    item: IFacility;
    /**
     * Creates an instance of StaffManagerModal.
     * 
     * @param {IPosition} item
     * 
     * @memberOf StaffManagerModal
     */
    constructor(item: IFacility) {
        this.item = item;
    }    
}
