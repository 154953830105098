import { ILeagueWindowService } from '../../models/ILeagueWindowService';
import { INews } from '../../models/INews';
import { ITeam } from '../../models/ITeam';
import { StateService } from '@uirouter/angularjs';
import { TeamService } from '../../services/TeamService';
/**
 * 
 * 
 * @export
 * @class NewsDetailController
 */
export class NewsDetailController {
    static $inject: string[] = ['TeamService', '$state', '$window'];

    news: INews;
    /**
     * Creates an instance of NewsDetailController.
     * 
     * 
     * @memberOf NewsDetailController
     */
    constructor(private teamService: TeamService, private $state: StateService, private $window: ILeagueWindowService) {
        let me = this;
        
        if (me.$window && me.$window.teamId && $state.params.id) {
            me.teamService.getNewsById({id: me.$window.teamId} as ITeam, $state.params.id)
            .then((news: INews) => {me.news = news; });
        }
    }
}
