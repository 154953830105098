import { States } from "./States";
import { TeamSiteController } from "./controllers/TeamSiteController";
import { NewsDetailController } from "./components/news/News.detail.controller";
import { TeamCalendarDetail } from "./components/team/calendar/TeamCalendar.detail.component";
import {
  Ng1StateDeclaration,
  StateProvider,
  UrlService
} from "@uirouter/angularjs";
import { PracticePlansModule } from "./modules/practiceplans/practiceplans.module";
import { PracticePlans } from "./modules/practiceplans/practiceplans";
import { PlanCreator } from "./modules/practiceplans/plancreator/plancreator";
import { IHttpService, IWindowService } from "angular";
import { TeamService } from "./services/TeamService";
import { CommonVanityUrlService } from "./services/VanityUrlService";
/**
 * Class to configure the various status of the application
 *
 * @export
 * @class States
 */
export class TeamSiteStates {
  public static Config(
    $stateProvider: StateProvider,
    $urlServiceProvider: UrlService
  ) {
    $stateProvider

      .state({
        name: "team",
        url: "/team",
        abstract: true,
        template: require("./templates/teamsite/template.html").default,
        controller: TeamSiteController,
        controllerAs: "$ctrl",
        resolve: {
          team: [
            "CommonVanityUrlService",
            "TeamService",
            async function (vanityUrlService: CommonVanityUrlService, teamService: TeamService) {
              let teamId = await vanityUrlService.resolve().then((res) => res?.id);
              return teamService.get(teamId);
            }
          ]
        }
      })
      .state({
        name: "team.home",
        url: "",
        template: require("./templates/teamsite/home.html").default
      })
      .state({
        name: "team.schedule",
        url: "/schedule",
        template: require("./templates/teamsite/schedule.html").default
      })
      .state({
        name: "team.roster",
        url: "/roster",
        template: require("./templates/teamsite/roster.html").default
      })
      .state({
        name: "team.messaging",
        url: "/messaging",
        template: require("./templates/teamsite/messaging.html").default
      })
      .state({
        name: "team.staff",
        url: "/staff",
        template: require("./templates/teamsite/staff.html").default
      })
      .state({
        name: "team.members",
        url: "/members",
        template: require("./templates/teamsite/members.html").default
      })
      .state({
        name: "team.facilities",
        url: "/facilities",
        template: require("./templates/teamsite/facilities.html").default
      })
      .state({
        name: "team.slideshow",
        url: "/slideshow",
        template: require("./templates/teamsite/slideshow.manager.html").default
      })
      .state({
        name: "team.news",
        url: "/news",
        template: require("./templates/teamsite/news.html").default
      })
      .state({
        name: "team.news.item",
        url: "/:id",
        template: require("./components/news/News.detail.template.html").default,
        controller: NewsDetailController,
        controllerAs: "$ctrl"
      })
      .state({
        name: "team.calendar",
        url: "/calendar",
        template: require("./templates/teamsite/calendar.html").default
      })
      .state({
        name: "team.calendar.item",
        url: "/:id",
        component: TeamCalendarDetail.Name
      })
      .state({
        name: "team.editor",
        url: "/edit",
        template: require("./components/team/editor/TeamEditor.page.template.html").default
      })
      .state({
        name: "team.userprofile",
        url: "/myprofile",
        template: require("./templates/teamsite/profile.html").default
      })
      .state({
        name: "team.plans",
        url: "/plans",
        component: PracticePlans.Name,
        resolve: {
          createPath: () => "team.createplan"
        }
      })
      .state({
        name: "team.createplan",
        url: "/createplan",
        component: PlanCreator.Name,
        resolve: {
          parentPath: () => "team.plans"
        }
      });
    $urlServiceProvider.rules.otherwise("/team");
  }
}
