import template from "./template.html";
import { ITeam } from "../../../models/ITeam";
import { Component, Input, Output } from "angular-ts-decorators";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import { IUser } from "../../../models/IUser";
import { TeamRegistrationService } from "../../../services/TeamRegistrationService";
import _ from "lodash";
import { StandingsService } from "../../../services/StandingsService";
import { IGame } from "../../../models/IGame";
import { IDateTime } from "../../../models/IDateTime";
import { GameService } from "../../../services/GameService";
import { IRosterEntry } from "../../../models/IRosterEntry";
import { IAgeGroup } from "../../../models/IAgeGroup";
import { ITeamLevel } from "../../../models/ITeamLevel";
import { IDivision } from "../../../models/IDivision";

const selector = "stTeamSchedulingManagement";

@Component({
  selector,
  template,
})
export class TeamSchedulingManagement {
  static $inject = [
    "TeamRegistrationService",
    "StandingsService",
    "GameService",
    "notify",
  ];
  static Name = selector;
  @Input()
  team: ITeam;
  @Input()
  registration: ITeamRegistration;
  @Input()
  settings: ILeagueSetting;
  @Input()
  user: IUser;

  ageGroup: IAgeGroup;
  teamLevel: ITeamLevel;
  division: IDivision;

  otherTeams: ITeamRegistration[];
  otherTeamsCopy: ITeamRegistration[];
  isCollapsed: boolean = true;
  isSearchCollapsed: boolean = true;
  /**
   *
   */
  constructor(
    private registrationService: TeamRegistrationService,
    private standingsService: StandingsService,
    private gameService: GameService,
    private notify: angular.cgNotify.INotifyService
  ) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    this.division = this.registration.division;
    this.ageGroup = this.registration.ageGroup;
    this.teamLevel = this.registration.teamLevel;
    this.refresh();
  }

  refresh() {
    this.registrationService
      .getTeamsInDivisionWithSourceListFilter(this.registration, this.division)
      .then((teams) => {
        this.otherTeams = _.filter(
          this.standingsService.calculateStandingsOfTeamRegistrations(teams),
          (team: ITeamRegistration) => {
            return team.id !== this.registration.id; // only other teams
          }
        );
      });
  }

  inviteTeam(team: ITeamRegistration) {
    this.registrationService.inviteTeam(this.registration, team).then(() => {
      this.refresh();
    });
  }

  uninviteTeam(team: ITeamRegistration) {
    this.registrationService
      .uninviteTeam(this.registration, team.invited[0])
      .then(() => {
        this.refresh();
      });
  }

  blockTeam(team: ITeamRegistration) {
    this.registrationService.blockTeam(this.registration, team).then(() => {
      this.refresh();
    });
  }

  unblockTeam(team: ITeamRegistration) {
    this.registrationService.unblockTeam(this.registration, team).then(() => {
      this.refresh();
    });
  }

  dateList(teamRegistration: ITeamRegistration) {
    return _.filter(teamRegistration.homeDates, (tr: IDateTime) => {
      return !tr.taken && tr.datetime;
    });
  }

  hasMatch(games: IGame[]) {
    let idx = _.findIndex(games, (game: IGame) => {
      return game.awayTeamId === this.registration.id;
    });
    return idx >= 0;
  }

  async createGame(homeTeam: ITeamRegistration, date: IDateTime) {
    if (
      !confirm(
        `Are you sure you want to create a game with ${homeTeam.team.name} on ${date.datetime}`
      )
    ) {
      return;
    }
    try {
      this.otherTeamsCopy = this.otherTeams;
      this.otherTeams = [];
      let game = await this.gameService.scheduleGame({
        homeTeamId: homeTeam.id,
        awayTeamId: this.registration.id,
        dateId: date.id,
        seasonId: this.registration.seasonId,
        agegroupId: this.registration.ageGroupId,
        teamlevelId: this.registration.teamlevelId,
      } as IGame);
      let fullGame = await this.gameService.getGame(game.id);
      this.registration.awayGames.push(fullGame);
      this.refresh();
      this.notify({
        message:
          "The game was created successfully, hang on while we refresh the list",
        classes: "message-success",
      });
    } catch (ex) {
      this.notify({
        message:
          "There was an error creating the game, it's most likely that the date was already taken by someone else.",
        classes: "message-danger",
      });
      this.refresh();
    }
  }

  canSchedule() {
    if (this.settings.restrictScheduling) {
      if (this.registration && this.registration.earlyScheduling) {
        // short circuit the logic if early scheduling is enabled
        return true;
      }
      return (
        this.registration &&
        this.registration.homeDates.length >=
          (this.settings.minimumHomeDates || -1)
      );
    }
    return true;
  }

  signedRosters(rosters: IRosterEntry[]) {
    return _.filter(rosters, (roster) => {
      return roster.waiver;
    });
  }
  allRostersSigned(rosters: IRosterEntry[]) {
    let rostersWithWaivers = this.signedRosters(rosters);
    return rostersWithWaivers.length >= this.settings.minimumRoster;
  }

  ageGroupChange(ageGroup: IAgeGroup) {
    this.ageGroup = ageGroup;
  }

  teamLevelChange(teamLevel: ITeamLevel) {
    this.teamLevel = teamLevel;
  }

  divisionChange(division: IDivision) {
    this.division = division;
    this.refresh();
  }
}
