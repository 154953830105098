import { ISetting } from '../models/ISetting';
import { IFilter } from '../models/IFilter';
import { Injectable } from 'angular-ts-decorators';
import { IService } from 'restangular';

@Injectable('SettingsService')
export class SettingsService {
    static $inject: string[] = ['Restangular'];

    constructor(private Restangular: IService) {
    }

    list(): ng.IPromise<ISetting[]> {
        return this.Restangular.all('settings').getList<ISetting>();
    }

    add(setting: ISetting): ng.IPromise<ISetting> {
        return this.Restangular.all('settings').post(setting);
    }

    getSetting(key: string): ng.IPromise<ISetting> {
        let filter: IFilter = {
            where: { key }
        } as IFilter;
        return this.Restangular.all('settings').customGET('findOne', { filter });
    }

    save(setting: ISetting): ng.IPromise<ISetting> {
        return this.Restangular.one('settings', setting.id).customPATCH(setting);
    }

    delete(setting: ISetting): ng.IPromise<ISetting> {
        return this.Restangular.one('settings', setting.id).remove();
    }
}
