import { ISport } from '../models/ISport';
import { Injectable } from 'angular-ts-decorators';
import { IService } from 'restangular';

@Injectable('SportsService')
export class SportsService {
    static $inject: string[] = ['Restangular'];

    sports: ISport[];
    constructor(private Restangular: IService) {
    }

    list(): ng.IPromise<ISport[]> {
        return this.Restangular.all('sports').getList<ISport>()
            .then((sports: ISport[]) => sports);
    }

    add(sport: ISport): ng.IPromise<ISport> {
        return this.Restangular.all('sports').post(sport)
            .then((newSport: ISport) => newSport);
    }

    save(sport: ISport): ng.IPromise<ISport> {
        return this.Restangular.one('sports', sport.id).customPATCH(sport)
            .then((result: ISport) => result);
    }

    delete(sport: ISport): ng.IPromise<ISport> {
        return this.Restangular.one('sports', sport.id).remove()
            .then((result: ISport) => result);
    }
}
