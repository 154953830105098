import { StateService, StateParams } from "@uirouter/angularjs";
import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { ILeagueSetting } from "../../../../models/ILeagueSetting";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { ITeam } from "../../../../models/ITeam";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { IUser } from "../../../../models/IUser";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import template from './index.html';

@Component({
    selector: 'stHostRoundRobin',
    template
})
export class HostRoundRobin implements IComponentController {
    static Name = 'stHostRoundRobin';
    static $inject = ["TeamRegistrationService", "$state", "$stateParams"];
    @Input()
    registration: ITeamRegistration;

    @Input()
    team: ITeam;

    @Input()
    settings: ILeagueSetting;

    @Input()
    user: IUser;

    public roundRobins: IRoundRobin[];
    public roundRobin: IRoundRobin;
    public selectedRoundRobin: IRoundRobin;

    constructor(
        private teamRegistrationService: TeamRegistrationService,
        private $state: StateService,
        private $stateParams: StateParams
    ) { }

    async $onInit() {
        this.roundRobins = await this.teamRegistrationService.getRoundRobins(
            this.registration
        );
        if (this.roundRobins && this.roundRobins.length <= 0) {
            this.$state.go(".create");
        }
        if (this.$stateParams["id"]) {
            const id = this.$stateParams["id"];
            this.roundRobins?.forEach(async (roundRobin) => {
                if (roundRobin.id === id) {
                    this.selectedRoundRobin = roundRobin;
                    await this.change(roundRobin);
                }
            });
        }
    }

    label(roundRobin: IRoundRobin) {
        return roundRobin?.name;
    }

    async change(roundRobin: IRoundRobin) {
        if (roundRobin) {
            this.roundRobin = await this.teamRegistrationService.getRoundRobin(roundRobin.id as string);
            this.$state.go(".view", { id: roundRobin.id });
        }
    }
}