import { PagesEditor } from './editor/component';
import { IPage } from '../../models/IPage';
import { Injectable } from 'angular-ts-decorators';
import { IModalService, IModalSettings } from 'angular-ui-bootstrap';

@Injectable('PagesModalService')
export class PagesModalService {
    static $inject: string[] = ['$uibModal'];

    constructor(private modalService: IModalService) {

    }

    openModal(item: IPage): angular.IPromise<IPage> {
        return this.modalService.open({
            animation: true,
            component: PagesEditor.Name,
            size: 'lg',
            resolve: {
                item() {
                    return item;
                }
            }
        } as IModalSettings).result;
    }
}
