import _ from 'lodash';
import moment from 'moment';
import { ICalendarEvent } from '../models/ICalendarEvent';
import { IGame } from '../models/IGame';
import { ILeagueScope } from '../models/ILeagueScope';
import { IService } from 'restangular';
import { StateService } from '@uirouter/angularjs';

export class SeasonScheduleController {
    static $inject = ['$state', '$scope', '$compile', 'Restangular'];
    events: ICalendarEvent[] = [];
    eventSources: ICalendarEvent[][] = [];
    calendarConfig: any;
    constructor(private $state: StateService, private $scope: ILeagueScope, private $compile: angular.ICompileService, private Restangular: IService) {
        let me = this;
        me.$scope.$watch(
            function() { return me.$scope.league.allGames; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.refresh(newValue);
                }
            },
            true
        );
        this.eventSources.push(this.events);
        this.calendarConfig = {
            calendar: {
                editable: false,
                header: {
                    left: 'title',
                    center: '',
                    right: 'today prev,next'
                },
                eventClick(calEvent: ICalendarEvent, jsEvent, view) { me.calendarEventClick(calEvent, jsEvent, view); }, // proxy to call in context
                eventRender(event, element, view) { me.calendarEventRender(event, element, view); }
            }

        };
    }

    calendarEventRender(event, element, view) {
        this.$compile(element)(this.$scope);
    }

    calendarEventClick(calEvent: ICalendarEvent, jsEvent, view) {
        let me = this;
        me.$state.go('league.team.game.info', { urlKey: calEvent.game.homeTeam.team.urlKey, gameId: calEvent.game.id });
    }

    currentPage: number = 0;
    groupedGames: _.Dictionary<IGame[]>;
    refresh(games: IGame[]) {
        let me = this;
        me.events.splice(0, me.events.length);

        let filteredGames = _.filter(games, function(g) {
            return g && g.date && g.date.datetime;
        });

        me.groupedGames = _.groupBy(filteredGames, function(g) {
            if (g && g.date && g.date.datetime) {
                let theDate = moment(g.date.datetime).toDate();
                theDate.setHours(0, 0, 0, 0);
                return theDate.getTime();
            }
            return null;
        });

        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let keys = me.keys(me.groupedGames);
        me.currentPage = _.findIndex(keys.sort(), function(k) {
            let d = moment(parseInt(k, 10)).toDate();
            return d >= today;
        });

    }

    keys(obj) {
        return obj ? Object.keys(obj) : [];
    }
}
