import later from '@breejs/later';
import _ from 'lodash';
import { IEvent, IEventOccurance } from '../../../models/IEvent';
import { IEventType } from '../../../models/IEventType';
import { IFacility } from '../../../models/IFacility';

export class TeamCalendarModal {
    static $inject: string[] = ['item', 'facilities'];

    item: IEvent;
    dates: IEvent[];
    recurringError: number;
    supportedEvents: IEventType[] = [{
        id: 1,
        type: 'practice'
    },
    {
        id: 2,
        type: 'practice game'
    },
    {
        id: 3,
        type: 'game'
    },
    {
        id: 4,
        type: 'general'
    }];

    /**
     * Creates an instance of NewsModalController.
     * 
     * @param {any} item
     * 
     * @memberOf NewsModalController
     */
    constructor(item: IEvent, private facilities: IFacility[]) {
        this.item = item;
    }

    typeLabel(value: any) {
        return value && value.type;
    }

    startDateChange(value: Date) {
        this.item.endDate = new Date(this.item.startDate.getTime());
        this.calculateRecurring(this.item.recurringText, this.item.recurringTotal || 10, this.item.recurringDuration || 1, value);
    }

    private calculateRecurring(text: string, total: number, duration: number, startDate: Date) {
        let me = this;
        me.item.occurances = [];
        later.date.localTime();
        let schedule = later.parse.text(text);
        me.recurringError = schedule.error;
        if (me.recurringError > -1) return;
        let events = later.schedule(schedule).next(total, startDate);
        _.each(events, (event) => {
            let endDate = new Date(event.getTime());
            endDate.setTime(endDate.getTime() + duration * 60 * 60 * 1000);
            let newEvent: IEventOccurance = {
                startDate: event,
                endDate
            } as IEvent;
            me.item.occurances.push(newEvent);
        });
    }

    recurringTotalChange(value: number) {
        this.calculateRecurring(this.item.recurringText, value || 10, this.item.recurringDuration || 1, this.item.startDate || new Date());
    }

    recurringTextChange(value: string) {
        this.calculateRecurring(value, this.item.recurringTotal || 10, this.item.recurringDuration || 1, this.item.startDate || new Date());
    }

    recurringDurationChange(value: number) {
        this.calculateRecurring(this.item.recurringText, this.item.recurringTotal || 10, value || 1, this.item.startDate || new Date());
    }

    onChange(value: IFacility) {
        this.item.location = value.location;
        this.item.locationName = value.name;
        this.item.facility = value;
    }

    label(facility: IFacility) {
        return facility && facility.name;
    }

}
