import { IHttpService } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { ILeague } from "../../models/ILeague";
import { IUser } from "../../models/IUser";
import template from "./index.html";

@Component({
  selector: "stSuggestions",
  template,
})
export class Suggestions {
  static Name: string = "stSuggestions";
  static $inject = ["$http"];

  @Input()
  user: IUser;

  @Input()
  league: ILeague;

  public message: string;
  public thankYou: boolean;
  constructor(private $http: IHttpService) {}

  async submit() {
    let response = this.$http.post("/api/suggestions", {
      message: this.message,
      userId: this.user?.id,
      league: this.league?.id,
    });
    if (response) {
      this.thankYou = true;
    }
  }
}
