import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import { INotification } from "../../../models/INotification";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { TeamService } from "../../../services/TeamService";
import template from "./template.html";

@Component({
  selector: "stTeamNotification",
  template,
})
export class NotificationComponent implements IComponentController {
  static $inject: string[] = ["TeamService", "notify"];

  /**
   * The passed in instance of a team object
   *
   * @type {ITeam}
   * @memberOf NotificationComponent
   */
  @Input()
  team: ITeam;

  /**
   *
   *
   * @type {IUser}
   * @memberOf NotificationComponent
   */
  @Input()
  user: IUser;

  @Input()
  roles: IRoleInfo;
  @Input()
  mode: string;
  /**
   * Creates an instance of NotificationComponent.
   *
   * @memberOf NotificationComponent
   */
  constructor(
    private teamService: TeamService,
    private notify: angular.cgNotify.INotifyService
  ) {}

  $onInit() {}

  async sendMessage(notification: INotification) {
    let me = this;
    try {
      notification.from = me.user.email;
      await me.teamService.notify(me.team, notification);
      me.notify({
        message: "the message was successfully sent",
        classes: "message-success",
      });
      notification.sendText = false;
      notification.message = null;
      notification.subject = null;
    } catch (ex) {
      console.error(ex);
      me.notify({
        message: "something went wrong sending the message, please try again",
        classes: "message-danger",
      });
    }
  }
}
