import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import { IError } from "../../models/IError";
import { IUser } from "../../models/IUser";
import { StateService } from "@uirouter/core";
import { UserService } from "../../services/UserService";
import template from "./template.html";

@Component({
  selector: "stLogout",
  template,
})
export class LogoutComponent implements IComponentController {
  static $inject: string[] = ["UserService"];

  @Output()
  onLogout: () => {};

  constructor(private userService: UserService) {}

  $onInit() {}

  logout() {
    this.userService.logout().then(() => {
      this.onLogout();
    });
  }
}
