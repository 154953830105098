import { Component, Input } from "angular-ts-decorators";
import template from "./lineupgenerator.html";
import { ILineupToolService } from "../ilineuptool.service";
import { IRestResource } from "../../../models/IRestResource";
import { ILineupSettings } from "../ilinupsettings";
import { TeamService } from "../../../services/TeamService";
import { ITeam } from "../../../models/ITeam";
import _ from "lodash";

const name: string = "lineupGenerator";

@Component({
  selector: name,
  template,
})
export class LineupGenerator {
  static $inject: string[] = [];
  static Name: string = name;

  @Input() service: ILineupToolService;

  @Input() restResource: IRestResource;

  @Input() teamService: TeamService;

  public settings: ILineupSettings;

  public sport: string = "baseball";

  public lineup: any;
  public configuration: {} = {
    algorithms: ["rotation", "random"],
    baseball: {
      periods: [
        { id: "5-innings", name: "5 Innings", value: 5 },
        { id: "6-innings", name: "6 Innings", value: 6 },
        { id: "7-innings", name: "7 Innings", value: 7 },
        { id: "8-innings", name: "8 Innings", value: 8 },
        { id: "9-innings", name: "9 Innings", value: 9 },
        { id: "10-innings", name: "10 Innings", value: 10 },
      ],
      setups: [
        {
          id: "baseball-9",
          name: "Baseball (9)",
          positions: [
            "Pitcher",
            "Catcher",
            "First Base",
            "Second Base",
            "Third Base",
            "Shortstop",
            "Left Field",
            "Center Field",
            "Right Field",
          ],
        },
        {
          id: "baseball-10",
          name: "Baseball (10)",
          positions: [
            "Pitcher",
            "Catcher",
            "First Base",
            "Second Base",
            "Third Base",
            "Shortstop",
            "Left Field",
            "Left Center",
            "Right Center",
            "Right Field",
          ],
        },
      ],
    },
  };

  constructor() {
    this.settings = {} as ILineupSettings;
  }

  $onChanges(values) {
    this.teamService.getMembers(this.restResource as ITeam).then((members) => {
      this.settings.players = members;
    });
  }

  generateLineup() {
    switch (this.settings.algorithm) {
      case "random":
        this.lineup = this.randomStrategy(this.settings);
        break;
      case "rotation":
        this.lineup = this.rotationStrategy(this.settings);
        break;
    }
  }

  randomStrategy(settings: ILineupSettings) {
    let totalPlayers = this.settings.players.length;
    let periods = this.settings.periods.value;
    let positions = _.clone(this.settings.positions.positions);
    let totalPositions = positions.length;
    let sitPositions = totalPlayers - totalPositions;
    if (sitPositions < 0) sitPositions = 0;
    let lineup = [];

    // copy the players and create the initial map
    let playersClone = _.clone(this.settings.players);
    playersClone.map((player, idx) => {
      lineup[idx] = {
        player,
        positions: [],
      };
    });

    positions = _.shuffle(positions);
    // assign the sitting positions first
    for (let s = 0; s < sitPositions; s++) {
      positions.push("X");
    }
    let i = periods;
    while (i--) {
      let positionsClone = _.clone(positions);
      playersClone.map((player, idx) => {
        lineup[idx].positions[i] = positionsClone.shift();
      });
      for (let p = 0; p < (sitPositions || 1); p++) {
        positions.push(positions.shift());
      }
    }
    return lineup;
  }

  rotationStrategy(settings: ILineupSettings) {
    let totalPlayers = this.settings.players.length;
    let periods = this.settings.periods.value;
    let positions = _.clone(this.settings.positions.positions);
    let totalPositions = positions.length;
    let sitPositions = totalPlayers - totalPositions;
    if (sitPositions < 0) sitPositions = 0;
    let lineup = [];

    // copy the players and create the initial map
    let playersClone = _.clone(this.settings.players);
    playersClone.map((player, idx) => {
      lineup[idx] = {
        player,
        positions: [],
      };
    });

    // assign the sitting positions first
    for (let s = 0; s < sitPositions; s++) {
      positions.push("X");
    }
    let i = periods;
    while (i--) {
      console.log(i);
      let positionsClone = _.clone(positions);
      playersClone.map((player, idx) => {
        lineup[idx].positions[i] = positionsClone.shift();
      });
      for (let p = 0; p < (sitPositions || 1); p++) {
        positions.push(positions.shift());
      }
    }
    return lineup;
  }
}
