import {ICarousel} from '../../models/ICarousel';
import {IFile} from '../../models/IFile';

export class CarouselModalController {
    static $inject: string[] = ['item'];

    item: ICarousel;

    /**
     * Creates an instance of NewsModalController.
     * 
     * @param {any} item
     * 
     * @memberOf NewsModalController
     */
    constructor(item: ICarousel) {
        this.item = item;
    }    

    /**
     * Handles the event when the image changes
     * 
     * @param {IFile} image
     * 
     * @memberOf CarouselModalController
     */
    imageChange(image: IFile) {
        this.item.image = image;
        this.item.imageId = image.id;
    }
}
