import { Injectable } from "angular-ts-decorators";
import { IWaiverService } from "../waivers/iwaiverservice";
import { IRestResource } from "../../models/IRestResource";
import { IWaiver } from "../waivers/iwaiver";
import { IHttpService } from "angular";
import { IUser } from "../../models/IUser";
import { IWaiverSignature } from "../waivers/iwaiversignature";
import { WaiverSignature } from "../waivers/waiversignature/waiversignature";

@Injectable("LeagueWaiverService")
export class LeagueWaiverService implements IWaiverService {
  static $inject: string[] = ["$http"];

  constructor(private http: IHttpService) {}

  getMyWaivers(resource: IRestResource, user: IUser): ng.IPromise<IWaiver[]> {
    const filter = {
      leagueId: resource.id
    };
    return this.http
      .get<IWaiver[]>(`/api/users/me/waivers?filter=${JSON.stringify(filter)}`)
      .then(response => response && response.data);
  }

  getMyTeamWaivers() {
    return this.http
      .get<IWaiver[]>(`/api/users/me/teamwaivers`)
      .then(response => response && response.data);
  }

  getWaivers(resource: IRestResource): ng.IPromise<IWaiver[]> {
    return this.http
      .get<IWaiver[]>(`/api/leagues/${resource.id}/waivers`)
      .then(response => {
        return response && response.data;
      });
  }
  getWaiverById(resource: IRestResource, id: string): ng.IPromise<IWaiver> {
    return this.http
      .get<IWaiver>(`/api/leagues/${resource.id}/waivers/${id}`)
      .then(response => {
        return response && response.data;
      });
  }
  addWaiver(resource: IRestResource, waiver: IWaiver): ng.IPromise<IWaiver> {
    return this.http
      .post<IWaiver>(`/api/leagues/${resource.id}/waivers`, waiver)
      .then(response => {
        return response && response.data;
      });
  }
  updateWaiver(resource: IRestResource, waiver: IWaiver): ng.IPromise<IWaiver> {
    return this.http
      .put<IWaiver>(`/api/leagues/${resource.id}/waivers/${waiver.id}`, waiver)
      .then((response) => {
        return response && response.data;
      });
  }
  removeWaiver(resource: IRestResource, waiver: IWaiver): ng.IPromise<IWaiver> {
    return this.http
      .delete<IWaiver>(`/api/leagues/${resource.id}/waivers/${waiver.id}`)
      .then((response) => {
        return response && response.data;
      });
  }

  signWaiver(
    resource: IRestResource,
    waiver: IWaiver
  ): ng.IPromise<IWaiverSignature> {
    return this.http
      .post<IWaiverSignature>(`/api/users/me/waiversignatures`, {
        signature_date: new Date(),
        waiverId: waiver.id
      })
      .then((response) => response && response.data);
  }

  signWaiverAnonymous(
    resource: IRestResource,
    waiver: IWaiver,
    user: IUser
  ): ng.IPromise<IWaiverSignature> {
    return this.http
      .post<IWaiverSignature>(`/api/waiversignatures/sign`, {
        signature_date: new Date(),
        waiverId: waiver.id,
        alternate_signature: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          cellPhone: user.cellPhone
        }
      })
      .then((response) => response && response.data);
  }

  signTeamWaiver(waiver: IWaiver): ng.IPromise<WaiverSignature> {
    return this.http
      .put<WaiverSignature>(`/api/users/me/teamwaivers/${waiver.id}`, waiver)
      .then((response) => response && response.data);
  }

  getTeamWaiverById(resource: IRestResource, id: string): ng.IPromise<IWaiver> {
    return this.http
      .get<IWaiver>(`/api/users/me/teamwaivers/${id}`)
      .then((response) => response && response.data);
  }
}
