import {ILeagueScope} from '../models/ILeagueScope';
import {IGame} from '../models/IGame';
import {IGameChange} from '../models/IGameChange';
import { IService, IElement } from 'restangular';
import { StateService } from '@uirouter/angularjs';
import _ from 'lodash';
import moment from 'moment';

export class OfficialsController {
    static $inject = ['$state', '$scope', 'Restangular'];
    dataService: IElement;
    tableFilter: string = '';
    daysAheadOptions: number[] = [7, 14, 21, Number.POSITIVE_INFINITY];
    daysAhead: number = Number.POSITIVE_INFINITY;
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService) {
        let me = this;
        me.$scope.$watch(
            function() { return me.$scope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.dataService = Restangular.one('seasons', me.$scope.league.currentSeason.id);
                    me.refresh();
                }
            },
            true
        );

    }

    upcomingGames: IGame[];
    refresh() {
        let me = this;
        me.dataService.getList('upcoming', { daysAhead: me.daysAhead })
            .then(function(games: IGame[]) {
                me.upcomingGames = _.sortBy(games, [
                    function(g: IGame) {
                        if (g && g.date && g.date.datetime) {
                            let newDate = moment(g.date.datetime).toDate();
                            newDate.setHours(0, 0, 0, 0);
                            return newDate;
                        }
                        return g && g.date && g.date.datetime;
                    },
                    function(g: IGame) {
                        return g && g.date && g.date.facility && g.date.facility.name;
                    }
                ]);

                _.each(me.upcomingGames, function(game: IGame) {
                    if (game && game.changes && game.changes) {
                        _.each(game.changes, function(change: IGameChange) {
                            if (change.data && change.data.dateId) {
                                game.hasChanges = true;
                            }
                        });
                        game.hasChanges = true;
                    }
                });
            });
    }

    assignOfficial(game: IGame) {
        let me = this;
        me.dataService.one('games', game.id).customPUT({ officialAssigned: game.officialAssigned })
            .then(function(result) {
                // nothing to do  
            });
    }
}
