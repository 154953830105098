import { IAgeGroup } from '../models/IAgeGroup';
import { ICollection, IElement, IService } from 'restangular';
import { ILeagueScope } from '../models/ILeagueScope';
import { ITeam } from '../models/ITeam';
import { ITeamRegistration } from '../models/ITeamRegistration';
import { StateService } from '@uirouter/angularjs';

export class TeamRegistrationController {
    static $inject = ['$state', '$scope', 'Restangular'];
    registrationService: ICollection;
    teamService: ICollection;
    list: ITeamRegistration[];
    newRegistration: ITeamRegistration;
    newTeam: ITeam;
    selectedTeam: ITeamRegistration;
    ageGroupService: ICollection;
    ageGroupList: IAgeGroup[];
    facilityService: ICollection;

    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService) {
        this.newRegistration = { roster: [], ageGroup: {}, team: { homeFields: [] } } as ITeamRegistration;
        this.newTeam = {} as ITeam;
        this.list = [];

        this.teamService = Restangular.all('teams');
        let me = this;
        me.$scope.$watch(
            function() { return me.$scope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.registrationService = Restangular.one('seasons', me.$scope.league.currentSeason.id).all('teamregistrations');
                    if (newValue) {
                        me.refresh();
                    }
                }

            },
            true
        );

        this.ageGroupService = Restangular.one('leagues', $scope.league.id).all('agegroups');
        this.ageGroupService.getList<IAgeGroup>()
            .then(function(ageGroups) {
                me.ageGroupList = ageGroups;
            });
    }

    refresh() {
        let me = this;
        this.registrationService.getList<ITeamRegistration>({ filter: '{"include":["team", "ageGroup", "league", "season"]}' })
            .then(function(teamRegistrations) {
                if (teamRegistrations) {
                    me.list = teamRegistrations;
                } else {
                    me.list = [];
                }

            })
            .catch(function(err) {
                me.list = [];
            });
    }

    create() {
        let me = this;
        this.registrationService.customPOST('registerTeam')
            .then(function(newTeam) {

            });
    }

    edit(team: ITeamRegistration) {
        let me = this;
        this.selectedTeam = team;
        this.$state.go('league.teamRegistrations.edit', { teamId: team.id });
    }

    save() {
        let me = this;
        this.selectedTeam.save()
            .then(function() {
                me.$state.go('league.teamRegistrations');
            });
    }
}
