import { GridComponent } from './component';
import { NgModule } from 'angular-ts-decorators';

@NgModule({
    id: 'league-manager-grid-components',
    declarations: [
        GridComponent
    ],
    providers: [
    ],
    imports: [
    ]
})
export class GridModule {

}
