import { Component, Input, Output } from "angular-ts-decorators";
import template from "./DateTime.template.html";

@Component({
  selector: "stDateTime",
  template,
})
export class DateTimeComponent {
  @Input("@") label: string;
  @Input() value: Date;
  @Input("@") required: string;
  @Output() onChange: ({ value }) => {};

  hourStep: number = 1;
  minuteStep: number = 15;
  myTime: Date = new Date();
  myDate: Date = new Date();
  dateIsOpened: boolean = false;

  dateOptions: any = {
    formatYear: "yy",
    maxDate: new Date(2020, 5, 22),
    minDate: new Date(),
    startingDay: 1,
  };

  /**
   * Creates an instance of DateTime.
   *
   *
   * @memberOf DateTime
   */
  constructor() {
    this.myTime.setMinutes(0);
  }

  isRequired(): boolean {
    return this.required === "true";
  }

  open() {
    this.dateIsOpened = true;
  }

  dateChange() {
    this.formatDate();
    this.onChange({ value: this.value });
  }

  timeChange() {
    this.formatDate();
  }

  formatDate() {
    if (!this.value) this.value = new Date();
    this.value.setMonth(this.myDate.getMonth());
    this.value.setDate(this.myDate.getDate());
    this.value.setFullYear(this.myDate.getFullYear());
    this.value.setHours(this.myTime.getHours());
    this.value.setMinutes(this.myTime.getMinutes());
  }

  $onChanges(changesObj) {
    let me = this;
    if (changesObj && changesObj.value) {
      me.myDate = new Date(changesObj.value.currentValue.getTime());
      me.myTime = new Date(changesObj.value.currentValue.getTime());
    }
  }
}
