import { Component, Input } from "angular-ts-decorators";
import { Wizard } from "./wizard";
import template from "./next.html";

@Component({
  selector: "nextButton",
  require: {
    wizard: "^wizard",
  },
  template,
})
export class NextButton {
  private wizard: Wizard;

  @Input("@") public label: string;

  $onInit() {}

  click() {
    this.wizard.nextStep();
  }
}
