import {IAgeGroup} from '../models/IAgeGroup';
import {IRootStateService} from '../models/IRootStateService';
import {ILeagueScope} from '../models/ILeagueScope';
import {ITeamLevel} from '../models/ITeamLevel';
import {IDivision} from '../models/IDivision';
import { IService, ICollection } from 'restangular';
import { StateService } from '@uirouter/angularjs';

import _ from 'lodash';

export class DivisionsController {
    static $inject = ['$rootScope', '$state', '$scope', 'Restangular', '$timeout'];
    dataService: ICollection;
    ageGroups: IAgeGroup[];
    constructor(private $rootScope: IRootStateService, private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService) {
        let me = this;
        me.$scope.$watch(
            function() { return me.$scope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.dataService = Restangular.all('agegroups');
                    me.refresh();
                }
            },
            true
        );
    }

    refresh() {
        let me = this;
        me.Restangular.one('seasons', me.$scope.league.currentSeason.id).getList<IAgeGroup>('agegroups')
            .then(function(result) {
                me.ageGroups = result;
                _.each(me.ageGroups, function(agegroup) {
                    let agc = me.Restangular.one('agegroups', agegroup.id);
                    agc.getList('teamlevels', { filter: { include: 'divisions' } })
                        .then(function(teamLevels) {
                            agegroup.teamlevels = teamLevels;
                        });
                });
            });
    }

    addDivision(teamLevel: ITeamLevel) {
        let me = this;
        let svc = me.Restangular.one('teamlevels', teamLevel.id).all('divisions');
        svc.post({ name: 'new division' })
            .then(function(division) {
                me.setEditing(division, true);
                teamLevel.divisions.push(division);

            });
    }

    editDivision(division: IDivision) {
        this.setEditing(division, true);
    }

    saveDivision(parent: ITeamLevel, division: IDivision) {
        let me = this;
        me.Restangular.one('teamlevels', parent.id).one('divisions', division.id).customPUT(division)
            .then(function(response) {
                me.setEditing(division, false);
            });
    }

    deleteDivision(parent: ITeamLevel, division: IDivision) {
        if (!confirm('Are you sure you want to delete this divsion?')) return;
        let me = this;
        me.Restangular.one('teamlevels', parent.id).one('divisions', division.id).remove()
            .then(function() {
                let idx = parent.divisions.indexOf(division);
                parent.divisions.splice(idx, 1);
            });

    }

    setEditing(obj: IDivision, value: boolean) {
        obj.__uistate = obj.__uistate || {};
        obj.__uistate.editing = value;
        if (value) {
            this.$timeout(function() {
                $('input.form-control:last').focus();
                $('input.form-control:last').select();
            });
        }
    }

}
