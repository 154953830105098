import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import { TeamRegistrationService } from "../../../services/TeamRegistrationService";
import { SettingsService } from "../../../services/SettingsService";
import { IPayment } from "../../../models/IPayment";
import { ISKU } from "../../../models/ISKU";
import template from "./registration.template.html";
import { PaymentService } from "../../../services/PaymentService";
import { IPrice } from "../../../models/IPrice";

@Component({
  selector: "stRegistration",
  template,
})
export class Registration extends UIComponent {
  static $inject = [
    "TeamRegistrationService",
    "SettingsService",
    "$window",
    "$sce",
    "PaymentService",
  ];
  static Name: string = "stRegistration";
  @Input()
  team: ITeam;
  @Input()
  registration: ITeamRegistration;
  @Input()
  settings: ILeagueSetting;
  @Input()
  user: IUser;
  product: IPrice;
  payment: IPayment;
  balance: number;

  checkoutUrl: string = "";

  constructor(
    private teamRegistrationService: TeamRegistrationService,
    private settingsService: SettingsService,
    private $window: ng.IWindowService,
    private $sce: ng.ISCEService,
    private paymentService: PaymentService
  ) {
    super();
    let me = this;
    me.settingsService.getSetting("checkoutUrl").then((value) => {
      me.checkoutUrl = value.value;
    });
  }

  handleProduct(product: IPrice) {
    let me = this;
    me.product = product;
  }

  async startPayment() {
    let paymentSession = await this.paymentService.getPaymentSession(
      this.product.id,
      this.registration.id,
      this.$window.location.href
    );
    let key = await this.paymentService.getKey();
    let stripeLibrary = this.$window.Stripe(key.stripe_key);
    stripeLibrary.redirectToCheckout({ sessionId: paymentSession.id });
  }

  checkPayments() {
    return this.teamRegistrationService.payments(this.registration);
  }

  handlePayments(payment: IPayment) {
    let me = this;
    me.payment = payment;
    me.balance =
      (me.product && me.product.unit_amount) -
      ((me.payment && me.payment.amount_total) || 0);
  }

  paymentError(error) {
    this.payment = null;
    this.handlePayments(null);
  }

  toDate(timestamp: number | string) {
    if (typeof timestamp === "number") {
      return new Date(timestamp * 1000);
    }
    return timestamp;
  }

  formUrl() {
    let url = this.checkoutUrl.replace(
      "{access_token}",
      this.user && this.user.accessToken
    );
    return this.$sce.trustAsResourceUrl(url);
  }

  /**
   *
   */
  $onChanges(changesObj) {
    if (this.team && this.registration) {
      if (
        this.registration &&
        this.registration.ageGroup &&
        this.registration.ageGroup.registrationProduct
      ) {
        let sku =
          this.registration.teamLevel.registrationProduct ||
          this.registration.ageGroup.registrationProduct;
        this.paymentService
          .getSkuInformation(sku)
          .then((product) => this.handleProduct(product))
          .then(() => this.checkPayments())
          .then((payment) => this.handlePayments(payment))
          .catch((error) => this.paymentError(error));
      }
    }
  }
}
