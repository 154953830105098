import { ITeamRegistration } from "../models/ITeamRegistration";
import { IPayment } from "../models/IPayment";
import { Injectable } from "angular-ts-decorators";
import { IService } from "restangular";
import { IRosterEntry } from "../models/IRosterEntry";
import { IHttpPromiseCallbackArg, IPromise } from "angular";
import { IFilter } from "../models/IFilter";
import { IScheduleConfigListItem } from "../models/IScheduleConfigListItem";
import { IDivision } from "../models/IDivision";
import { IRoundRobin } from "../models/IRoundRobin";
import { IInvitation } from "../models/IInvitation";
import { IDateTime } from "../models/IDateTime";

@Injectable("TeamRegistrationService")
export class TeamRegistrationService {
  static $inject: string[] = ["Restangular", "$http"];

  /**
   * Creates an instance of TeamService.
   *
   * @param {IService} Restangular
   *
   * @memberOf TeamService
   */
  constructor(private Restangular: IService, private $http: ng.IHttpService) {}

  get(id: string) {
    return this.Restangular.one("teamregistrations", id);
  }

  update(registration: ITeamRegistration) {
    return this.Restangular.one(
      "teamregistrations",
      registration.id
    ).customPATCH(registration);
  }

  payments(registration: ITeamRegistration): ng.IPromise<IPayment> {
    return this.Restangular.one("teamregistrations", registration.id).customGET(
      "payments"
    );
  }

  paymentInformation(registration: ITeamRegistration) {
    return this.Restangular.one("teamregistrations", registration.id).customGET(
      "paymentinformation"
    );
  }

  addRoster(registration: ITeamRegistration, rosterEntry: IRosterEntry) {
    return this.$http
      .post<IRosterEntry>(
        `/api/teamregistrations/${registration.id}/roster`,
        rosterEntry
      )
      .then(this.unwrapResponse);
  }

  unregister(registrationId: string) {
    return this.$http
      .delete(`/api/teamregistrations/${registrationId}`)
      .then(this.unwrapResponse);
  }

  getTeamsInDivision(
    registration: ITeamRegistration
  ): ng.IPromise<ITeamRegistration[]> {
    let filter: IFilter = {
      include: [
        "homeGames",
        "awayGames",
        "homeDates",
        {
          team: ["manager"],
        },
      ],
    };
    return this.$http
      .get<ITeamRegistration[]>(
        `/api/divisions/${
          registration.divisionId
        }/teamregistrations?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse);
  }

  getTeamsInDivisionWithRoundRobinInvites(
    registration: ITeamRegistration,
    division: IDivision,
    roundRobin: IRoundRobin
  ) {
    let filter: IFilter = {
      include: [
        "homeGames",
        "awayGames",
        "homeDates",
        {
          team: ["manager"],
        },
        {
          relation: "participants",
          scope: {
            where: {
              hostRegistrationId: registration.id,
              roundRobinId: roundRobin.id,
            },
          },
        },
      ],
    };
    return this.$http
      .get<ITeamRegistration[]>(
        `/api/divisions/${
          division.id
        }/teamregistrations?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse);
  }

  getTeamsInDivisionWithSourceListFilter(
    registration: ITeamRegistration,
    division: IDivision
  ): ng.IPromise<ITeamRegistration[]> {
    let filter: IFilter = {
      include: [
        "homeGames",
        "awayGames",
        "homeDates",
        {
          team: ["manager"],
        },
        {
          relation: "blacklisted",
          scope: {
            where: {
              sourceTeamId: registration.id,
            },
          },
        },
        {
          relation: "invited",
          scope: {
            where: {
              sourceTeamId: registration.id,
            },
          },
        },
      ],
    };
    return this.$http
      .get<ITeamRegistration[]>(
        `/api/divisions/${
          division.id
        }/teamregistrations?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse);
  }

  getTeamsWhoHaveInvited(registration: ITeamRegistration) {
    // let filter: IFilter = {
    //   include: {
    //     sourceTeam: [
    //       "homeGames",
    //       "awayGames",
    //       "homeDates",
    //       {
    //         team: ["manager"],
    //       },
    //     ],
    //   },
    // };
    let filter: IFilter = {
      include: {
        sourceTeam: [
          "homeGames",
          "awayGames",
          "homeDates",
          {
            team: ["manager"],
          },
          {
            relation: "blacklist",
            scope: {
              where: {
                destTeamId: registration.id,
              },
            },
          },
          {
            relation: "invite",
            scope: {
              where: {
                destTeamId: registration.id,
              },
            },
          },
        ],
      },
    };
    return this.$http
      .get<IScheduleConfigListItem[]>(
        `/api/teamregistrations/${
          registration.id
        }/invited?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse)
      .then((response) => {
        return response.map((response) => {
          return response.sourceTeam;
        });
      });
  }

  getTeamsInDivisionWithDestListFilter(
    registration: ITeamRegistration
  ): ng.IPromise<ITeamRegistration[]> {
    let filter: IFilter = {
      include: [
        "homeGames",
        "awayGames",
        "homeDates",
        {
          team: ["manager"],
        },
        {
          relation: "blacklist",
          scope: {
            where: {
              destTeamId: registration.id,
            },
          },
        },
        {
          relation: "invite",
          scope: {
            where: {
              destTeamId: registration.id,
            },
          },
        },
      ],
    };
    return this.$http
      .get<ITeamRegistration[]>(
        `/api/divisions/${
          registration.divisionId
        }/teamregistrations?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse);
  }

  public inviteTeamToRoundRobin(
    hostTeam: ITeamRegistration,
    participantTeam: ITeamRegistration,
    roundRobin: IRoundRobin
  ) {
    return this.$http
      .post<IInvitation>(`/api/roundrobins/${roundRobin.id}/invitations`, {
        hostRegistrationId: hostTeam.id,
        participantRegistrationId: participantTeam.id,
        //roundRobinId:
      } as IInvitation)
      .then(this.unwrapResponse);
  }

  public uninviteTeamToRoundRobin(
    invitation: IInvitation,
    roundRobin: IRoundRobin
  ) {
    return this.$http
      .delete<IInvitation>(
        `/api/roundrobins/${roundRobin.id}/invitations/${invitation.id}`
      )
      .then(this.unwrapResponse);
  }

  public updateInvitation(invitation: IInvitation) {
    return this.$http
      .patch(`/api/invitations/${invitation.id}`, invitation)
      .then(this.unwrapResponse);
  }

  public getRoundRobinInvitations(registration: ITeamRegistration) {
    let filter: IFilter = {
      include: {
        roundrobin: {
          teamRegistration: ["team"],
        },
      },
      // where: {
      //   accepted: false,
      //   declined: false,
      // },
    };
    return this.$http
      .get<IInvitation[]>(
        `/api/teamregistrations/${
          registration.id
        }/participants?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse);
  }

  public inviteTeam(
    sourceTeam: ITeamRegistration,
    destinationTeam: ITeamRegistration
  ) {
    return this.$http
      .post(`/api/teamregistrations/${sourceTeam.id}/invite`, {
        sourceTeamId: sourceTeam.id,
        destTeamId: destinationTeam.id,
      })
      .then(this.unwrapResponse);
  }

  public uninviteTeam(
    sourceTeam: ITeamRegistration,
    scheduleItem: IScheduleConfigListItem
  ) {
    return this.$http
      .delete(
        `/api/teamregistrations/${sourceTeam.id}/invite/${scheduleItem.id}`
      )
      .then(this.unwrapResponse);
  }

  public blockTeam(
    sourceTeam: ITeamRegistration,
    destinationTeam: ITeamRegistration
  ) {
    return this.$http
      .post(`/api/teamregistrations/${sourceTeam.id}/blacklist`, {
        sourceTeamId: sourceTeam.id,
        destTeamId: destinationTeam.id,
      })
      .then(this.unwrapResponse);
  }

  public unblockTeam(
    sourceTeam: ITeamRegistration,
    destinationTeam: ITeamRegistration
  ) {
    return this.$http
      .delete(
        `/api/teamregistrations/${sourceTeam.id}/blacklist/${destinationTeam.blacklisted[0].id}`
      )
      .then(this.unwrapResponse);
  }

  public createRoundRobin(
    registration: string | ITeamRegistration,
    roundRobin: IRoundRobin
  ) {
    return this.$http
      .post<IRoundRobin>(
        `/api/teamregistrations/${this.getRegistrationId(
          registration
        )}/roundrobins`,
        roundRobin
      )
      .then(this.unwrapResponse);
  }

  public getRoundRobin(
    roundRobin: string | IRoundRobin
  ): IPromise<IRoundRobin> {
    let filter: IFilter = {
      include: [
        {
          invitations: {
            participant: "team",
          },
        },
        {
          games: [
            "facility",
            { date: "facility" },
            { homeTeam: "team" },
            { awayTeam: "team" },
          ],
        },
      ],
    };
    return this.$http
      .get<IRoundRobin>(
        `/api/roundrobins/${this.getRoundRobinId(
          roundRobin
        )}?filter=${JSON.stringify(filter)}`
      )
      .then(this.unwrapResponse);
  }

  public getRoundRobins(registration: string | ITeamRegistration) {
    return this.$http
      .get<IRoundRobin[]>(
        `/api/teamregistrations/${this.getRegistrationId(
          registration
        )}/roundrobins`
      )
      .then(this.unwrapResponse);
  }

  public addHomeDate(
    registration: string | ITeamRegistration,
    date: IDateTime
  ) {
    return this.$http
      .post<IDateTime>(
        `/api/teamregistrations/${this.getRegistrationId(
          registration
        )}/homeDates`,
        date
      )
      .then(this.unwrapResponse);
  }

  public updateHomeDate(
    registration: string | ITeamRegistration,
    date: IDateTime
  ) {
    return this.$http
      .put<IDateTime>(
        `/api/teamregistrations/${this.getRegistrationId(
          registration
        )}/homeDates/${date.id}`,
        date
      )
      .then(this.unwrapResponse);
  }

  private getRoundRobinId(roundRobin: string | IRoundRobin): string {
    return typeof roundRobin === "string"
      ? roundRobin
      : (roundRobin?.id as string);
  }

  private getRegistrationId(registration: string | ITeamRegistration): string {
    return typeof registration === "string" ? registration : registration.id;
  }

  private unwrapResponse<T>(response: IHttpPromiseCallbackArg<T>) {
    return response && response.data;
  }
}
