import { Component, Input } from "angular-ts-decorators";
import template from "./waiversignature.html";
import { IWaiver } from "../iwaiver";
import { IWaiverService } from "../iwaiverservice";
import { IRestResource } from "../../../models/IRestResource";
import { StateService } from "@uirouter/angularjs";
import { IUser } from "../../../models/IUser";
import { UserService } from "../../../services/UserService";

const name: string = "waiverSignature";

@Component({
  selector: name,
  template,
})
export class WaiverSignature {
  static $inject: string[] = ["$state", "UserService"];
  static Name: string = name;

  @Input() waiver: IWaiver;

  @Input() waiverService: IWaiverService;

  @Input() resource: IRestResource;

  @Input() teamwaiver: boolean;

  @Input() user: IUser;

  newUser: IUser;
  createAccount: boolean;

  constructor(private $state: StateService, private userService: UserService) {}

  signWaiverAnonymous(waiver: IWaiver) {
    if (this.createAccount) {
      let createdUser;
      this.userService
        .register(this.newUser.email, this.newUser.password)
        .then((theUser) => {
          createdUser = theUser;
          return this.userService.login(
            this.newUser.email,
            this.newUser.password
          );
        })
        .then((theUser) => {
          createdUser.firstName = this.newUser.firstName;
          createdUser.lastName = this.newUser.lastName;
          createdUser.cellPhone = this.newUser.cellPhone;
          return this.userService.save(createdUser);
        })
        .then((user) => {
          this.user = user;
          this.signWaiver(waiver);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.waiverService
        .signWaiverAnonymous(this.resource, waiver, this.newUser)
        .then((response) => {
          this.$state.go("league.home");
        });
    }
  }
  signWaiver(waiver: IWaiver) {
    if (!this.user) {
      return this.signWaiverAnonymous(waiver);
    }
    if (this.teamwaiver) {
      this.waiverService.signTeamWaiver(this.waiver).then((signature) => {
        this.$state.go("league.home");
      });
    } else {
      this.waiverService
        .signWaiver(this.resource, this.waiver)
        .then((signature) => {
          this.$state.go("league.home");
        });
    }
  }

  $onInit() {}
}
