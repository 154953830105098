import { Injectable } from "angular-ts-decorators";
import { IHttpService, IPromise } from "angular";
import { IRegistrationProgram } from "../models/IRegistrationProgram";

@Injectable("RegistrationSystemService")
export class RegistrationSystemService {
  static $inject: string[] = ["$http"];
  constructor(private $http: IHttpService) {}

  get(id: string): IPromise<IRegistrationProgram> {
    return this.$http
      .get<IRegistrationProgram>(`/api/registrationsystems/${id}`)
      .then((response) => response.data);
  }

  add(program: IRegistrationProgram) {
    return this.$http
      .post<IRegistrationProgram>(`/api/registrationprograms`, program)
      .then((response) => response.data);
  }
}
