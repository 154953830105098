import { UIComponent } from "../../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./createteam.template.html";
import { IRegistrationProgram } from "../../../../models/IRegistrationProgram";
import { ILeague } from "../../../../models/ILeague";
import { SeasonService } from "../../../../services/SeasonService";
import { LeagueService } from "../../../../services/LeagueService";
import { ISeason } from "../../../../models/ISeason";
import { IAgeGroup } from "../../../../models/IAgeGroup";
import { ITeamLevel } from "../../../../models/ITeamLevel";
import { AgeGroupService } from "../../../../services/AgeGroupService";
import { RegistrationSystemService } from "../../../../services/RegistrationSystemService";

const name = "stCreateTeam";

@Component({
  selector: name,
  template,
})
export class CreateTeam extends UIComponent {
  static $inject: string[] = [
    "SeasonService",
    "LeagueService",
    "AgeGroupService",
    "RegistrationSystemService",
  ];
  static Name: string = name;

  @Input() league: ILeague;
  seasons: ISeason[];
  agegroups: IAgeGroup[];
  teamlevels: ITeamLevel[];

  public newRegistration: IRegistrationProgram;

  constructor(
    private seasonService: SeasonService,
    private leagueService: LeagueService,
    private agegroupService: AgeGroupService,
    private registrationSystemService: RegistrationSystemService
  ) {
    super();
    this.newRegistration = {} as IRegistrationProgram;
  }

  $onInit() {}

  $onChanges(changeObj) {
    if (this.league) {
      this.newRegistration.league = this.league;
      this.newRegistration.leagueId = this.league.id;
      this.leagueService.getSeasons(this.league).then((seasons) => {
        this.seasons = seasons;
      });
    }
  }

  public nameLabel(value: ISeason): string {
    return value.name;
  }

  public seasonSelect(value: ISeason) {
    this.seasonService.getAgeGroups(value).then((agegroups: IAgeGroup[]) => {
      this.agegroups = agegroups;
    });
  }

  public agegroupSelect(value: IAgeGroup) {
    this.agegroupService
      .getTeamLevels(value)
      .then((teamlevels: ITeamLevel[]) => {
        this.teamlevels = teamlevels;
      });
  }

  public createProgram(registrationSystem: IRegistrationProgram) {
    this.registrationSystemService.add(registrationSystem);
  }

  public firstStepValidation(context: any) {
    if (
      !this.newRegistration ||
      !this.newRegistration.name ||
      !(this.newRegistration.name.length > 2)
    ) {
      return false;
    }
    return true;
  }
}
