import {IVideoType} from '../../models/IVideoType';
import {IVideo} from '../../models/IVideo';
export class VideosModalController {
    static $inject: string[] = ['item'];
    item: IVideo;
    supportedVideos: IVideoType[] = [{
        id: 1,
        type: 'vimeo'
    },
    {
        id: 2,
        type: 'youtube'
    }];

    /**
     * Creates an instance of VideosModalController.
     * 
     * @param {IVideo} item
     * 
     * @memberOf VideosModalController
     */
    constructor(item: IVideo) {
        this.item = item;
    }

    typeLabel(value: any) {
        return value && value.type;
    }
}
