import { StateService } from "@uirouter/core";
import { Component, Input } from "angular-ts-decorators";
import { IModalService } from "angular-ui-bootstrap";
import _ from "lodash";
import moment from "moment";
import { UIComponent } from "../../../core/UIComponent";
import "../../../lib/DateExtensions";
import { IEvent } from "../../../models/IEvent";
import { IFacility } from "../../../models/IFacility";
import { ITeam } from "../../../models/ITeam";
import { IGameDetailService } from "../../../services/IGameDetailService";
import { TeamService } from "../../../services/TeamService";
import { TeamCalendarGame } from "./game/component";
import { TeamCalendarModal } from "./TeamCalendar.modal.controller";
import template from "./TeamCalendar.template.html";

@Component({
  selector: "stTeamCalendar",
  template,
})
export class TeamCalendar extends UIComponent {
  static $inject: string[] = [
    "TeamService",
    "$uibModal",
    "$state",
    "uiCalendarConfig",
  ];

  @Input()
  team: ITeam;
  @Input()
  mode: string;

  events: IEvent[] = [];
  eventSources: any[] = [];
  calendarConfig: any;
  calendarName: string = "teamCalendar";

  /**
   * Creates an instance of News.
   *
   * @param {TeamService} teamService
   * @param {IModalService} $uibModal
   *
   * @memberOf News
   */
  constructor(
    private teamService: TeamService,
    private $uibModal: IModalService,
    private $state: StateService,
    private uiCalendarConfig: any
  ) {
    super();
    let me = this;

    this.calendarConfig = {
      calendar: {
        editable: false,
        header: {
          left: "title",
          center: "month,agendaWeek,agendaDay",
          right: "today prev,next",
        },
        eventClick(calEvent: IEvent, jsEvent, view) {
          me.calendarEventClick(calEvent, jsEvent, view);
        }, // proxy to call in context
      },
    };
  }

  calendarEventClick(event: IEvent, jsEvent: any, view: any) {
    let me = this;
    if (me.ifEditing()) {
      me.navigateEvent(event);
    } else {
      me.$state.go("team.calendar.item", { id: event.id });
    }
  }

  /**
   * helper method to open the settings modal for this component
   *
   * @private
   * @param {*} resolver
   * @returns {ng.IPromise<INews>}
   *
   * @memberOf News
   */
  private openModal(resolver: any): ng.IPromise<IEvent> {
    return this.modal<IEvent>(
      this.$uibModal,
      require("./TeamCalendar.modal.template.html").default,
      TeamCalendarModal,
      resolver
    );
  }

  /**
   * Opens the navigation dialog
   *
   * @private
   * @returns {ng.IPromise<string>}
   *
   * @memberOf TeamCalendar
   */
  private openNavigation(): ng.IPromise<string> {
    return this.modal<string>(
      this.$uibModal,
      require("./TeamCalendar.navigation.modal.html").default,
      null,
      null
    );
  }

  /**
   * Opens the help dialog
   */
  private openHelp(): ng.IPromise<any> {
    return this.modal<any>(
      this.$uibModal,
      require("./TeamCalendar.help.modal.html").default,
      null,
      null
    );
  }

  /**
   * internal method to allow the user to choose what to do with an event in edit mode
   *
   * @param {IEvent} event
   *
   * @memberOf TeamCalendar
   */
  navigateEvent(event: IEvent) {
    let me = this;
    me.openNavigation().then((result) => {
      switch (result) {
        case "view":
          me.view(event);
          break;
        case "edit":
          if (event.id.indexOf("game:") >= 0) {
            me.editGame(event);
          } else {
            me.edit(event);
          }

          break;
        case "delete":
          me.remove(event);
          break;
      }
    });
  }

  /**
   * opens the add dialog and then saves the new news items
   *
   *
   * @memberOf News
   */
  add() {
    let me = this;
    let newDate = new Date();
    newDate.setMinutes(0);
    newDate.setMilliseconds(0);
    me.openModal({
      item() {
        return { startDate: newDate, endDate: newDate };
      },
      facilities: () => me.team.homeFields,
    }).then((result: IEvent) => {
      me.saveFacility(result);
      if (result.isRecurring) {
        me.addRecurrentEvents(result);
      } else {
        me.addEvent(result);
      }
    });
  }

  /**
   * saves a facility entered into the UI for later use
   */
  private saveFacility(event: IEvent) {
    let me = this;
    if (!event.facility) {
      me.teamService.addFacility(me.team, {
        name: event.locationName,
        location: event.location,
      } as IFacility);
    }
  }

  /**
   * add a collection of recurring events
   */
  private addRecurrentEvents(event: IEvent) {
    let me = this;
    _.map(event.occurances, (eventOccurance) => {
      let newEvent = _.clone(event);
      newEvent.isRecurring = false;
      newEvent.occurances = null;
      newEvent.startDate = eventOccurance.startDate;
      newEvent.endDate = eventOccurance.endDate;
      me.addEvent(newEvent);
    });
  }

  /**
   * Adds a single event record
   */
  private addEvent(event: IEvent, retry: boolean = true) {
    let me = this;
    me.teamService
      .addEvent(me.team, event)
      .then((eventResponse: IEvent) => {
        me.cleanEvent(eventResponse);
        me.events.push(eventResponse);
        if (me.events.length === 1) {
          me.eventSources.push({ events: me.events });
        }
        return eventResponse;
      })
      .catch((error) => {
        if (retry) {
          me.addEvent(event, false);
        }
        console.error(error);
      });
  }

  editGame(event: IEvent) {
    this.$uibModal
      .open({
        size: "lg",
        component: TeamCalendarGame.Name,
        resolve: {
          game: () => {
            return event;
          },
          gameDetail: () => {
            return (this.teamService as IGameDetailService).getGameDetail(
              this.team,
              this.stripGameKey(event.id)
            );
          },
        },
      })
      .result.then((result) => {
        if (result.id) {
          return (this.teamService as IGameDetailService).updateGameDetail(
            this.team,
            result
          );
        }
        return (this.teamService as IGameDetailService).addGameDetail(
          this.team,
          result
        );
      });
  }

  private stripGameKey(id: string) {
    return id.substr("game:".length);
  }

  /**
   * edits the current news item
   *
   * @param {INews} news
   *
   * @memberOf News
   */
  edit(event: IEvent) {
    let me = this;
    me.openModal({
      item() {
        return event;
      },
      facilities: () => me.team.homeFields,
    }).then((result: IEvent) => {
      me.teamService
        .updateEvent(me.team, result)
        .then((updatedEvent: IEvent) => {
          event.start = moment(updatedEvent.startDate).toDate();
          event.end = moment(updatedEvent.endDate).toDate();
          if (me.uiCalendarConfig.calendars[me.calendarName] !== undefined) {
            me.uiCalendarConfig.calendars[me.calendarName].fullCalendar(
              "updateEvent",
              event
            );
          }
        });
    });
  }

  /**
   *
   */
  view(event: IEvent) {
    this.$state.go("team.calendar.item", { id: event.id });
  }

  /**
   *
   *
   * @param {INews} news
   * @returns
   *
   * @memberOf News
   */
  remove(event: IEvent) {
    if (!confirm("are you sure you want to remove this event?")) return false;
    let me = this;
    me.teamService.removeEvent(me.team, event);
    let idx = _.findIndex(me.events, (e: IEvent) => e.id === event.id);
    if (idx >= 0) me.events.splice(idx, 1);
    if (me.uiCalendarConfig.calendars[me.calendarName] !== undefined) {
      me.uiCalendarConfig.calendars[me.calendarName].fullCalendar(
        "removeEvents",
        [event.id]
      );
    }
  }

  private cleanUpEvents() {
    let me = this;
    let recurringEvents: IEvent[] = [];
    _.each(me.events, (event: IEvent) => {
      let newEvents = me.explodeRecurring(event);
      if (newEvents) {
        recurringEvents = recurringEvents.concat(newEvents);
      }
    });
    me.events = _.concat(me.events, recurringEvents);
    _.each(me.events, (event: IEvent) => {
      me.cleanEvent(event);
    });
  }

  private explodeRecurring(event: IEvent): IEvent[] {
    if (!event.isRecurring) return null;
    let events: IEvent[] = [];
    _.each(event.occurances, (occurance) => {
      events.push({
        startDate: occurance.startDate,
        endDate: occurance.endDate,
        title: event.title,
        id: event.id,
      } as IEvent);
    });
  }

  private cleanEvent(event: IEvent) {
    event.start = moment(event.startDate).toDate();
    event.end = moment(event.endDate).toDate();
    event.startDate = moment(event.startDate).toDate();
    event.endDate = moment(event.endDate).toDate();
    event.stick = true;
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    let me = this;
    if (changesObj && changesObj.team) {
      if (!me.team || !me.team.id) return;
      me.teamService.getEvents(me.team).then((events: IEvent[]) => {
        me.events = events;
        me.cleanUpEvents();
        me.eventSources.push({ events: me.events });
      });

      me.teamService.getGames(me.team).then((team) => {
        let events = [];
        _.each(team.registrations, (registration) => {
          _.each(registration.homeGames, (game) => {
            if (!game.date) return;
            let e = {
              start: moment(game.date.datetime).toDate(),
              startDate: moment(game.date.datetime).toDate(),
              end: moment(game.date.datetime).toDate().addHours(2),
              endDate: moment(game.date.datetime).toDate().addHours(2),
              title:
                "vs. " +
                (game &&
                  game.awayTeam &&
                  game.awayTeam.team &&
                  game.awayTeam.team.name),
              type: {
                id: 5,
                type: "scheduled game",
              },
              id: "game:" + game.id,
              stick: true,
            } as IEvent;
            events.push(e);
          });
          _.each(registration.awayGames, (game) => {
            if (!game.date) return;
            let e = {
              start: moment(game.date.datetime).toDate(),
              startDate: moment(game.date.datetime).toDate(),
              end: moment(game.date.datetime).toDate().addHours(2),
              endDate: moment(game.date.datetime).toDate().addHours(2),
              title:
                "@" +
                (game &&
                  game.homeTeam &&
                  game.homeTeam.team &&
                  game.homeTeam.team.name),
              type: {
                id: 5,
                type: "scheduled game",
              },
              id: "game:" + game.id,
              stick: true,
            } as IEvent;
            events.push(e);
          });
        });
        me.eventSources.push({ events });
      });
    }
  }

  /**
   *
   * @TODO: Build remote method and subscribe
   *
   * @returns
   *
   * @memberOf TeamCalendar
   */
  subscribeUrl() {
    if (!this.team) return;
    return window.location.host + "/api/teams/" + this.team.id + "/calendar"; // + this.leagueScope.league.currentSeason.id + '/subscribe'\
  }
}
