import { IStandingsAlgorithm } from './IStandingsAlgorithm';
import { ISortingRules } from '../models/ISortingRules';
import { ITeamRegistration } from '../models/ITeamRegistration';
import { Injectable } from "angular-ts-decorators";
import {map, orderBy} from 'lodash';

@Injectable("DynamicStandingsAlgorithm")
export class DynamicStandingsAlgorithm implements IStandingsAlgorithm {

    constructor(private sortingRules: ISortingRules) {

    }

    public sortStandings(teamregistrations: ITeamRegistration[]): ITeamRegistration[] {
        let me = this;
        let sortFields = map(me.sortingRules.rules, 'property') as string[];
        let sortDirectsions = map(me.sortingRules.rules, 'direction') as (boolean | "asc" | "desc")[];
        return orderBy(teamregistrations, sortFields, sortDirectsions);
    }
}
