import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

@Component({
  selector: "stRichText",
  template,
})
export class RichTextInput {
  @Input("@")
  label: string;
  @Input("=")
  value: any;
  @Input("@")
  required: string;

  constructor() {}

  isRequired(): boolean {
    return this.required === "true";
  }
}
