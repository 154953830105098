import { Component, Input, Output } from "angular-ts-decorators";
import template from "./importroster.html";
import { UIComponent } from "../../../core/UIComponent";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { TeamService } from "../../../services/TeamService";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import _ from "lodash";
import { ISeason } from "../../../models/ISeason";
import { TeamRegistrationService } from "../../../services/TeamRegistrationService";
import { StateService } from "@uirouter/core";
import { IRosterEntry } from "../../../models/IRosterEntry";

const name = "stImportRoster";

@Component({
  selector: name,
  template,
})
export class ImportRoster extends UIComponent {
  static $inject: string[] = [
    "TeamService",
    "TeamRegistrationService",
    "$q",
    "$state",
  ];
  static Name: string = name;

  @Input() team: ITeam;
  @Input() user: IUser;

  @Input() season: ISeason;
  @Input() registration: ITeamRegistration;

  public teams: ITeam[];
  public allChecked: boolean = false;

  constructor(
    private teamService: TeamService,
    private registrationService: TeamRegistrationService,
    private $q: ng.IQService,
    private $state: StateService
  ) {
    super();
  }

  $onInit() {
    if (this.user) {
      this.teamService.loadMyTeams(this.user).then((teams) => {
        this.teams = teams;
      });
    }
  }

  $onChanges(changeObj) {}

  public formatName(registration: ITeamRegistration): string {
    return `${registration.league && registration.league.acronym} : ${
      registration.season && registration.season.name
    } : ${registration.ageGroup && registration.ageGroup.name} : ${
      registration.teamLevel && registration.teamLevel.name
    }`;
  }

  public selectAll(list: any[], selected) {
    list.forEach((item) => {
      item.selected = selected;
    });
  }

  public migrateRoster(registration: ITeamRegistration) {
    let promises = [];
    let me = this;
    _.each(registration.roster, function (tr) {
      if (tr.selected) {
        let promise = me.registrationService
          .addRoster(me.registration, {
            player: tr.player,
            number: tr.number,
          } as IRosterEntry)
          .then((result) => {
            tr.migrate = true;
          });
        promises.push(promise);
      }
    });
    this.$q.all(promises).then(() => {
      this.$state.go("league.team.rostermanager", null, { reload: true });
    });
  }
}
