import { ICollection, IService } from 'restangular';
import { IFacility } from '../models/IFacility';
import { ILeagueScope } from '../models/ILeagueScope';
import { StateService } from '@uirouter/angularjs';

export class FacilityController {
    static $inject = ['$state', '$scope', 'Restangular', '$q'];
    facilities: IFacility[];
    currentFacility: IFacility;
    facilitiesService: ICollection;
    
    constructor(private $state: StateService, private leagueScope: ILeagueScope, private Restangular: IService, private $q: ng.IQService) {
        let me = this;
        me.currentFacility = {} as IFacility;
        me.leagueScope.$watch(
            function() { return me.leagueScope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.refresh();
                }
            },
            true
        );
    }
    
    refresh() {
        let me = this;
        me.facilitiesService = me.Restangular.one('leagues', me.leagueScope.league.id).all('facilities');
        me.facilitiesService.getList<IFacility>()
        .then(function(facilities) {
            me.facilities = facilities;
        });
    }
    
    add(facility: IFacility) {
        let me = this;
        me.facilitiesService.post(facility)
        .then(function(addedFacility) {
            me.facilities.push(addedFacility);
            me.currentFacility = {} as IFacility;
        });
    }
    
    setEditing(facility: IFacility, editing: boolean) {
        facility.__uistate = facility.__uistate || {};
        facility.__uistate.editing = editing;
    }
    
    edit(facility: IFacility) {
        this.currentFacility = facility;
        this.setEditing(facility, true);
    }
    
    cancel(facility: IFacility) {
        this.setEditing(facility, false);
        this.currentFacility = {} as IFacility;
    }
    
    save(facility: IFacility) {
        let me = this;
        let facilityService = me.Restangular.one('leagues', me.leagueScope.league.id).one('facilities', facility.id);
        facilityService.customPUT(facility)
        .then(function(updatedFacility) {
            me.currentFacility = {} as IFacility;
            me.setEditing(facility, false);
        });
    }
    
    delete(facility: IFacility) {
        if (!confirm('are you sure you would like to remove this facility?')) return;
        let me = this;
        let facilityService = me.Restangular.one('leagues', me.leagueScope.league.id).one('facilities', facility.id);
        facilityService.remove()
        .then(function(result) {
            let idx = me.facilities.indexOf(facility);
            if (idx >= 0) me.facilities.splice(idx, 1);
        });
    }
}
