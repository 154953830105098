import { Component, Input, Output } from "angular-ts-decorators";
import template from "./Date.template.html";

@Component({
  selector: "stDate",
  template,
})
export class DateComponent {
  @Input("@") label: string;
  @Input() value: Date;
  @Input("@") required: string;
  @Output() onChange: ({ value }) => {};

  myDate: Date;
  dateIsOpened: boolean = false;

  dateOptions: any = {
    // formatYear: 'yyyy',
    startingDay: 0,
  };

  /**
   * Creates an instance of DateTime.
   *
   *
   * @memberOf DateTime
   */
  constructor() {}

  isRequired(): boolean {
    return this.required === "true";
  }

  open() {
    this.dateIsOpened = true;
  }

  dateChange() {
    // this.value = new Date(this.myDate.getTime());
    this.onChange({ value: this.value });
  }

  $onChanges(changesObj) {
    let me = this;
    if (changesObj && changesObj.value) {
      if (me.value) {
        me.myDate = new Date(me.value.getTime());
      } else {
        me.myDate = null;
      }
    }
  }
}
