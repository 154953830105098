import { StateService } from "@uirouter/core";
import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import { RoundRobin } from "../component";
import template from "./index.html";

@Component({
  selector: "stCreateRoundRobin",
  template,
})
export class CreateRoundRobin implements IComponentController {
  static Name = "stCreateRoundRobin";
  static $inject = ["TeamRegistrationService", "$state"];
  public roundRobin: IRoundRobin;

  @Input()
  registration: ITeamRegistration;

  constructor(
    private teamRegistrationService: TeamRegistrationService,
    private $state: StateService
  ) {
    this.roundRobin = {} as IRoundRobin;
  }

  public async create() {
    const roundRobin = await this.teamRegistrationService.createRoundRobin(
      this.registration,
      this.roundRobin
    );
    this.$state.go("^.view", { id: roundRobin.id });
  }
}
