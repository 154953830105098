import { IPageService } from "../../../services/IPagesService";
import { IRestResource } from "../../../models/IRestResource";
import { PagesModalService } from "../pages.modal.service";
import { UIComponent } from "../../../core/UIComponent";
import { IPage } from "../../../models/IPage";
import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

let name: string = "stPagesViewer";

@Component({
  selector: name,
  template,
})
export class PagesViewer extends UIComponent {
  static Name: string = name;
  static $inject = ["PagesModalService"];

  @Input()
  page: IPage;

  @Input()
  mode: string;

  @Input()
  restResource: IRestResource;

  @Input()
  service: IPageService;
  /**
   *
   */
  constructor(private pagesModalService: PagesModalService) {
    super();
  }

  edit() {
    this.pagesModalService.openModal(this.page).then((page: IPage) => {
      this.service.updatePage(this.restResource, page);
    });
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
