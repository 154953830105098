import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { IDivision } from "../../../../models/IDivision";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import template from "./index.html";

@Component({
  selector: "stViewRoundRobin",
  template,
})
export class ViewRoundRobin implements IComponentController {
  static $inject = ["TeamRegistrationService"];
  static Name = "stViewRoundRobin";
  @Input()
  registration: ITeamRegistration;

  @Input()
  roundRobin: IRoundRobin;

  constructor() {}
}
