import { IAgeGroup } from '../models/IAgeGroup';
import { IUser } from '../models/IUser';
import { IRootStateService } from '../models/IRootStateService';
import { ILeagueScope } from '../models/ILeagueScope';
import { IRoleInfo } from '../models/IRoleInfo';
import { IFilter } from '../models/IFilter';
import { StateService } from '@uirouter/core';
import { IService, ICollection } from 'restangular';

// StateService
export class AdminController {
    static $inject = ['$rootScope', '$state', '$scope', 'Restangular', '$timeout', 'notify'];
    dataService: ICollection;
    ageGroups: IAgeGroup[];
    users: IUser[];
    selectedUser: IUser;
    pageSize: number = 10;
    currentPage: number = 1;
    totalPages: number;
    totalUsers: number;
    filterType: any;
    filterTypes: any[];
    filter: string;
    migrateData: string;
    migrateLog: string;
    constructor(private $rootScope: IRootStateService, private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService, private notify: angular.cgNotify.INotifyService) {
        let me = this;
        this.filterTypes = [{
            id: 1,
            label: "email",
            filter: "email"
        },
        {
            id: 2,
            label: "last name",
            filter: "lastName"
        }];
        me.dataService = Restangular.all("users");
        me.refresh();
    }
    refresh() {
        let me = this;
        this.loadUsers();
        if (this.$state.params.userId) {
            this.loadUser(this.$state.params.userId);
        }
    }

    loadUser(userId: string) {
        let me = this;
        this.Restangular.one("users", userId).get()
            .then(function (user: IUser) {
                me.selectedUser = user;
                me.loadRoles(user);
            });
    }

    loadRoles(user: IUser) {
        user.customGET("roles")
            .then(function (roles: IRoleInfo) {
                user.roleInfo = roles;
            });
    }

    loadUsers() {
        let me = this;
        let filter = {
            where:
                {
                    // leagueId: me.$scope.league.id
                },
            /*include: {
                relation: 'leagues',
                scope: {
                    where:
                    {
                        leagueId: me.$scope.league.id
                    }
                }
            },*/
            // limit: this.pageSize, 
            // skip: (this.currentPage - 1) * this.pageSize, 
            order: "lastName ASC"
        } as IFilter;
        if (this.filterType && this.filter) {
            if (this.filterType.id === 1) {
                filter.where[this.filterType.filter] = this.filter.toLocaleLowerCase();
            } else {
                filter.where[this.filterType.filter] = {
                    like: this.filter,
                    options: "i"
                };
            }

        } else {
            filter.limit = this.pageSize;
            filter.skip = (this.currentPage - 1) * this.pageSize;
        }
        this.dataService.customGET("count", filter)
            .then(function (response) {
                me.totalUsers = response.count;
                me.totalPages = Math.floor(response.count / me.pageSize);
                me.dataService.getList({ filter })
                    .then(function (users) {
                        me.users = users;
                    });
            });
    }

    editUser(user: IUser) {
        this.loadUser(user.id);
        this.$state.go("league.admin.users.edit", { userId: user.id });
    }

    reverify(user: IUser) {
        let me = this;
        me.Restangular.one("users", user.id).customGET("reverify")
            .then(function (result) {
                me.notify({ message: "verification email sent", classes: "message-success" });
            })
            .catch(function (error) {
                me.notify({ message: "something went wrong...", classes: "message-error" });
            });
    }

    resetPassword(user: IUser) {
        let me = this;
        me.Restangular.one("users", user.id).customGET("generatepassword")
            .then(function (result) {
                me.notify({ message: "new password is: " + result, classes: "message-success" });
            })
            .catch(function (error) {
                me.notify({ message: "something went wrong...", classes: "message-error" });
            });
    }

    editRoles(user: IUser) {
        let me = this;
        this.loadUser(user.id);
        me.$state.go("league.admin.users.roles", { userId: user.id });
    }

    saveUser(user: IUser) {
        let me = this;
        user.patch()
            .then(function (updatedUser) {
                me.$state.go("league.admin.users.list");
            });
    }

    addRole(user: IUser, role: string) {
        let me = this;
        user.customPOST({ name: role }, "roles")
            .then(function (result) {
                me.selectedUser.roleInfo.roles.push(role);
            });
    }

    deleteRole(user: IUser, role: string) {
        let me = this;
        user.one("roles", role).remove()
            .then(function (response) {
                let idx = me.selectedUser.roleInfo.roles.indexOf(response);
                me.selectedUser.roleInfo.roles.splice(idx, 1);
            });
    }

    updateVerification(user: IUser) {
        if (!confirm("are you sure you want to override the email verification?")) return;
        let obj = { emailVerified: true };
        user.customPATCH(obj)
            .then(function (response) {
                user.emailVerified = response.emailVerified;
            });
    }

    migrate() {
        let me = this;
        let data = JSON.parse(this.migrateData);
        me.migrateTotal = data.length;
        me.callService(data, 0);
    }

    migrateTotal: number;
    migrateCurrent: number;

    callService(list, index) {
        let me = this;
        let svc = this.Restangular.all("teamregistrations");
        if (index < list.length) {
            let tr = list[index];
            tr.leagueId = me.$scope.league.leagueData.id;
            tr.seasonId = me.$scope.league.currentSeason.id;
            svc.customPOST(tr, "migrateWSBL")
                .then(function (result) {
                    me.migrateCurrent = index + 1;
                    me.callService(list, index + 1);
                });
        }
    }

}
