import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import { PermissionsService } from "../../../services/PermissionsService";
import { LeagueService } from "../../../services/LeagueService";
import { ILeague } from "../../../models/ILeague";
import { IUser } from "../../../models/IUser";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IMessage } from "../../../models/IMessage";
import template from "./messages.template.html";
import _ from "lodash";

@Component({
  selector: "stMessages",
  template,
})
export class MessagesComponent extends UIComponent {
  static $inject: string[] = ["LeagueService", "PermissionsService"];
  @Input() league: ILeague;
  @Input() user: IUser;
  @Input() roles: IRoleInfo;
  @Input() mode: string;

  messages: IMessage[];
  addingMessage: boolean = false;

  constructor(
    private leagueService: LeagueService,
    private permissionsService: PermissionsService
  ) {
    super();
  }

  $onChanges(changeObj) {
    let me = this;
    if (me.league) {
      me.leagueService.getMessages(me.league).then((messages) => {
        me.messages = messages;
      });
    }
  }

  addMessage(message: IMessage) {
    let me = this;
    me.leagueService.addMessage(me.league, message).then((messageResponse) => {
      me.messages.push(messageResponse);
      me.addingMessage = false;
    });
  }

  saveMessage(message: IMessage) {
    let me = this;
    me.leagueService.saveMessage(me.league, message).then(() => {
      message.__uistate.editing = false;
    });
  }

  removeMessage(message: IMessage) {
    if (!confirm("Are you sure you want to delete this message?")) return;
    let me = this;
    me.leagueService.removeMessage(me.league, message).then(() => {
      let idx = me.messages.indexOf(message);
      if (idx >= 0) me.messages.splice(idx);
    });
  }
}
