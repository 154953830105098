import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import { IError } from "../../models/IError";
import { IUser } from "../../models/IUser";
import { StateService } from "@uirouter/core";
import { UserService } from "../../services/UserService";
import template from "./template.html";
import "./style.scss";

@Component({
  selector: "stLogin",
  template,
})
export class Login implements IComponentController {
  static $inject: string[] = ["UserService", "$state", "$uibModal"];
  @Input() user: IUser;
  @Input("@") profileState: string;
  error: IError;
  @Output() onLogin: ({ user, token }) => {};
  isRegistered: boolean;
  forgotPassword: boolean;
  resetMessage: boolean;

  constructor(
    private userService: UserService,
    private $state: StateService,
    private $uibModal: angular.ui.bootstrap.IModalService
  ) {}

  $onInit() {
    if (this.user) return; // don't grab the user if we already know it
    this.userService
      .me()
      .then((user: IUser) => {
        this.user = user;
        this.userService
          .accessToken()
          .then((token) => {
            user.accessToken = token.id;
            this.onLogin({ user, token: token.id });
          })
          .catch((ex) => {
            this.user = null;
            this.onLogin({ user: null, token: null });
          });
      })
      .catch((ex) => {
        // do nothing;
      });
  }

  async login(username: string, password: string, isRegistering: boolean) {
    let me = this;
    me.error = null;
    if (isRegistering) {
      me.register(username, password);
      return;
    }
    try {
      let response = await me.userService.login(username, password);
      let token = response.id;
      let user = await me.userService.get(response.userId);
      user.accessToken = token; // set the access token of the current user
      me.user = user;
      me.onLogin({ user, token });
      if (!user.firstName || !user.lastName) {
        me.$state.go(me.profileState);
      } else {
        let notificationDismissed = localStorage.getItem(
          "notification_dismissed"
        );
        if (
          !notificationDismissed &&
          (!user.cellPhone || !user.preferences || !user.preferences.cellNotify)
        ) {
          try {
            let result = await me.$uibModal.open({
              size: "md",
              templateUrl: "notifications_modal.html",
            }).result;
            me.$state.go(me.profileState);
          } finally {
            localStorage.setItem("notification_dismissed", "true");
          }
        }
        me.$state.go(".", { user }, { notify: true, reload: true });
      }
    } catch (ex) {
      me.error = ex && ex.data && ex.data.error;
      console.error(ex);
    }
  }

  async register(username: string, password: string) {
    let me = this;
    me.error = null;
    try {
      let newUser = await me.userService.register(username, password);
      me.isRegistered = true;
    } catch (ex) {
      me.error = ex && ex.data && ex.data.error;
      console.error(ex);
    }
  }

  async resetPassword(email: string) {
    let me = this;
    me.error = null;
    me.resetMessage = false;
    try {
      let rest = await me.userService.resetPassword(email);
      me.resetMessage = true;
    } catch (e) {
      console.error(e);
      me.error = e && e.data && e.data.error;
    }
  }
}
