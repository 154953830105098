import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";
@Component({
  selector: "stPanel",
  template,
  transclude: {
    title: "?panelTitle",
    body: "panelBody",
    footer: "?panelFooter",
  },
})
export class PanelTemplate {
  static $inject = ["$transclude"];
  /**
   *
   */
  constructor(private $transclude: any) {}

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}

  isPresent(slot): boolean {
    return this.$transclude.isSlotFilled(slot);
  }
}
