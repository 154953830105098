import { format, Url } from 'url';
import { IHttpService } from 'angular';
import { IPage } from '../models/IPage';
import { IRestResource } from '../models/IRestResource';
import { IService } from 'restangular';
export interface IPageService {
    getPages?(resource: IRestResource): ng.IPromise<IPage[]>;
    getPageById?(resource: IRestResource, id: string): ng.IPromise<IPage>;
    addPage?(resource: IRestResource, page: IPage): ng.IPromise<IPage>;
    updatePage?(resource: IRestResource, page: IPage): ng.IPromise<IPage>;
    removePage?(resource: IRestResource, page: IPage): ng.IPromise<IPage>;
}

export interface IServiceMixin {
    Restangular: IService;
    $http: IHttpService;
    base: string;
}

export class PageService implements IPageService {

    Restangular: IService;
    base: string;
    $http: IHttpService;

    getPages?(resource: IRestResource): ng.IPromise<IPage[]> {
        return this.$http.get<IPage[]>('api/' + this.base + '/' + resource.id + '/' + 'pages')
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return [] as IPage[];
        });
    }

    getPageById?(resource: IRestResource, id: string): ng.IPromise<IPage> {
        return this.$http.get<IPage>('api/' + this.base + '/' + resource.id + '/' + 'pages/' + id)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return {title: 'Page not found'} as IPage;
        });
    }

    addPage?(resource: IRestResource, page: IPage): ng.IPromise<IPage> {
        return this.Restangular.one(this.base, resource.id).all('pages').customPOST(page);
    }

    updatePage?(resource: IRestResource, page: IPage): ng.IPromise<IPage> {
        return this.Restangular.one(this.base, resource.id).one('pages', page.id).customPUT(page);
    }

    removePage?(resource: IRestResource, page: IPage): ng.IPromise<IPage> {
        return this.Restangular.one(this.base, resource.id).one('pages', page.id).remove();
    }
}
