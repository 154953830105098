import { NgModule } from "angular-ts-decorators";
import { WaiverManager } from "./waivermanager/waivermanager";
import {
  StateProvider,
  Ng1StateDeclaration,
  Transition
} from "@uirouter/angularjs";
import { PersonalWaivers } from "./personalwaivers/personalwaivers";
import { WaiverSignature } from "./waiversignature/waiversignature";
import { LeagueWaiverService } from "../leauge/league.waiver.service";
import { IWaiverService } from "./iwaiverservice";
import { IRestResource } from "../../models/IRestResource";
import { LeagueService } from "../../services/LeagueService";
import { IWaiver } from "./iwaiver";
import { ILeague } from "../../models/ILeague";

@NgModule({
  id: "WaiverModule",
  declarations: [WaiverManager, PersonalWaivers, WaiverSignature],
  providers: [],
  imports: []
})
export class WaiverModule {
  static config($stateProvider: StateProvider) {
    $stateProvider
      .state({
        name: "league.waivermanager",
        url: "/waivermanager",
        component: WaiverManager.Name,
        resolve: {
          waiverService: [
            "LeagueWaiverService",
            leagueWaiverService => {
              return leagueWaiverService;
            }
          ]
        }
      } as Ng1StateDeclaration)
      .state({
        name: "league.waiversignature",
        url: "/waiver/:id",
        component: WaiverSignature.Name,
        resolve: {
          waiverService: [
            "LeagueWaiverService",
            leagueWaiverService => {
              return leagueWaiverService;
            }
          ],
          waiver: [
            "resource",
            "LeagueWaiverService",
            "$transition$",
            (
              resource: IRestResource,
              leagueWaiverService: IWaiverService,
              $transition$: Transition
            ) => {
              return leagueWaiverService.getWaiverById(
                resource,
                $transition$.params().id
              );
            }
          ]
        }
      } as Ng1StateDeclaration)
      .state({
        name: "league.teamwaiversignature",
        url: "/teamwaiver/:registrationid/:id",
        component: WaiverSignature.Name,
        resolve: {
          waiverService: [
            "LeagueWaiverService",
            leagueWaiverService => {
              return leagueWaiverService;
            }
          ],
          teamwaiver: () => {
            return true;
          },
          waiver: [
            "resource",
            "LeagueService",
            "LeagueWaiverService",
            "$transition$",
            (
              resource: IRestResource,
              leagueService: LeagueService,
              leagueWaiverService: IWaiverService,
              $transition$: Transition
            ) => {
              let leagueResource = resource as ILeague;
              return leagueWaiverService
                .getTeamWaiverById(
                  { id: $transition$.params().registrationid },
                  $transition$.params().id
                )
                .then((waiver: IWaiver) => {
                  waiver.text = leagueResource.leaguesettings.waiverText;
                  waiver.name = "Team Waiver";
                  return waiver;
                });
            }
          ]
        }
      } as Ng1StateDeclaration);
  }
}
