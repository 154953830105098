import { Component, Input } from "angular-ts-decorators";
import template from "./emergencycontact.html";
import { IRosterEntry } from "../../../models/IRosterEntry";
import { LeagueRosterService } from "../league.roster.service";
import { StateService } from "@uirouter/core";

const name: string = "emergencyContact";

@Component({
  selector: name,
  template,
})
export class EmergencyContact {
  @Input() public rosterEntry: IRosterEntry;
  @Input() public showPlayer: boolean;
  @Input("@") public afterState: string;
  public static Name: string = name;
  static $inject: string[] = ["LeagueRosterService", "$state"];

  constructor(
    private leagueRosterService: LeagueRosterService,
    private $state: StateService
  ) {}

  save(rosterEntry: IRosterEntry) {
    this.leagueRosterService
      .updateEmergencyContact(rosterEntry)
      .then((roster) => {
        this.$state.go(this.afterState);
      });
  }
}
