import _ from "lodash";
import { IAgeGroup } from "../models/IAgeGroup";
import { IFilter } from "../models/IFilter";
import { IGame } from "../models/IGame";
import { ILeagueScope } from "../models/ILeagueScope";
import { IService } from "restangular";
import { ITeamRegistration } from "../models/ITeamRegistration";
import { StateService } from "@uirouter/angularjs";
import { TeamRegistrationService } from "../services/TeamRegistrationService";

export class RegistrationDashboardController {
  static $inject = [
    "$state",
    "$scope",
    "Restangular",
    "TeamRegistrationService",
  ];
  constructor(
    private $state: StateService,
    private $scope: ILeagueScope,
    private Restangular: IService,
    private teamRegistrationService: TeamRegistrationService
  ) {
    let me = this;
    if ($scope.league.selectedAgeGroup) {
      this.refresh($scope.league.selectedAgeGroup);
    }
  }
  predicate: string = "team.name";
  groupedTeams: _.Dictionary<ITeamRegistration[]>;
  rosters: ITeamRegistration[];
  refresh(ageGroup: IAgeGroup) {
    let me = this;
    if (ageGroup) me.$scope.league.setAgeGroup(ageGroup);
    let filter: IFilter = {
      fields: {
        id: true,
        divisionId: true,
        ageGroupId: true,
        teamlevelId: true,
        teamId: true,
        divisionPreference: true,
      },
      include: [
        {
          relation: "roster",
          scope: {
            fields: ["id", "waiver", "emergencyContact"],
          },
        },
        {
          relation: "homeDates",
          scope: {
            fields: ["id", "datetime", "taken"],
          },
        },
        {
          relation: "homeGames",
          scope: {
            fields: [
              "id",
              "dateId",
              "homeTeamScore",
              "awayTeamScore",
              "homeTeamForfeit",
              "awayTeamForfeit",
            ],
          },
        },
        {
          relation: "awayGames",
          scope: {
            fields: [
              "id",
              "dateId",
              "homeTeamScore",
              "awayTeamScore",
              "homeTeamForfeit",
              "awayTeamForfeit",
            ],
          },
        },
        {
          relation: "team",
          scope: {
            fields: ["name", "urlKey", "managerId"],
            include: [
              {
                relation: "manager",
                scope: {
                  fields: ["email"],
                },
              },
            ],
          },
        },
        {
          relation: "ageGroup",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "teamLevel",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "division",
          scope: {
            fields: ["name"],
          },
        },
        {
          relation: "payments",
          scope: {
            fields: [
              "amount",
              "amount_total",
              "status",
              "updated",
              "created",
              "modified",
            ],
          },
        },
      ],
      where: {
        ageGroupId: ageGroup.id,
      },
    };
    me.Restangular.one("seasons", me.$scope.league.currentSeason.id)
      .all("teamregistrations")
      .getList<ITeamRegistration>({ filter })
      .then(function (registrations) {
        if (registrations) {
          _.each(registrations, function (value: any, key, collection) {
            value.confirmedWaivers = _.filter(value.roster, {
              waiver: true,
            }).length;
            value.emergencyContacts = _.filter(value.roster, (r) => {
              return r.emergencyContact != null;
            }).length;
            value.totalGames = value.homeGames.length + value.awayGames.length;
            value.gamesWithDates =
              _.filter(value.homeGames, function (g: IGame) {
                return g.dateId != null;
              }).length +
              _.filter(value.awayGames, function (g: IGame) {
                return g.dateId != null;
              }).length;
            value.gamesPlayed = _.filter(
              value.homeGames.concat(value.awayGames),
              (g: IGame) => {
                return (
                  g.awayTeamScore >= 0 ||
                  g.homeTeamScore >= 0 ||
                  g.awayTeamForfeit ||
                  g.homeTeamForfeit
                );
              }
            ).length;
          });
          me.rosters = registrations;
          me.groupedTeams = _.groupBy(
            registrations,
            function (team: ITeamRegistration) {
              return team.ageGroup.name;
            }
          );
          _.each(me.groupedTeams, function (value: any, key, collection) {
            collection[key].groupedTeamLevels = _.groupBy(
              value,
              function (team: ITeamRegistration) {
                return team.teamLevel && team.teamLevel.name;
              }
            );
            _.each(collection[key].groupedTeamLevels, function (v: any, k, c) {
              v.confirmedWaivers = _.filter(v.roster, { waiver: true }).length;
            });
          });
        }
      });
  }

  keys(obj) {
    return obj ? Object.keys(obj) : [];
  }

  unregister(registrationId: string) {
    if (!confirm("are you sure you want to unregister this team?")) return;
    this.teamRegistrationService.unregister(registrationId).then((response) => {
      this.refresh(this.$scope.league.selectedAgeGroup);
    });
  }
}
