import { Component, Input, Output } from "angular-ts-decorators";
import { IListComponent } from "../../core/IListComponent";
import { CarouselModalController } from "./Carousel.modal.controller";
import { NewsModalController } from "../news/News.modal.controller";
import { IRestResource } from "../../models/IRestResource";
import { ICarouselService } from "../../services/ICarouselService";
import { UIComponent } from "../../core/UIComponent";
import { ICarousel } from "../../models/ICarousel";
import template from "./Carousel.template.html";

@Component({
  selector: "stCarousel",
  template,
})
export class CarouselComponent
  extends UIComponent
  implements IListComponent<ICarousel>
{
  static $inject: string[] = ["$timeout", "Upload", "$uibModal"];

  sliderInterval: number = 5000;
  carousels: ICarousel[];
  @Input() restResource: IRestResource;
  @Input() mode: string;
  @Input() carouselService: ICarouselService;

  /**
   * Creates an instance of CarouselComponent.
   *
   * @param {TeamService} teamService
   * @param {ng.ITimeoutService} $timeout
   * @param {angular.angularFileUpload.IUploadService} Upload
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf CarouselComponent
   */
  constructor(
    private $timeout: ng.ITimeoutService,
    private Upload: angular.angularFileUpload.IUploadService,
    private $uibModal: angular.ui.bootstrap.IModalService
  ) {
    super();
  }

  /**
   *
   *
   * @returns {number}
   *
   * @memberOf CarouselComponent
   */
  public slideInterval(): number {
    return this.ifEditing() ? 0 : this.sliderInterval;
  }

  /**
   * helper method to open the settings modal for this component
   *
   * @private
   * @param {*} resolver
   * @returns {ng.IPromise<INews>}
   *
   * @memberOf News
   */
  private openModal(resolver: any): ng.IPromise<ICarousel> {
    return this.modal<ICarousel>(
      this.$uibModal,
      require("./Carousel.modal.template.html").default,
      CarouselModalController,
      resolver
    );
  }

  /**
   *
   *
   *
   * @memberOf CarouselComponent
   */
  public add() {
    const me = this;
    me.openModal({
      item() {
        return {};
      },
    }).then(function (result: ICarousel) {
      me.carouselService
        .addCarousel(me.restResource, result)
        .then((carousel: ICarousel) => {
          me.carousels.push(carousel);
        });
    });
  }

  /**
   *
   *
   * @param {ICarousel} carousel
   *
   * @memberOf CarouselComponent
   */
  public edit(carousel: ICarousel) {
    const me = this;
    me.openModal({
      item() {
        return carousel;
      },
    }).then(function (result: ICarousel) {
      me.carouselService.updateCarousel(me.restResource, result);
    });
  }

  /**
   *
   *
   * @param {ICarousel} carousel
   * @returns
   *
   * @memberOf CarouselComponent
   */
  public remove(carousel: ICarousel) {
    if (!confirm("are you sure you want to delete this banner?")) return false;
    const me = this;
    me.carouselService.removeCarousel(me.restResource, carousel);
    const idx = me.carousels.indexOf(carousel);
    if (idx >= 0) me.carousels.splice(idx, 1);
  }

  $onChanges(changesObj) {
    const me = this;
    if (!me.restResource || !me.restResource.id) return;
    me.carouselService
      .getCarousels(me.restResource)
      .then((carousels: ICarousel[]) => {
        me.carousels = carousels;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
