import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";
@Component({
  selector: "stFieldDisplay",
  template,
})
export class FieldDisplay {
  static $inject = [];

  @Input("@")
  label: string;
  @Input()
  value: string;

  /**
   *
   */
  constructor() {}

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
