import { ICarousel } from '../models/ICarousel';
import { ICollection, IService } from 'restangular';
import { IFilter } from '../models/IFilter';
import { ILeagueScope } from '../models/ILeagueScope';
import { StateService } from '@uirouter/angularjs';
export class CarouselController {
    static $inject = ['$state', '$scope', 'Restangular', '$timeout', 'Upload'];
    newCarousel: ICarousel;
    dataService: ICollection;
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService, private Upload: angular.angularFileUpload.IUploadService) {
        this.newCarousel = {} as ICarousel;
        this.dataService = this.Restangular.one('leagues', $scope.league.id).all('carousels');
    }

    add(carousel: ICarousel) {
        let me = this;
        let filter: IFilter = {
            include: ['image']
        };
        me.dataService.post(carousel)
            .then(function(response) {
                me.Restangular.one('leagues', me.$scope.league.id).one('carousels', response.id).get({ filter })
                    .then(function(updated: ICarousel) {
                        updated.image = updated.image;
                        me.$scope.league.leagueData.carousels.push(updated);
                    });
            });
    }

    edit(carousel: ICarousel) {
        this.setEditing(carousel, true);
    }

    setEditing(carousel: ICarousel, editing: boolean) {
        if (!carousel.__uistate) carousel.__uistate = { editing: false };
        carousel.__uistate.editing = editing;
    }

    delete(carousel: ICarousel) {
        let me = this;
        me.Restangular.one('leagues', me.$scope.league.id).one('carousels', carousel.id).remove()
            .then(function(result) {
                let idx = me.$scope.league.leagueData.carousels.indexOf(carousel);
                me.$scope.league.leagueData.carousels.splice(idx, 1);
            });
    }

    save(carousel: ICarousel) {
        let me = this;
        me.Restangular.one('leagues', me.$scope.league.id).one('carousels', carousel.id).customPUT(carousel)
            .then(function(response) {
                me.setEditing(carousel, false);
            });
    }

    uploadPhoto(file, destination: ICarousel) {
        if (!file) return;
        let me = this;

        let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
            method: 'POST',
            data: { file },
            url: 'api/files/upload'
        };
        file.upload = me.Upload.upload(fileUploadConfig);
        file.upload.then(function(response) {
            me.$timeout(function() {
                destination.image = response.data;
                destination.imageId = response.data.id;
            });
        });
    }
}
