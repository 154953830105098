import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import { ILeague } from "../../models/ILeague";
import { ILeagueNotification } from "../../models/ILeagueNotification";
import { INotification } from "../../models/INotification";
import { IRoleInfo } from "../../models/IRoleInfo";
import { ITeam } from "../../models/ITeam";
import { IUser } from "../../models/IUser";
import { LeagueService } from "../../services/LeagueService";
import { TeamService } from "../../services/TeamService";
import template from "./template.html";

let name: string = "stNotifications";

@Component({
  selector: name,
  template,
})
export class SiteNotifications implements IComponentController {
  static $inject: string[] = ["TeamService", "notify"];
  static Name: string = name;

  /**
   * The passed in instance of a team object
   *
   * @type {ITeam}
   * @memberOf NotificationComponent
   */
  @Input()
  league: ILeague;

  /**
   *
   *
   * @type {IUser}
   * @memberOf NotificationComponent
   */
  @Input()
  user: IUser;

  @Input()
  roles: IRoleInfo;

  /**
   * Creates an instance of NotificationComponent.
   *
   * @memberOf NotificationComponent
   */
  constructor(
    private leagueService: LeagueService,
    private notify: angular.cgNotify.INotifyService
  ) {}

  $onInit() {}

  async sendMessage(notification: ILeagueNotification) {
    let me = this;
    try {
      notification.from = me.user.email;
      await me.leagueService.notify(me.league, notification);
      me.notify({
        message: "the message was successfully sent",
        classes: "message-success",
      });
      notification.sendText = false;
      notification.managers = false;
      notification.teamStaff = false;
      notification.rosterGuardians = false;
      notification.message = null;
      notification.subject = null;
    } catch (ex) {
      console.error(ex);
      me.notify({
        message: "something went wrong sending the message, please try again",
        classes: "message-danger",
      });
    }
  }
}
