import _ from "lodash";
import angular from "angular";
import moment from "moment";
import { Component, Input } from "angular-ts-decorators";
import { IAgeGroup } from "../../models/IAgeGroup";
import { IFlight } from "../../models/IFlight";
import { IGame } from "../../models/IGame";
import { IRound } from "../../models/IRound";
import { ITeam } from "../../models/ITeam";
import { ITournament } from "../../models/ITournament";
import { IWindowService } from "angular";
import { UIComponent } from "../../core/UIComponent";
import template from "./ticker.template.html";

@Component({
  selector: "stGameTicker",
  template,
})
export class TickerComponent extends UIComponent {
  static $inject: string[] = ["$window"];
  @Input()
  games: IGame[];
  upcomingGames: IGame[];
  @Input()
  ageGroup: IAgeGroup;
  @Input()
  tournaments: ITournament[];

  limit: number = 3;

  constructor(private $window: IWindowService) {
    super();
  }

  $onInit() {
    let me = this;

    let resize = function () {
      me.limit = 3;
      if (me.$window.innerWidth >= 768) {
        me.limit = 4;
      }
      if (me.$window.innerWidth >= 992) {
        me.limit = 6;
      }
      if (me.$window.innerWidth >= 1200) {
        me.limit = 12;
      }
    };
    angular.element(this.$window).on("resize", resize);
    resize();
  }

  $onChanges(changeObj) {
    if (
      (changeObj && changeObj.tournaments) ||
      changeObj.games ||
      changeObj.ageGroup
    ) {
      this.getUpcomingGames();
    }
  }

  getUpcomingGames() {
    let me = this;
    let playOffGames = [];
    _.each(me.tournaments, function (tournament: ITournament) {
      let ageGroup = me.ageGroup;
      if (ageGroup && tournament.agegroupId !== ageGroup.id) {
        return true;
      }
      let tourneyGames = [];
      _.each(tournament.flights, (flight: IFlight) => {
        _.each(flight.games, (game: IGame) => {
          game.agegroup = tournament.agegroup;
          game.teamlevel = tournament.teamlevel;
        });
        tourneyGames = _.concat(tourneyGames, flight.games);
        _.each(flight.rounds, (round: IRound) => {
          _.each(round.games, (game: IGame) => {
            game.agegroup = tournament.agegroup;
            game.teamlevel = tournament.teamlevel;
          });
          tourneyGames = _.concat(tourneyGames, round.games);
        });
      });
      _.each(tournament.championship, (round: IRound) => {
        _.each(round.games, (game: IGame) => {
          game.agegroup = tournament.agegroup;
          game.teamlevel = tournament.teamlevel;
        });
        tourneyGames = _.concat(tourneyGames, round.games);
      });
      playOffGames = _.concat(playOffGames, tourneyGames);
      playOffGames = _.filter(playOffGames, function (game: IGame) {
        return (
          (game.homeTeam != null || game.homeTeamPromise != null) &&
          (game.awayTeam != null || game.awayTeamPromise != null)
        );
      });
    });

    let previousDate = new Date();
    previousDate.setDate(previousDate.getDate() - 7);
    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 7);
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let recentGames = _.filter(me.games, function (game) {
      let theDate = game && game.date && moment(game.date.datetime).toDate();
      return (
        !game.isTournamentMatch &&
        theDate &&
        theDate > previousDate &&
        theDate < futureDate
      );
    });
    let gamesWithScores = _.filter(recentGames, function (game: IGame) {
      let theDate = moment(game.date.datetime).toDate();
      return (
        theDate >= yesterday ||
        (game.homeTeamScore != null && game.awayTeamScore != null)
      );
    });
    let filteredGames = _.sortBy(gamesWithScores, "date.datetime");
    me.curPage = _.findIndex(filteredGames, function (game) {
      let theDate = moment(game.date.datetime).toDate();
      return theDate > today;
    });
    if (me.curPage < 0) me.curPage = 0;

    me.upcomingGames = _.concat(playOffGames, filteredGames);
  }

  curPage: number;

  teamAcronym(team: ITeam) {
    if (!team || !team.name) return "tbd";
    let parts = team.name.split(" ");
    let acronym = "";
    _.each(parts, function (p) {
      acronym = acronym + p.substr(0, 1);
    });
    return acronym;
  }
}
