import { StateService } from "@uirouter/core";
import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { IGame } from "../../../../models/IGame";
import { IInvitation } from "../../../../models/IInvitation";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { IScheduleConfig } from "../../../../models/IScheduleConfig";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { ITeamRegistrationGroup } from "../../../../models/ITeamRegistrationGroup";
import { GameService } from "../../../../services/GameService";
import { SchedulingService } from "../../../../services/SchedulingService";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import template from "./index.html";

@Component({
  selector: "stRoundRobinGenerate",
  template,
})
export class RoundRobinGenerate implements IComponentController {
  static $inject = ["TeamRegistrationService", "SchedulingService", "GameService", "$state"];
  static Name = "stRoundRobinGenerate";

  @Input()
  registration: ITeamRegistration;

  @Input()
  roundRobin: IRoundRobin;

  @Input()
  roundRobinDetails: IRoundRobin;

  public teamregistrations: ITeamRegistration[];
  public games: IGame[];

  constructor(
    private registrationService: TeamRegistrationService,
    private scheduleService: SchedulingService,
    private gameService: GameService,
    private $state: StateService
  ) { }

  async $onInit() {
    if (!this.roundRobin) return;
    this.teamregistrations = this.roundRobin.invitations
      ?.filter((invitation) => {
        return invitation.accepted;
      })
      ?.map((invitation: IInvitation) => {
        return invitation.participant;
      });
    this.teamregistrations.push(({
      id: this.registration.id,
      homeGames: [],
      awayGames: [],
      team: this.registration.team,
      league: this.registration.league,
      divisionId: this.registration.divisionId
    } as unknown) as ITeamRegistration);
  }

  async generate() {
    this.teamregistrations = this.teamregistrations.map(
      (team) => {
        team.awayGames = [];
        team.homeGames = [];
        return team;
      }
    );
    this.games = this.scheduleService.createRoundRobin(
      this.teamregistrations
    );
  }

  async save() {
    const gamesToSave: IGame[] = this.games.map((game): IGame => {
      return {
        awayTeamId: game.awayTeam.id,
        homeTeamId: game.homeTeam.id,
        gameType: "roundrobin",
        roundRobinId: this.roundRobin.id,
        seasonId: this.registration.seasonId
      } as IGame;
    })
    const savedGames = this.gameService.batchSchedule(gamesToSave);
    this.$state.go('^');
  }
}
