import _ from "lodash";
import moment from "moment";
import { Component, Input, Output } from "angular-ts-decorators";
import { IAvailability } from "../../../models/IAvailability";
import { IDateTime } from "../../../models/IDateTime";
import { IFacility } from "../../../models/IFacility";
import { IFilter } from "../../../models/IFilter";
import { IFlight } from "../../../models/IFlight";
import { IGame } from "../../../models/IGame";
import { IRound } from "../../../models/IRound";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ITournament } from "../../../models/ITournament";
import { SchedulingService } from "../../../services/SchedulingService";
import { StandingsService } from "../../../services/StandingsService";
import { IService } from "restangular";
import "../../../lib/DateExtensions";
// import moment from 'moment';
import template from "./template.html";

@Component({
  selector: "stTournamentSchedule",
  template,
})
export class TournamentSchedule {
  static $inject = ["Restangular", "StandingsService", "SchedulingService"];
  @Input()
  tournament: ITournament;
  @Input("@")
  leagueId: string;
  @Input()
  facilities: IFacility[];
  newFacility: IFacility = {} as IFacility;
  selectedFacility: IFacility;
  newAvailability: IAvailability = {} as IAvailability;
  availableSlots: IDateTime[];
  // gameTime:number = 45;
  // restTime:number = 45;
  constructor(
    private Restangular: IService,
    private standingsService: StandingsService,
    private schedulingService: SchedulingService
  ) {}

  getAvailability(tournament: ITournament) {
    let me = this;
    _.each(tournament.flights, function (flight: IFlight) {
      _.each(flight.games, function (game: IGame) {
        if (
          (game.homeTeam && game.homeTeam.byeTeam) ||
          (game.awayTeam && game.awayTeam.byeTeam)
        )
          return true;
        let slotIndex = me.findFirstAvailableTime(
          flight,
          me.availableSlots,
          game,
          me.tournament.gameTime,
          me.tournament.restTime
        );
        let slot = me.availableSlots.splice(slotIndex, 1);
        game.date = slot && slot[0];
        me.setTaken(game.date);
      });
    });
    _.each(tournament.flights, function (flight: IFlight) {
      _.each(flight.rounds, function (round: IRound) {
        _.each(round.games, function (game: IGame) {
          if (
            (game.homeTeam && game.homeTeam.byeTeam) ||
            (game.awayTeam && game.awayTeam.byeTeam)
          )
            return true;
          let slotIndex = me.findFirstAvailableBracketTime(
            flight,
            round,
            me.availableSlots,
            game,
            me.tournament.gameTime,
            me.tournament.restTime
          );
          let slot = me.availableSlots.splice(slotIndex, 1);
          game.date = slot && slot[0];
          me.setTaken(game.date);
        });
      });
    });
    _.each(tournament.championship, function (round: IRound) {
      _.each(round.games, function (game: IGame) {
        if (
          (game.homeTeam && game.homeTeam.byeTeam) ||
          (game.awayTeam && game.awayTeam.byeTeam)
        )
          return true;
        let slotIndex = me.findFirstAvailableChampionshipTime(
          tournament,
          round,
          me.availableSlots,
          game,
          me.tournament.gameTime,
          me.tournament.restTime
        );
        let slot = me.availableSlots.splice(slotIndex, 1);
        game.date = slot && slot[0];
        me.setTaken(game.date);
      });
    });
  }

  setTaken(date: IDateTime) {
    if (!date) return;
    date.taken = true;
  }

  findFirstAvailableBracketTime(
    flight: IFlight,
    round: IRound,
    availableSlots: IDateTime[],
    game: IGame,
    gameTime: number,
    rest: number
  ): number {
    let me = this;
    return _.findIndex(availableSlots, function (slot: IDateTime) {
      if (round.roundNumber === 1) {
        let lastGame = me.findLastGameTime(flight.games);
        let finalDate =
          lastGame &&
          lastGame.datetime &&
          lastGame.datetime.clone().addMinutes(gameTime + rest);
        return slot.datetime > finalDate;
      } else {
        let previousGames = [
          flight.rounds[round.roundNumber - 2].games[
            (game.game_number - 1) * 2
          ],
          flight.rounds[round.roundNumber - 2].games[
            (game.game_number - 1) * 2 + 1
          ],
        ];
        let lastGame = me.findLastGameTime(
          _.concat(previousGames, flight.games)
        );
        let finalDate =
          lastGame &&
          lastGame.datetime &&
          lastGame.datetime.clone().addMinutes(gameTime + rest);
        return slot.datetime > finalDate;
      }
    });
  }

  findFirstAvailableChampionshipTime(
    tournament: ITournament,
    round: IRound,
    availableSlots: IDateTime[],
    game: IGame,
    gameTime: number,
    rest: number
  ): number {
    let me = this;
    return _.findIndex(availableSlots, function (slot: IDateTime) {
      if (round.roundNumber === 1) {
        let allGames = [];
        _.each(tournament.flights, function (flight) {
          allGames = _.concat(
            allGames,
            flight.rounds[flight.rounds.length - 1].games
          );
        });
        let lastGame = me.findLastGameTime(allGames);
        let finalDate =
          lastGame &&
          lastGame.datetime &&
          lastGame.datetime.clone().addMinutes(gameTime + rest);
        return slot.datetime > finalDate;
      } else {
        let lastGame = me.findLastGameTime(
          tournament.championship[round.roundNumber - 2].games
        );
        let finalDate =
          lastGame &&
          lastGame.datetime &&
          lastGame.datetime.clone().addMinutes(gameTime + rest);
        return slot.datetime > finalDate;
      }
    });
  }

  findFirstAvailableTime(
    flight: IFlight,
    availableSlots: IDateTime[],
    game: IGame,
    gameTime: number,
    rest: number
  ): number {
    let me = this;
    return _.findIndex(availableSlots, function (slot: IDateTime) {
      let homeTeam = me.findTeamById(
        flight.teamregistrations,
        game.homeTeamId || (game.homeTeam && game.homeTeam.id)
      );
      let awayTeam = me.findTeamById(
        flight.teamregistrations,
        game.awayTeamId || (game.awayTeam && game.awayTeam.id)
      );
      let homeTeamGames = _.filter(flight.games, function (otherGames: IGame) {
        return (
          (otherGames.homeTeam && otherGames.homeTeam.id) ===
            (game.homeTeam && game.homeTeam.id) ||
          (otherGames.awayTeam && otherGames.awayTeam.id) ===
            (game.homeTeam && game.homeTeam.id)
        );
      });
      let awayTeamGames = _.filter(flight.games, function (otherGames: IGame) {
        return (
          (otherGames.homeTeam && otherGames.homeTeam.id) ===
            (game.awayTeam && game.awayTeam.id) ||
          (otherGames.awayTeam && otherGames.awayTeam.id) ===
            (game.awayTeam && game.awayTeam.id)
        );
      });
      let lastGame = me.findLastGameTime(
        _.concat(homeTeamGames, awayTeamGames)
      );
      let finalDate =
        lastGame &&
        lastGame.datetime &&
        lastGame.datetime.clone().addMinutes(gameTime + rest);
      return slot.datetime > finalDate;
    });
  }

  findTeamById(teamRegistrations: ITeamRegistration[], id): ITeamRegistration {
    return _.find(teamRegistrations, function (tr: ITeamRegistration) {
      return tr.id === id;
    });
  }

  findLastGameTime(combinedGames: IGame[]): IDateTime {
    let gamesWithDates = _.filter(combinedGames, function (game: IGame) {
      return game && game.date && game.date.datetime;
    });
    gamesWithDates = _.orderBy(gamesWithDates, function (game: IGame) {
      return game && game.date && game.date.datetime;
    });
    return gamesWithDates.length ? gamesWithDates.pop().date : null;
  }

  /*
    cloneDate(date:Date):Date {
        if (!date) return null;
        return new Date(date.getTime());
    }
    
    addMinutes(date:Date, minutes:number):Date {
        if (!date) return null;
        var cd = this.cloneDate(date);
        cd.setMinutes(cd.getMinutes()+minutes);
        return cd;
    }*/

  updateAvailability() {
    this.calculateFacilityAvailability(this.facilities);
  }

  calculateFacilityAvailability(facilities: IFacility[]) {
    let me = this;
    me.availableSlots = [];
    _.each(facilities, function (facility: IFacility) {
      facility.__uistate = facility.__uistate || { include: false };
      if (!facility.__uistate || !facility.__uistate.include) {
        return true;
      }
      facility.slots = [];
      _.each(facility.availability, function (avail: IAvailability) {
        avail.__uistate = avail.__uistate || { include: false };
        if (!avail.__uistate || !avail.__uistate.include) {
          return true;
        }
        avail.startdate = moment(avail.startdate).toDate();
        avail.enddate = moment(avail.enddate).toDate();
        let diffMs = (avail.enddate as any) - (avail.startdate as any);
        let diffMins = Math.round(diffMs / 1000 / 60);
        let slots = Math.floor(diffMins / me.tournament.startInterval);
        for (let i = 0; i < diffMins; i += me.tournament.startInterval) {
          let newSlot = {
            datetime:
              avail && avail.startdate && avail.startdate.clone().addMinutes(i),
            facility,
          } as IDateTime;

          let alreadyTaken = _.find(facility.dates, function (date: IDateTime) {
            date.datetime = moment(date.datetime).toDate();
            let endRange =
              date &&
              date.datetime &&
              date.datetime.clone().addMinutes(me.tournament.gameTime);
            return (
              newSlot.datetime >= date.datetime && newSlot.datetime < endRange
            );
          });
          if (alreadyTaken) {
            newSlot.taken = true;
            facility.slots.push(newSlot);
          } else {
            me.availableSlots.push(newSlot);
            facility.slots.push(newSlot);
          }
        }
      });
    });
    me.availableSlots = _.sortBy(me.availableSlots, function (slot) {
      return slot.datetime;
    });
  }

  addAvailability(
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) {
    let me = this;
    let availability = {} as IAvailability;
    availability.startdate = moment(
      startDate + " " + startTime,
      "YYYY-MM-DD h:mmA"
    ).toDate();
    availability.enddate = moment(
      endDate + " " + endTime,
      "YYYY-MM-DD h:mmA"
    ).toDate();
    this.Restangular.one("facilities", me.selectedFacility.id)
      .all("availability")
      .post(availability)
      .then(function (availabilityResult) {
        if (!me.selectedFacility.availability) {
          me.selectedFacility.availability = [];
        }
        availabilityResult.startdate = moment(
          availabilityResult.startdate
        ).toDate();
        availabilityResult.enddate = moment(
          availabilityResult.enddate
        ).toDate();

        me.selectedFacility.availability.push(availabilityResult);
        me.newAvailability = {} as IAvailability;
      });
  }

  deleteAvailability(facility: IFacility, availability: IAvailability) {
    let me = this;
    me.Restangular.one("facilities", facility.id)
      .one("availability", availability.id)
      .remove()
      .then(function (result) {
        let idx = facility.availability.indexOf(availability);
        if (idx >= 0) facility.availability.splice(idx, 1);
      });
  }

  selectFacility(facility: IFacility) {
    this.selectedFacility = facility;
  }
  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    let me = this;
    me.calculateFacilityAvailability(me.facilities);
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
