import { Injectable } from "angular-ts-decorators";
import { IHttpService } from "angular";
import { IDivision } from "../models/IDivision";

@Injectable("TeamLevelService")
export class TeamLevelService {
  static $inject = ["$http"];
  constructor(private $http: IHttpService) {}

  get(id: string) {
    return this.$http.get<IDivision>(`/api/teamlevels/${id}`).then(response => response?.data);
  }

  getDivisions(teamLevelId: string) {
    return this.$http.get<IDivision[]>(`/api/teamlevels/${teamLevelId}/divisions`).then(response => response?.data);
  }
}
