import { NgModule } from "angular-ts-decorators";
import { Wizard } from "./wizard";
import { Step } from "./step";
import { NextButton } from "./next";
import { PreviousButton } from "./previous";
import { FinishButton } from "./finish";

@NgModule({
  id: "wizard-module",
  declarations: [Wizard, Step, NextButton, PreviousButton, FinishButton],
  imports: [],
  providers: []
})
export class WizardModule {}
