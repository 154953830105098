import _ from 'lodash';
import { IFilter } from '../models/IFilter';
import { ILeagueScope } from '../models/ILeagueScope';
import { IService } from 'restangular';
import { ITeamRegistration } from '../models/ITeamRegistration';
import { StateService } from '@uirouter/angularjs';

export class RosterReportController {
    static $inject = ['$state', '$scope', 'Restangular', '$timeout'];
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService) {
        let me = this;
        me.$scope.$watch(
            function() { return me.$scope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.refresh();
                }
            },
            true
        );
    }

    groupedTeams: _.Dictionary<ITeamRegistration[]>;
    rosters: ITeamRegistration[];
    refresh() {
        let me = this;
        let filter: IFilter = {
            fields: { id: true, divisionId: true, ageGroupId: true, teamlevelId: true, teamId: true },
            include: [
                {
                    relation: 'roster',
                    scope: {
                        fields: ['id', 'waiver']
                    }
                },
                {
                    relation: 'team',
                    scope: {
                        fields: ['name', 'urlKey']
                    }
                },
                {
                    relation: 'ageGroup',
                    scope: {
                        fields: ['name']
                    }
                },
                {
                    relation: 'teamLevel',
                    scope: {
                        fields: ['name']
                    }
                },
                {
                    relation: 'division',
                    scope: {
                        fields: ['name']
                    }
                }]
        };
        me.Restangular.one('seasons', me.$scope.league.currentSeason.id).all('teamregistrations').getList<ITeamRegistration>({ filter })
            .then(function(registrations) {
                if (registrations) {

                    _.each(registrations, function(value: any, key, collection) {
                        value.confirmedWaivers = _.filter(value.roster, { waiver: true }).length;
                    });
                    me.rosters = registrations;
                    me.groupedTeams = _.groupBy(registrations, function(team: ITeamRegistration) {
                        return team.ageGroup.name;
                    });
                    _.each(me.groupedTeams, function(value: any, key, collection) {
                        collection[key].groupedTeamLevels = _.groupBy(value, function(team: ITeamRegistration) {
                            return team.teamLevel && team.teamLevel.name;
                        });
                        _.each(collection[key].groupedTeamLevels, function(v: any, k, c) {
                            v.confirmedWaivers = _.filter(v.roster, { waiver: true }).length;
                        });
                    });
                }
            });

    }

    keys(obj) {
        return obj ? Object.keys(obj) : [];
    }
}
