import { Component, Input } from "angular-ts-decorators";
import template from "./waivermanager.html";
import { IWaiverService } from "../iwaiverservice";
import { IRestResource } from "../../../models/IRestResource";
import { IWaiver } from "../iwaiver";

const name: string = "waiverManager";

@Component({
  selector: name,
  template,
})
export class WaiverManager {
  static Name: string = name;
  @Input()
  waiverService: IWaiverService;
  waivers: IWaiver[];

  @Input()
  resource: IRestResource;

  waiverForm: IWaiver;

  constructor() {}

  $onInit() {}

  saveWaiver(waiver: IWaiver) {
    if (waiver && waiver.id) {
      this.waiverService
        .updateWaiver(this.resource, waiver)
        .then((updatedWaiver) => {
          return updatedWaiver;
        });
    } else {
      this.waiverService.addWaiver(this.resource, waiver).then((newWaiver) => {
        this.waivers.push(newWaiver);
      });
    }
    this.clearWaiver();
  }

  clearWaiver() {
    this.waiverForm = {} as IWaiver;
  }

  removeWaiver(waiver: IWaiver) {
    this.waiverService.removeWaiver(this.resource, waiver).then((removed) => {
      const idx = this.waivers.indexOf(waiver);
      if (idx >= 0) this.waivers.splice(idx, 1);
    });
    this.clearWaiver();
  }

  $onChanges() {
    if (this.resource) {
      this.waiverService
        .getWaivers(this.resource)
        .then((waivers) => (this.waivers = waivers));
    }
  }
}
