import {Injectable } from 'angular-ts-decorators';
import {IFilter} from '../models/IFilter';
import { IService } from 'restangular';
import {IEventAvailability} from '../models/IEventAvailability';

@Injectable('AvailabilityService')
export class AvailabilityService {
    static $inject = ['Restangular'];
    constructor(private Restangular: IService) {
    }

    getByEvent(eventId: string): ng.IPromise<IEventAvailability[]> {
        let filter: IFilter = {
            where: {
                eventId
            }
        } as IFilter;
        return this.Restangular.all('rsvps').getList({filter});
    }

    getByPlayerAndEventIds(playerId: string, eventId: string): ng.IPromise<IEventAvailability[]> {
            let filter: IFilter = {
            where: {
                eventId,
                playerId
            }
        } as IFilter;
            return this.Restangular.all('rsvps').getList({filter});
    }

    add(availability: IEventAvailability): ng.IPromise<IEventAvailability> {
        return this.Restangular.all('rsvps').post(availability);
    }

    update(availability: IEventAvailability): ng.IPromise<IEventAvailability> {
        return this.Restangular.one('rsvps', availability.id).customPATCH(availability);
    }    
    
}
