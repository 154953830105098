import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";
import _ from "lodash";

let name: string = "stGrid";

@Component({
  selector: name,
  template,
})
export class GridComponent {
  static Name: string = name;
  static $inject = [];

  @Input()
  data: any[];

  @Input()
  options: object;

  @Input()
  columns: object[];

  gridOptions: any = {
    paginationPageSize: 25,
    paginationCurrentPage: 1,
    minRowsToShow: 15,
    enablePaginationControls: true,
    useExternalPagination: true,
    enableColumnResizing: true,
    enableSorting: true,
    useExternalSorting: true,
    enableEditing: true,
    enableColumnMenu: true,
    enableFiltering: true,
    useExternalFiltering: true,
  };
  /**
   *
   */
  constructor() {}

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    if (changesObj.data && this.data) {
      this.gridOptions.data = this.data;
    }

    if (changesObj.columns && this.columns) {
      this.gridOptions.columnDefs = this.columns;
    }
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    this.gridOptions = _.defaults(this.options, this.gridOptions);
  }
}
