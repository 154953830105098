import { UIComponent } from "../../../core/UIComponent";
import { Component } from "angular-ts-decorators";
import template from "./home.template.html";
import { LeagueWaiverService } from "../league.waiver.service";

const name: string = "leagueHome";

@Component({
  selector: name,
  template,
})
export class LeagueHomeComponent extends UIComponent {
  static Name: string = name;
  static $inject: string[] = ["LeagueWaiverService"];

  constructor(public leagueWaiverService: LeagueWaiverService) {
    super();
  }
}
