import template from "./template.html";
import { ITeam } from "../../../models/ITeam";
import { ITeamSettings } from "../../../models/ITeamSettings";
import { TeamService } from "../../../services/TeamService";
import { Component, Input, Output } from "angular-ts-decorators";
@Component({
  selector: "stTeamSettings",
  template,
})
export class TeamSettings {
  static $inject = ["TeamService", "notify"];
  @Input()
  team: ITeam;
  /**
   *
   */
  constructor(
    private teamService: TeamService,
    private notify: angular.cgNotify.INotifyService
  ) {}

  async saveSettings(settings: ITeamSettings) {
    let me = this;
    try {
      let update = await me.teamService.updateSettings(me.team, settings);
      me.notify({ message: "settings updated", classes: "message-success" });
    } catch (ex) {
      me.notify({
        message: "there was an error updating the settings",
        classes: "message-danger",
      });
    }
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
