import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";

@Component({
  selector: "stSelect",
  template,
})
export class Select implements IComponentController {
  @Input("@") label: string;
  @Input("@") display: string;

  @Output() labelMethod: ({ value }) => {};
  @Input("=") value: any;
  @Input("@") required: string;
  @Input("@") listType: string;
  @Output() onChange: ({ value }) => {};
  @Input("@") defaultOption: string;
  @Input() options: any;
  @Input("@") optionsQuery: string;
  @Input() includeWrapper: boolean = true;

  constructor() { }

  $onInit() { }

  isRequired(): boolean {
    return this.required === "true";
  }

  onSelectChange() {
    this.onChange({ value: this.value });
  }

  name(obj) {
    let label =
      this.labelMethod &&
      typeof this.labelMethod === "function" &&
      this.labelMethod({ value: obj });
    if (label) {
      return label;
    }
    if (this.display) {
      return obj[this.display];
    }
    if (obj.name) {
      return obj.name;
    }
    return "you need a label function to support this component";
  }
}
