import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import { INotification } from "../../../models/INotification";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { TeamService } from "../../../services/TeamService";
import template from "./template.html";

@Component({
  selector: "stGameNotifications",
  template,
})
export class GameNotifications implements IComponentController {
  static $inject: string[] = ["TeamService"];

  @Input()
  team: ITeam;
  @Input()
  user: IUser;
  @Input()
  roles: IRoleInfo;
  @Input()
  mode: string;

  /**
   * Creates an instance of NotificationComponent.
   *
   * @memberOf NotificationComponent
   */
  constructor(private teamService: TeamService) {}

  $onInit() {}
}
