import { PermissionsService } from '../services/PermissionsService';
import { IRoleInfo } from '../models/IRoleInfo';
import { IFile } from '../models/IFile';
import { IUser } from '../models/IUser';
import { UserService } from '../services/UserService';
import { ITeam } from '../models/ITeam';
import { ISetting } from '../models/ISetting';
import { TeamService } from '../services/TeamService';
import { IFacility } from '../models/IFacility';
import { ILeagueWindowService } from '../models/ILeagueWindowService';
import { ICarousel } from '../models/ICarousel';
import { IPosition } from '../models/IPosition';
import { SettingsService } from '../services/SettingsService';

/**
 * 
 * 
 * @export
 * @class TeamSiteController
 */
export class TeamSiteController {
    static $inject: string[] = ['UserService', 'TeamService', 'PermissionsService', 'SettingsService', 'team'];
    userInfo: IUser;
    teamInfo: ITeam;
    roleInfo: IRoleInfo;
    notFollowing: boolean = true;
    positionTypes: any[] = [
        {
            id: 1,
            label: 'coach'
        },
        {
            id: 2,
            label: 'team administrator'
        },
        {
            id: 3,
            label: 'scorekeeper'
        }
    ];
    mode: string;
    selectedFacility: IFacility;
    newFacility: IFacility;

    /**
     * Creates an instance of TeamSiteController.
     * 
     * @param {UserService} userService
     * @param {TeamService} teamService
     * @param {ILeagueWindowService} $window
     * 
     * @memberOf TeamSiteController
     */
    constructor(private userService: UserService, private teamService: TeamService, private permissionsService: PermissionsService, private settingsService: SettingsService, private team: ITeam) {
        this.refresh();
    }

    /**
     * 
     * 
     * 
     * @memberOf TeamSiteController
     */
    private refresh() {
        this.teamInfo = this.team;
        this.checkFollowing()
    }

    /**
     * 
     * 
     * @param {IUser} user
     * 
     * @memberOf TeamSiteController
     */
    loggedIn(user: IUser) {
        let me = this;
        me.userInfo = user;
        me.teamService.getTeamRoles({ id: this.team?.id } as ITeam)
            .then((roleInfo: IRoleInfo) => {
                me.roleInfo = roleInfo;
            });
        me.checkFollowing();
    }

    /**
     * 
     * 
     * @param {IUser} user
     * 
     * @memberOf TeamSiteController
     */
    logout(user: IUser) {
        this.userService.logout()
            .then(() => {
                this.mode = '';
            });
    }

    /**
     * Makes the decision on whether the current user can edit the page contents
     * 
     * @returns
     * 
     * @memberOf TeamSiteController
     */
    canEdit() {
        let me = this;
        return me.permissionsService.isManager(me.roleInfo, me.teamInfo, me.userInfo);
    }

    canSeeMembers() {
        let me = this;
        return me.isTeamMember();
    }

    isTeamMember() {
        return this.permissionsService.isTeamMember(this.roleInfo, this.teamInfo, this.userInfo);
    }

    /**
     * 
     * 
     * @param {ITeam} team
     * @param {IFile} image
     * 
     * @memberOf TeamSiteController
     */
    onLogoChange(team: ITeam, image: IFile) {
        let me = this;
        me.teamService.addPhoto(team, image);
    }

    checkFollowing() {
        let me = this;
        if (!me.userInfo || !me.teamInfo) return;
        me.teamService.isFollowing(me.teamInfo, me.userInfo)
            .then(() => {
                me.notFollowing = false;
            })
            .catch(() => {
                me.notFollowing = true;
            });
    }

    follow() {
        let me = this;
        me.teamService.followTeam(me.teamInfo, me.userInfo)
            .then(() => {
                me.notFollowing = false;
            });
    }

    unFollow() {
        let me = this;
        me.teamService.unfollowTeam(me.teamInfo, me.userInfo)
            .then(() => {
                me.notFollowing = true;
            });
    }
}
