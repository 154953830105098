import { Component, Input, Output } from "angular-ts-decorators";
import { UIComponent } from "../../core/UIComponent";
import { INews } from "../../models/INews";
import { INewsService } from "../../services/INewsService";
import { IRestResource } from "../../models/IRestResource";
import { NewsModalController } from "./News.modal.controller";
import template from "./News.template.html";

@Component({
  selector: "stNews",
  template,
})
export class News extends UIComponent {
  static $inject: string[] = ["$uibModal"];

  @Input() restResource: IRestResource;
  @Input() news: INews[];
  @Input() mode: string;
  @Input() newsService;

  /**
   * Creates an instance of News.
   *
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf News
   */
  constructor(private $uibModal: angular.ui.bootstrap.IModalService) {
    super();
  }

  /**
   * helper method to open the settings modal for this component
   *
   * @private
   * @param {*} resolver
   * @returns {ng.IPromise<INews>}
   *
   * @memberOf News
   */
  private openModal(resolver: any): ng.IPromise<INews> {
    return this.modal<INews>(
      this.$uibModal,
      require("./News.modal.template.html").default,
      NewsModalController,
      resolver
    );
  }

  /**
   * opens the add dialog and then saves the new news items
   *
   *
   * @memberOf News
   */
  add() {
    let me = this;
    me.openModal({
      item() {
        return {};
      },
    }).then((result: INews) => {
      me.newsService.addNews(me.restResource, result).then((news: INews) => {
        me.news.push(news);
      });
    });
  }

  /**
   * edits the current news item
   *
   * @param {INews} news
   *
   * @memberOf News
   */
  edit(news: INews) {
    let me = this;
    me.openModal({
      item() {
        return news;
      },
    }).then((result: INews) => {
      me.newsService.updateNews(me.restResource, result);
    });
  }

  /**
   *
   *
   * @param {INews} news
   * @returns
   *
   * @memberOf News
   */
  remove(news: INews) {
    if (!confirm("are you sure you want to remove this news story?"))
      return false;
    let me = this;
    me.newsService.removeNews(me.restResource, news);
    let idx = me.news.indexOf(news);
    if (idx >= 0) me.news.splice(idx, 1);
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    let me = this;
    if (!me.restResource || !me.restResource.id) return;
    me.newsService
      .getNews(me.restResource)
      .then((news: INews[]) => {
        me.news = news;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
