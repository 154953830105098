import {IFile} from '../../../models/IFile';
import {ISponsor} from '../../../models/ISponsor';

export class SponsorModal {
    static $inject: string[] = ['item'];
    item: ISponsor;
    /**
     * Creates an instance of StaffManagerModal.
     * 
     * @param {IPosition} item
     * 
     * @memberOf StaffManagerModal
     */
    constructor(item: ISponsor) {
        this.item = item;
    }    

    imageChange(image: IFile) {
        this.item.image = image;
    }
}
