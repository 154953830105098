import { IPage } from "../../../models/IPage";
import { IRestResource } from "../../../models/IRestResource";
import { IPageService } from "../../../services/IPagesService";
import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

let name: string = "stPagesEditor";

interface ItemResolver {
  item: IPage;
}

@Component({
  selector: name,
  template,
})
export class PagesEditor {
  static Name: string = name;
  static $inject = [];

  @Output()
  close: ({ $value }) => {};

  @Output()
  dismiss: ({ $value }) => {};

  @Input()
  resolve: ItemResolver;

  /**
   *
   */
  constructor(private item: IPage) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    this.item = this.resolve.item;
  }

  ok() {
    this.close({ $value: this.item });
  }

  cancel() {
    this.dismiss({ $value: "cancel" });
  }
}
