import {ILeagueScope} from '../models/ILeagueScope';
import {IAgeGroup} from '../models/IAgeGroup';
import {ITeamLevel} from '../models/ITeamLevel';
import {IDivision} from '../models/IDivision';
import {IFilter} from '../models/IFilter';
import {GameCreatorController} from './GameCreatorController';
import {IGame} from '../models/IGame';
import {GameEditorController} from './GameEditorController';
import { IService, IElement } from 'restangular';
import _ from 'lodash';

export class GamesReportController {
    static $inject = ['$scope', 'Restangular', '$uibModal'];
    dataService: IElement;

    constructor(private $scope: ILeagueScope, private Restangular: IService, private $uibModal: angular.ui.bootstrap.IModalService) {
    }

    currentAgeGroup: string;
    ageGroup: IAgeGroup;
    selectedLevel: ITeamLevel;
    selectedDivision: IDivision;
    showStringDates: boolean = false;

    refresh(ageGroupId: string) {
        this.currentAgeGroup = ageGroupId;
        this.selectedLevel = null;
        this.selectedDivision = null;
        let me = this;
        let filter: IFilter = {
            include:
            [
                {
                    relation: 'teamlevels',
                    scope: {
                        include: [
                            {
                                relation: 'divisions',
                                scope: {
                                    include: [
                                        {
                                            relation: 'teamregistrations',
                                            scope: {
                                                include: [
                                                    'team',
                                                    { awayGames: ['date', { homeTeam: 'team' }, { awayTeam: 'team' }] },
                                                    { homeGames: ['date', { homeTeam: 'team' }, { awayTeam: 'team' }] }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }

            ]
        };

        this.dataService = this.Restangular.one('agegroups', ageGroupId);
        this.dataService.get<IAgeGroup>({ filter })
            .then(function(ageGroup) {
                _.each(ageGroup.teamlevels, function(tl) {
                    let allTeams = [];
                    _.each(tl.divisions, function(d) {
                        _.each(d.teamregistrations, function(tr) {
                            allTeams.push(tr);
                        });
                    });
                    tl.allTeams = allTeams;
                });
                me.ageGroup = ageGroup;
            });
    }

}
