import { Component, Input, Output } from "angular-ts-decorators";
import template from "./mydashboard.template.html";
import { UserService } from "../../services/UserService";
import { IUser } from "../../models/IUser";
import { ITeam } from "../../models/ITeam";
import { ISeason } from "../../models/ISeason";
import _ from "lodash";

@Component({
  selector: "stMyDashboard",
  template,
})
export class MyDashboard {
  static $inject = ["UserService"];

  @Input() user: IUser;
  @Input() season: ISeason;
  teams: ITeam[];
  /**
   *
   */
  constructor(private userService: UserService) {}

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    let me = this;
    if (me.user && me.season) {
      me.userService.getTeams(me.user).then((user) => {
        let allTeams = [];
        let positions = _.filter(user.positions, (position) => {
          let hasRegistrations =
            position &&
            position.team &&
            position.team.registrations &&
            position.team.registrations.length;
          if (hasRegistrations) {
            position.team.currentRegistration = _.find(
              position.team.registrations,
              (registration) => {
                return registration.seasonId === me.season.id;
              }
            );
          }
          return hasRegistrations && position.team.currentRegistration;
        });
        _.each(positions, (position) => {
          allTeams.push(position.team);
        });

        let teams = _.filter(user.teams, (team) => {
          let hasRegistrations =
            team && team.registrations && team.registrations.length;
          if (hasRegistrations) {
            team.currentRegistration = _.find(
              team.registrations,
              (registration) => {
                return registration.seasonId === me.season.id;
              }
            );
          }
          return hasRegistrations && team.currentRegistration;
        });
        _.each(teams, (team) => {
          allTeams.push(team);
        });

        let distinctTeams = _.uniqBy(allTeams, (team) => {
          return team.id;
        });

        me.teams = _.orderBy(distinctTeams, ["name"]);
      });
    } else {
      me.teams = [];
    }
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
