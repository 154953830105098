import { StateParams, StateService } from "@uirouter/core";
import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { round } from "lodash";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import { IRoundRobin } from "../../../models/IRoundRobin";
import { ITeam } from "../../../models/ITeam";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { IUser } from "../../../models/IUser";
import { TeamRegistrationService } from "../../../services/TeamRegistrationService";
import template from "./template.html";

@Component({
  selector: "stRoundRobin",
  template,
})
export class RoundRobin implements IComponentController {
  static $inject = ["TeamRegistrationService", "$state", "$stateParams"];

  @Input()
  registration: ITeamRegistration;

  @Input()
  team: ITeam;

  @Input()
  settings: ILeagueSetting;

  @Input()
  user: IUser;

  public roundRobins: IRoundRobin[];
  public roundRobin: IRoundRobin;

  constructor(
    private teamRegistrationService: TeamRegistrationService,
    private $state: StateService,
    private $stateParams: StateParams
  ) {}

  async $onInit() {
    this.roundRobins = await this.teamRegistrationService.getRoundRobins(
      this.registration
    );
    if (this.roundRobins && this.roundRobins.length <= 0) {
      this.$state.go(".create");
    }
    if (this.$stateParams["id"]) {
      const id = this.$stateParams["id"];
      this.roundRobins?.forEach((roundRobin) => {
        if (roundRobin.id === id) {
          this.roundRobin = roundRobin;
        }
      });
    }
  }

  label(roundRobin: IRoundRobin) {
    return roundRobin?.name;
  }

  change(roundRobin: IRoundRobin) {
    if (roundRobin) {
      this.roundRobin = roundRobin;
      this.$state.go(".view", { id: roundRobin.id });
    }
  }
}
