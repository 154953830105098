import { LogoutComponent } from "./logout/component";
import { AvailabilityComponent } from "./team/availability/component";
import { Bracket } from "./tournament/bracket/component";
import { NgModule } from "angular-ts-decorators";
import { CarouselComponent } from "./carousel/Carousel.component";
import { CarouselManagerComponent } from "./carousel/CarouselManager.component";
import { DateComponent } from "./datetime/Date.component";
import { DateTimeComponent } from "./datetime/DateTime.component";
import { Login } from "./login/component";
import { MyDashboard } from "./mydashboard/mydashboard.component";
import { News } from "./news/News.component";
import { SiteNotifications } from "./notifications/component";
import { PagesModule } from "./pages/pages.module";
import { Standings } from "./standings/component";
import { TeamCalendarGame } from "./team/calendar/game/component";
import { TeamCalendar } from "./team/calendar/TeamCalendar.component";
import { TeamCalendarDetail } from "./team/calendar/TeamCalendar.detail.component";
import { TeamEditor } from "./team/editor/TeamEditor.component";
import { TeamRegistrationEditor } from "./team/editor/TeamRegistrationEditor.component";
import { Facilities } from "./team/facilities/Facilities.component";
import { GameDisplay } from "./team/game/game.display.component";
import { GameNotifications } from "./team/gamenotifications/component";
import { MembersManager } from "./team/members/Members.component";
import { NotificationComponent } from "./team/notifications/component";
import { Registration } from "./team/registration/registration.component";
import { TeamSettings } from "./team/settings/component";
import { SponsorComponent } from "./team/sponsor/Sponsor.component";
import { StaffManager } from "./team/staff/StaffManager.component";
import { TickerComponent } from "./ticker/ticker.component";
import { FlightsSingleElimination } from "./tournament/flight/component";
import { TournamentSchedule } from "./tournament/schedule/component";
import { SingleElimination } from "./tournament/singleelimination/component";
import { CheckBoxComponent } from "./ui/checkbox/checkbox";
import { FieldDisplay } from "./ui/fielddisplay/component";
import { HelpBlock } from "./ui/helpblock/component";
import { InlineImageUpload } from "./ui/imageupload/component";
import { PageTitle } from "./ui/pagetitle/component";
import { PanelTemplate } from "./ui/panel/component";
import { RichTextInput } from "./ui/richtext/component";
import { Select } from "./ui/select/component";
import { StringList } from "./ui/stringlist/component";
import { TextArea } from "./ui/textarea/textarea";
import { TextInput } from "./ui/textinput/textinput";
import { UserProfile } from "./user/profile/UserProfile.component";
import { VideosComponent } from "./video/Videos.component";
import { WellTemplate } from "./ui/well/component";
import { TeamScheduling } from "./team/scheduling/component";
import { TeamSchedulingManagement } from "./team/schedulingmanagement/component";
import { PaymentResponse } from "./team/paymentresponse/paymentresponse";
import { AgeGroupSelector } from "./agegroupselector/component";
import { TeamLevelSelector } from "./teamlevelselector/component";
import { DivisionSelector } from "./divisionselector/component";
import { RoundRobin } from "./team/roundrobin/component";
import { CreateRoundRobin } from "./team/roundrobin/create";
import { ViewRoundRobin } from "./team/roundrobin/view";
import { RoundRobinInvite } from "./team/roundrobin/invite";
import { RoundRobinInvitations } from "./team/roundrobin/invitations";
import { RoundRobinGenerate } from "./team/roundrobin/generate";
import { HostRoundRobin } from "./team/roundrobin/host";
import { ScheduleRoundRobin } from "./team/roundrobin/schedule";
import { Suggestions } from "./suggestions";

export const ComponentsModuleName: string = "league-manager-components";

@NgModule({
  id: ComponentsModuleName,
  declarations: [
    AvailabilityComponent,
    Bracket,
    CarouselManagerComponent,
    FlightsSingleElimination,
    InlineImageUpload,
    Login,
    RichTextInput,
    Select,
    SingleElimination,
    Standings,
    StringList,
    TournamentSchedule,
    CarouselComponent,
    DateTimeComponent,
    DateComponent,
    MyDashboard,
    News,
    TeamEditor,
    TeamRegistrationEditor,
    Facilities,
    GameDisplay,
    GameNotifications,
    MembersManager,
    NotificationComponent,
    Registration,
    SponsorComponent,
    StaffManager,
    TeamCalendar,
    TeamCalendarDetail,
    TeamCalendarGame,
    TickerComponent,
    CheckBoxComponent,
    TextArea,
    TextInput,
    UserProfile,
    VideosComponent,
    WellTemplate,
    PanelTemplate,
    FieldDisplay,
    HelpBlock,
    TeamSettings,
    SiteNotifications,
    PageTitle,
    LogoutComponent,
    TeamScheduling,
    TeamSchedulingManagement,
    PaymentResponse,
    AgeGroupSelector,
    TeamLevelSelector,
    DivisionSelector,
    RoundRobin,
    CreateRoundRobin,
    ViewRoundRobin,
    RoundRobinInvite,
    RoundRobinInvitations,
    RoundRobinGenerate,
    HostRoundRobin,
    ScheduleRoundRobin,
    Suggestions,
  ],
  providers: [],
  imports: [PagesModule],
})
export class Components {}
