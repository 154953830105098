import { IHttpService } from "angular";
import { Injectable } from "angular-ts-decorators";

export enum VanityUrlType {
    League = 1,
    Team = 2,
    Organization = 3
}

export interface VanityUrlResolution {
    id: string;
    urlKey: string;
    type: VanityUrlType
}

@Injectable("CommonVanityUrlService")
export class CommonVanityUrlService {
    static $inject: string[] = ['$http'];
    constructor(private $http: IHttpService) {

    }

    resolve() {
        return this.$http.get<VanityUrlResolution>(`/api/vanityurls/resolve`).then((res) => res.data);
    }
}