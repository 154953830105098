import { IAgeGroup } from "../models/IAgeGroup";
import { ILeagueScope } from "../models/ILeagueScope";
import { IService, ICollection } from "restangular";
import { StateService } from "@uirouter/angularjs";

export class AgeGroupController {
  static $inject = ["$state", "$scope", "Restangular"];
  dataService: ICollection;
  list: IAgeGroup[];
  newItem: IAgeGroup;
  selectedItem: IAgeGroup;
  constructor(
    private $state: StateService,
    private $scope: ILeagueScope,
    private Restangular: IService
  ) {
    this.list = [];
    this.newItem = {} as IAgeGroup;
    let me = this;
    me.$scope.$watch(
      function () {
        return me.$scope.league.currentSeason;
      },
      function (newValue, oldValue) {
        if (newValue) {
          me.refresh();
        }
      },
      true
    );
  }

  refresh() {
    let me = this;
    this.dataService = this.Restangular.one(
      "seasons",
      this.$scope.league.currentSeason.id
    ).all("agegroups");
    this.dataService.getList<IAgeGroup>().then(function (response) {
      if (response) {
        me.list = response;
      } else {
        me.list = [];
      }
    });
  }

  create() {
    let me = this;
    this.dataService.post(this.newItem).then(function (response) {
      me.list.push(response);
      me.newItem = {} as IAgeGroup;
      me.$state.go("league.ageGroups");
    });
  }

  edit(model: IAgeGroup) {
    this.selectedItem = model;
    this.$state.go("league.ageGroups.edit", { ageGroupId: model.id });
  }

  delete(model: IAgeGroup) {
    if (
      !confirm(
        "are you sure you want to delete this age group? this will remove all divisions and team assignments for it."
      )
    )
      return;
    let me = this;
    model.remove().then(function () {
      let idx = me.list.indexOf(model);
      me.list.splice(idx, 1);
    });
  }

  editScheduling(ageGroup: IAgeGroup) {
    let me = this;
    me.Restangular.one("agegroups", ageGroup.id)
      .customPATCH({ schedulingOpen: !ageGroup.schedulingOpen })
      .then(function (response) {
        ageGroup.schedulingOpen = !ageGroup.schedulingOpen;
      });
  }

  editOpenScheduling(ageGroup: IAgeGroup) {
    let me = this;
    me.Restangular.one("agegroups", ageGroup.id)
      .customPATCH({ openSchedulingEnabled: !ageGroup.openSchedulingEnabled })
      .then(function (response) {
        ageGroup.openSchedulingEnabled = !ageGroup.openSchedulingEnabled;
      });
  }

  editInviteScheduling(ageGroup: IAgeGroup) {
    let me = this;
    me.Restangular.one("agegroups", ageGroup.id)
      .customPATCH({ inviteSchedulingEnabled: !ageGroup.inviteSchedulingEnabled })
      .then(function (response) {
        ageGroup.inviteSchedulingEnabled = !ageGroup.inviteSchedulingEnabled;
      });
  }

  editRoster(ageGroup: IAgeGroup) {
    let me = this;
    me.Restangular.one("agegroups", ageGroup.id)
      .customPATCH({ lockRoster: !ageGroup.lockRoster })
      .then(function (response) {
        ageGroup.lockRoster = !ageGroup.lockRoster;
      });
  }

  save() {
    let me = this;
    this.selectedItem.save().then(function () {
      me.$state.go("league.ageGroups");
    });
  }
}
