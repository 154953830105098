import { Component, Input, Output } from "angular-ts-decorators";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { PermissionsService } from "../../../services/PermissionsService";
import { TeamService } from "../../../services/TeamService";
import { UIComponent } from "../../../core/UIComponent";
import template from "./TeamEditor.template.html";

@Component({
  selector: "stTeamEditor",
  template,
})
export class TeamEditor extends UIComponent {
  static $inject: string[] = [
    "TeamService",
    "PermissionsService",
    "$uibModal",
    "notify",
  ];
  @Input() team: ITeam;
  @Input() user: IUser;
  @Input() roles: IRoleInfo;
  @Input() mode: string;
  @Input("@") hideColor: string;

  /**
   * Creates an instance of StaffManager.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf StaffManager
   */
  constructor(
    private teamService: TeamService,
    private permissionsService: PermissionsService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private notify: angular.cgNotify.INotifyService
  ) {
    super();
  }

  update(team: ITeam) {
    let me = this;
    me.teamService
      .update({
        id: team.id,
        name: team.name,
        urlKey: team.urlKey,
        color: team.color,
        description: team.description,
      } as ITeam)
      .then(() => {
        me.notify({ message: "settings updated", classes: "message-success" });
      });
  }

  $onChanges(changesObj) {
    let me = this;
  }
}
