import {IPosition} from '../../../models/IPosition';

export class StaffManagerModal {
    static $inject: string[] = ['item'];
    item: IPosition;
    positionTypes: string[] = [
        'coach',
        'team administrator',
        'scorekeeper'
        ];
    /**
     * Creates an instance of StaffManagerModal.
     * 
     * @param {IPosition} item
     * 
     * @memberOf StaffManagerModal
     */
    constructor(item: IPosition) {
        this.item = item;
    }    

    typeLabel(value: any) {
        return value && value.label;
    }
    
}
