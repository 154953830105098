import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import template from "./template.html";

@Component({
  selector: "stCheckbox",
  template,
})
export class CheckBoxComponent implements IComponentController {
  static $inject: string[] = [];
  @Input("@")
  label: string;
  @Input("=")
  value: boolean;
  @Input("@")
  required: string;
  @Output()
  onChange: ({ value }) => {};
  /**
   * Creates an instance of CheckBoxComponent.
   *
   * @memberOf CheckBoxComponent
   */
  constructor() {}

  $onInit() {}
}
