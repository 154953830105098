import { IRoleInfo } from "../../../models/IRoleInfo";
import { IUser } from "../../../models/IUser";
import { PermissionsService } from "../../../services/PermissionsService";
import { StaffManagerModal } from "./StaffManager.Modal.controller";
import * as angular from "angular";
import { IPosition } from "../../../models/IPosition";
import { ITeam } from "../../../models/ITeam";
import { TeamService } from "../../../services/TeamService";
import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./StaffManager.template.html";

@Component({
  selector: "stStaffManager",
  template,
})
export class StaffManager extends UIComponent {
  static $inject: string[] = ["TeamService", "PermissionsService", "$uibModal"];
  @Input()
  team: ITeam;
  @Input()
  user: IUser;
  @Input()
  roles: IRoleInfo;
  @Input()
  mode: string;
  positions: IPosition[];

  /**
   * Creates an instance of StaffManager.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf StaffManager
   */
  constructor(
    private teamService: TeamService,
    private permissionsService: PermissionsService,
    private $uibModal: angular.ui.bootstrap.IModalService
  ) {
    super();
  }

  private openModal(resolver: any): ng.IPromise<IPosition> {
    return this.modal<IPosition>(
      this.$uibModal,
      require("./StaffManager.modal.template.html").default,
      StaffManagerModal,
      resolver
    );
  }

  add() {
    let me = this;
    me.openModal({
      item() {
        return {};
      },
    }).then((result: IPosition) => {
      me.teamService.addPosition(me.team, result).then((news: IPosition) => {
        me.positions.push(news);
      });
    });
  }

  remove(position: IPosition) {
    if (
      !confirm("are you sure you would like to remove this team staff member?")
    )
      return false;
    let me = this;
    me.teamService.removePosition(me.team, position).then(() => {
      let idx = me.positions.indexOf(position);
      if (idx >= 0) me.positions.splice(idx, 1);
    });
  }

  $onChanges(changesObj) {
    let me = this;
    let resolveStaff = (positions: IPosition[]) => {
      me.positions = positions;
    };
    if (
      me.team &&
      me.user &&
      me.roles &&
      me.permissionsService.isManager(me.roles, me.team, me.user)
    ) {
      me.teamService.getStaffMembers(me.team).then(resolveStaff);
    } else if (me.team) {
      me.teamService.getPositions(me.team).then(resolveStaff);
    }
  }
}
