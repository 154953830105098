import { UIComponent } from "../../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./registerforseason.html";
import { ISeason } from "../../../../models/ISeason";
import { ICollection, IService } from "restangular";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { StateService } from "@uirouter/core";
import { IQService } from "angular";
import { ITeam } from "../../../../models/ITeam";
import { IUser } from "../../../../models/IUser";
import { IAddress } from "../../../../models/IAddress";
import { IAgeGroup } from "../../../../models/IAgeGroup";
import { IFilter } from "../../../../models/IFilter";
import { IRosterEntry } from "../../../../models/IRosterEntry";
import _ from "lodash";
import { ILeague } from "../../../../models/ILeague";

const name = "stRegisterForSeason";

@Component({
  selector: name,
  template,
})
export class RegisterForSeason extends UIComponent {
  static $inject = ["$state", "Restangular", "$q"];
  registrationService: ICollection;
  newRegistration: ITeamRegistration;
  submitted: boolean;
  static Name: string = name;

  @Input()
  public season: ISeason;

  @Input()
  public agegroups: IAgeGroup[];

  @Input()
  public resource: ILeague;

  @Input()
  public user: IUser;

  public waiverSigned: boolean;

  constructor(
    private $state: StateService,
    private Restangular: IService,
    private $q: IQService
  ) {
    super();
    this.registrationService = Restangular.all("teamregistrations");
  }

  init() {
    this.loadOtherTeams();
    this.newRegistration = {} as ITeamRegistration;
    this.newRegistration.isMe = false;
    this.newRegistration.league = this.resource;
    this.newRegistration.leagueId = this.resource.id;
    if (this.season) {
      this.newRegistration.season = this.season;
      this.newRegistration.seasonId = this.season.id;
    }

    // this.newRegistration.ageGroup = <IAgeGroup>{};
    this.newRegistration.team = {} as ITeam;
    this.newRegistration.team.manager = {} as IUser;
    this.newRegistration.team.manager.location = {} as IAddress;
    this.newRegistration.roster = [];
    this.newRegistration.team.leagueId = this.resource.id;
  }

  populateMe() {
    if (this.newRegistration.isMe) {
      this.newRegistration.team.manager = this.user;
    } else {
      this.newRegistration.team.manager = {} as IUser;
    }
  }

  loadTeamLevels(ageGroup: IAgeGroup) {
    let svc = this.Restangular.one("agegroups", ageGroup.id).all("teamlevels");
    svc.getList().then(function (teamlevels) {});
  }

  teams: ITeamRegistration[];
  loadOtherTeams() {
    if (this.user && this.user.id) {
      let filter: IFilter = {
        include: [
          {
            relation: "positions",
            scope: {
              fields: ["id", "userId", "teamId", "positionType"],
              include: {
                relation: "team",
                scope: {
                  fields: ["id", "name", "urlKey", "leagueId"],
                  include: {
                    relation: "registrations",
                    scope: {
                      include: [
                        "ageGroup",
                        "teamLevel",
                        "league",
                        "season",
                        "roster",
                      ],
                    },
                  },
                },
              },
            },
          },
          {
            relation: "teams",
            scope: {
              fields: ["id", "name", "urlKey", "leagueId"],
              include: {
                relation: "registrations",
                scope: {
                  include: [
                    "ageGroup",
                    "teamLevel",
                    "league",
                    "season",
                    "roster",
                  ],
                },
              },
            },
          },
        ],
      };
      let me = this;
      this.Restangular.one("users", this.user.id)
        .get<IUser>({ filter })
        .then(function (user) {
          me.teams = [];
          _.each(user.teams, function (team) {
            let idx = _.findIndex(team.registrations, function (r) {
              return (
                r.leagueId === me.resource.id && r.seasonId === me.season.id
              );
            });

            if (idx === -1) {
              me.teams.push({
                teamId: team.id,
                seasonId: me.season.id,
                leagueId: me.resource.id,
                team,
                migrateRoster: false,
              } as ITeamRegistration);
            }
          });

          _.each(user.positions, function (position) {
            if (position.positionType === "team administrator") {
              let idx = _.findIndex(
                position.team && position.team.registrations,
                function (r) {
                  return (
                    r.leagueId === me.resource.id && r.seasonId === me.season.id
                  );
                }
              );
              if (idx === -1 && position.team) {
                me.teams.push({
                  teamId: position.team.id,
                  seasonId: me.season.id,
                  leagueId: me.resource.id,
                  team: position.team,
                  migrateRoster: false,
                } as ITeamRegistration);
              }
            }
          });
        });
    }
  }

  joinSeason(team: ITeamRegistration) {
    let me = this;
    team.__uistate = { submitted: true };

    let joinRequest = {
      teamId: team.teamId,
      leagueId: team.leagueId,
      seasonId: team.seasonId,
      ageGroup: {
        id: team.ageGroup.id,
      },
      teamLevel: {
        id: team.teamLevel.id,
      },
      divisionPreference: team.divisionPreference,
      waiverSigned: me.waiverSigned,
    };
    this.Restangular.all("teamregistrations")
      .customPOST(joinRequest, "join")
      .then(function (registration: ITeamRegistration) {
        let stateName = "league.team.home";
        if (me.resource.leaguesettings.showRegistrationForm) {
          stateName = "league.team.registrationform";
        }
        if (team.migrateRoster) {
          let promises = [];
          _.each(team.rosterToMigrate.roster, function (tr) {
            if (tr.migrate) {
              let promise = me.Restangular.one(
                "teamregistrations",
                registration.id
              )
                .all("roster")
                .post({
                  player: tr.player,
                  number: tr.number,
                });
              promises.push(promise);
            }
          });
          me.$q.all(promises).then(function () {
            me.$state.go(stateName, {
              urlKey: team.team.urlKey,
              seasonOverride: me.season && me.season.id,
            });
          });
        } else {
          me.$state.go(stateName, {
            urlKey: team.team.urlKey,
            seasonOverride: me.season && me.season.id,
          });
        }
      });
  }

  selectAllRosters(rosters: IRosterEntry[]) {
    _.each(rosters, function (r) {
      r.migrate = true;
    });
  }

  create() {
    let me = this;
    this.submitted = true;
    this.newRegistration.waiverSigned = this.waiverSigned;
    this.registrationService
      .customPOST(this.newRegistration, "register")
      .then(function (newRegistration) {
        let stateName = "league.team.home";
        if (me.resource.leaguesettings.showRegistrationForm) {
          stateName = "league.team.registrationform";
        }
        me.$state.go(stateName, {
          urlKey: newRegistration.team.urlKey,
          seasonOverride: me.season && me.season.id,
        });
      });
  }

  $onInit() {
    this.init();
  }

  $onChanges(changeObj) {}
}
