import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./registrationsystem.template.html";
import { ILeague } from "../../../models/ILeague";
import { LeagueService } from "../../../services/LeagueService";
import { IRegistrationProgram } from "../../../models/IRegistrationProgram";

const name = "stRegistrationSystem";

@Component({
  selector: name,
  template,
})
export class RegistrationSystem extends UIComponent {
  static $inject: string[] = [];
  static Name: string = name;

  @Input() league: ILeague;

  constructor() {
    super();
  }

  $onInit() {}

  $onChanges(changeObj) {}
}
