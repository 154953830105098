import { ITeamPlan } from "../models/ITeamPlan";
import { applyMixins } from "../lib/Mixins";
import { GameDetailService, IGameDetailService } from "./IGameDetailService";
import { ICarousel } from "../models/ICarousel";
import { ICarouselService } from "./ICarouselService";
import { ICollection, IElement, IService } from "restangular";
import { IEvent } from "../models/IEvent";
import { IFacility } from "../models/IFacility";
import { IFile } from "../models/IFile";
import { IFilter } from "../models/IFilter";
import { INews } from "../models/INews";
import { INewsService } from "./INewsService";
import { Injectable } from "angular-ts-decorators";
import { INotification } from "../models/INotification";
import { IPlayer } from "../models/IPlayer";
import { IPosition } from "../models/IPosition";
import { IServiceMixin } from "./IPagesService";
import { ISponsor } from "../models/ISponsor";
import { ITeam } from "../models/ITeam";
import { ITeamMember } from "../models/ITeamMember";
import { ITeamSettings } from "../models/ITeamSettings";
import { IUser } from "../models/IUser";
import { IVideo } from "../models/IVideo";
import { IVideoService } from "./IVideoService";
import { IHttpPromiseCallbackArg, IPromise } from "angular";
import _ from "lodash";
import { ISeason } from "../models/ISeason";
import { ITeamRegistration } from "../models/ITeamRegistration";

const teams = "teams";
const homeFields = "homeFields";

/**
 *
 *
 * @export
 * @class TeamService
 */

@Injectable("TeamService")
export class TeamService
  implements
    INewsService,
    IVideoService,
    ICarouselService,
    IGameDetailService,
    GameDetailService,
    IServiceMixin {
  static $inject: string[] = ["Restangular", "$http"];
  base: string = "teams";

  /**
   * Creates an instance of TeamService.
   *
   * @param {IService} Restangular
   *
   * @memberOf TeamService
   */
  constructor(
    public Restangular: IService,
    public $http: angular.IHttpService
  ) {}

  getAll(): ng.IPromise<ITeam[]> {
    return this.Restangular.all("teams").getList();
  }

  /**
   *
   * @param {string} id
   * @returns {ng.IPromise<ITeam>}
   *
   * @memberOf TeamService
   */
  get(id: string): ng.IPromise<ITeam> {
    let filter: IFilter = {
      include: [
        "homeFields",
        { manager: ["photo"] },
        "teamPhoto",
        "teamPositions",
        "positions",
        "carousels",
        {
          registrations: ["league", "season"]
        }
      ]
    };
    return this.Restangular.one(teams, id)
      .get({ filter })
      .then((theTeam: ITeam) => theTeam);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<ITeam>}
   *
   * @memberOf TeamService
   */
  update(team: ITeam): ng.IPromise<ITeam> {
    return this.Restangular.one(teams, team.id).customPATCH(team);
  }

  findByName(name: string): ng.IPromise<ITeam[]> {
    const filter: IFilter = {
      where: {
        urlKey: name
      }
    } as IFilter;
    return this.Restangular.all(teams).getList({ filter });
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getTeamService(team: ITeam): IElement {
    return this.Restangular.one(teams, team.id);
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {IFacility} [facility]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getFacilityResource(
    team: ITeam,
    facility?: IFacility
  ): IElement | ICollection {
    if (facility) {
      return this.getTeamService(team).one(homeFields, facility.id);
    }
    return this.getTeamService(team).all(homeFields);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IFacility[]>}
   *
   * @memberOf TeamService
   */
  getFacilities(team: ITeam): ng.IPromise<IFacility[]> {
    return this.getFacilityResource(team).getList();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IFacility} facility
   * @returns {ng.IPromise<IFacility>}
   *
   * @memberOf TeamService
   */
  addFacility(team: ITeam, facility: IFacility): ng.IPromise<IFacility> {
    return (this.getFacilityResource(team) as ICollection)
      .post(facility)
      .then((addedFacility) => {
        team.homeFields.push(addedFacility);
        return addedFacility;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IFacility} facility
   * @returns {ng.IPromise<IFacility>}
   *
   * @memberOf TeamService
   */
  removeFacility(team: ITeam, facility: IFacility): ng.IPromise<IFacility> {
    let me = this;
    return (this.getFacilityResource(team, facility) as IElement)
      .remove()
      .then(() => {
        let idx = team.homeFields.indexOf(facility);
        if (idx >= 0) team.homeFields.splice(idx, 1);
        return facility;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IFacility} facility
   * @returns {ng.IPromise<IFacility>}
   *
   * @memberOf TeamService
   */
  updateFacility(team: ITeam, facility: IFacility): ng.IPromise<IFacility> {
    return this.getFacilityResource(team, facility).customPUT(facility);
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {ICarousel} [carousel]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getCarouselResource(
    team: ITeam,
    carousel?: ICarousel
  ): IElement | ICollection {
    if (carousel) {
      return this.getTeamService(team).one("carousels", carousel.id);
    }
    return this.getTeamService(team).all("carousels");
  }

  getCarousels(team: ITeam): ng.IPromise<ICarousel[]> {
    return this.getCarouselResource(team).getList();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {ICarousel} carousel
   * @returns {ng.IPromise<ICarousel>}
   *
   * @memberOf TeamService
   */
  addCarousel(team: ITeam, carousel: ICarousel): ng.IPromise<ICarousel> {
    let imageCopy = carousel.image;
    carousel.image = null;
    return (this.getCarouselResource(team) as ICollection)
      .post(carousel)
      .then((addedCarousel: ICarousel) => {
        if (!team.carousels) {
          team.carousels = [];
        }
        addedCarousel.image = imageCopy;
        return addedCarousel;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {ICarousel} carousel
   * @returns {ng.IPromise<ICarousel>}
   *
   * @memberOf TeamService
   */
  updateCarousel(team: ITeam, carousel: ICarousel): ng.IPromise<ICarousel> {
    let imageCopy = carousel.image;
    carousel.image = null;
    return this.getCarouselResource(team, carousel)
      .customPUT(carousel)
      .then((updatedCarousel) => {
        updatedCarousel.image = imageCopy;
        return updatedCarousel;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {ICarousel} carousel
   * @returns {ng.IPromise<ICarousel>}
   *
   * @memberOf TeamService
   */
  removeCarousel(team: ITeam, carousel: ICarousel): ng.IPromise<ICarousel> {
    return (this.getCarouselResource(team, carousel) as IElement).remove();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IPosition[]>}
   *
   * @memberOf TeamService
   */
  getPositions(team: ITeam): ng.IPromise<IPosition[]> {
    let svc = this.getTeamService(team).all("staff");
    return svc.getList<IPosition>();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IPosition[]>}
   *
   * @memberOf TeamService
   */
  getStaffMembers(team: ITeam): ng.IPromise<IPosition[]> {
    let svc = this.getTeamService(team).all("staffmembers");
    return svc.getList<IPosition>();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IPosition} position
   * @returns
   *
   * @memberOf TeamService
   */
  addPosition(team: ITeam, position: IPosition): ng.IPromise<IPosition> {
    let svc = this.getTeamService(team).all("addposition");
    return svc.post({
      positionType: position.positionType,
      email: position.user && position.user.email
    });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IPosition} position
   * @returns
   *
   * @memberOf TeamService
   */
  removePosition(team: ITeam, position: IPosition) {
    return this.getTeamService(team)
      .one("positions", position.id)
      .remove();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IFile} image
   * @returns
   *
   * @memberOf TeamService
   */
  addPhoto(team: ITeam, image: IFile) {
    return this.getTeamService(team)
      .customPATCH({ teamPhotoId: image.id })
      .then((updatedTeam: ITeam) => {
        return updatedTeam;
      });
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {INews} [news]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getNewsResource(team: ITeam, news?: INews): IElement | ICollection {
    if (news) {
      return this.getTeamService(team).one("news", news.id);
    }
    return this.getTeamService(team).all("news");
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {IVideo} [video]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getVideoResource(
    team: ITeam,
    video?: IVideo
  ): IElement | ICollection {
    if (video) {
      return this.getTeamService(team).one("videos", video.id);
    }
    return this.getTeamService(team).all("videos");
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<INews[]>}
   *
   * @memberOf TeamService
   */
  getNews(team: ITeam): ng.IPromise<INews[]> {
    return (this.getNewsResource(team) as ICollection).getList<INews>();
  }

  /**
   * Returns an individual news record by id
   *
   * @param {ITeam} team
   * @param {string} id
   * @returns
   *
   * @memberOf TeamService
   */
  getNewsById(team: ITeam, id: string): ng.IPromise<INews> {
    return (this.getNewsResource(team, { id } as INews) as IElement).get();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {INews} news
   * @returns {ng.IPromise<INews>}
   *
   * @memberOf TeamService
   */
  addNews(team: ITeam, news: INews): ng.IPromise<INews> {
    let imageCopy = news.banner;
    news.banner = null;
    return (this.getNewsResource(team) as ICollection)
      .post(news)
      .then((added: INews) => {
        added.banner = imageCopy;
        return added;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {INews} news
   * @returns {ng.IPromise<INews>}
   *
   * @memberOf TeamService
   */
  updateNews(team: ITeam, news: INews): ng.IPromise<INews> {
    let imageCopy = news.banner;
    news.banner = null;
    return this.getNewsResource(team, news)
      .customPUT(news)
      .then((updated: INews) => {
        updated.banner = imageCopy;
        return updated;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {INews} news
   * @returns {ng.IPromise<INews>}
   *
   * @memberOf TeamService
   */
  removeNews(team: ITeam, news: INews): ng.IPromise<INews> {
    return (this.getNewsResource(team, news) as IElement).remove();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IVideo[]>}
   *
   * @memberOf TeamService
   */
  getVideos(team: ITeam): ng.IPromise<IVideo[]> {
    return this.getVideoResource(team).getList();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IVideo} video
   * @returns {ng.IPromise<IVideo>}
   *
   * @memberOf TeamService
   */
  addVideo(team: ITeam, video: IVideo): ng.IPromise<IVideo> {
    return this.getVideoResource(team).customPOST(video);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IVideo} video
   * @returns {ng.IPromise<IVideo>}
   *
   * @memberOf TeamService
   */
  updateVideo(team: ITeam, video: IVideo): ng.IPromise<IVideo> {
    return this.getVideoResource(team, video).customPUT(video);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IVideo} video
   * @returns {ng.IPromise<IVideo>}
   *
   * @memberOf TeamService
   */
  removeVideo(team: ITeam, video: IVideo): ng.IPromise<IVideo> {
    return (this.getVideoResource(team, video) as IElement).remove();
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {IEvent} [event]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getEventsResource(
    team: ITeam,
    event?: IEvent
  ): IElement | ICollection {
    if (event) {
      return this.getTeamService(team).one("events", event.id);
    }
    return this.getTeamService(team).all("events");
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IEvent[]>}
   *
   * @memberOf TeamService
   */
  getEvents(team: ITeam): ng.IPromise<IEvent[]> {
    return this.getEventsResource(team).getList();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {string} id
   * @returns {ng.IPromise<IEvent>}
   *
   * @memberOf TeamService
   */
  getEventById(team: ITeam, id: string): ng.IPromise<IEvent> {
    return (this.getEventsResource(team, { id } as IEvent) as IElement).get();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IEvent} event
   * @returns {ng.IPromise<IEvent>}
   *
   * @memberOf TeamService
   */
  addEvent(team: ITeam, event: IEvent): ng.IPromise<IEvent> {
    return this.getEventsResource(team).customPOST(event);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IEvent} event
   * @returns {ng.IPromise<IEvent>}
   *
   * @memberOf TeamService
   */
  updateEvent(team: ITeam, event: IEvent): ng.IPromise<IEvent> {
    let updateEvent: IEvent = {
      title: event.title,
      type: event.type,
      startDate: event.startDate,
      endDate: event.endDate,
      locationName: event.locationName,
      location: event.location,
      description: event.description,
      notes: event.notes,
      arrivalBefore: event.arrivalBefore,
      sendReminder: event.sendReminder,
      reminderBefore: event.reminderBefore
    } as IEvent;
    return this.getEventsResource(team, event).customPUT(updateEvent);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IEvent} event
   * @returns {ng.IPromise<IEvent>}
   *
   * @memberOf TeamService
   */
  removeEvent(team: ITeam, event: IEvent): ng.IPromise<IEvent> {
    return (this.getEventsResource(team, event) as IElement).remove();
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {IUser} [user]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getFollowers(team: ITeam, user?: IUser): IElement {
    return this.getTeamService(team)
      .all("followers")
      .one("rel", user.id);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IUser} user
   * @returns {ng.IPromise<IEvent>}
   *
   * @memberOf TeamService
   */
  followTeam(team: ITeam, user: IUser): ng.IPromise<any> {
    return this.getFollowers(team, user).customPUT({});
  }

  /**
   *
   */
  isFollowing(team: ITeam, user: IUser): ng.IPromise<boolean> {
    return this.getFollowers(team, user).head();
  }

  /**
   *
   */
  unfollowTeam(team: ITeam, user: IUser): ng.IPromise<any> {
    return this.Restangular.one("users", user.id)
      .all("following")
      .one("rel", team.id)
      .remove();
  }

  /**
   *
   */
  private getMembersResource(
    team: ITeam,
    member?: IPlayer
  ): IElement | ICollection {
    if (member) {
      return this.getTeamService(team).one("members", member.id);
    }
    return this.getTeamService(team).all("members");
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IPlayer} member
   * @returns {ng.IPromise<IPlayer>}
   *
   * @memberOf TeamService
   */
  addMember(team: ITeam, member: IPlayer): ng.IPromise<IPlayer> {
    return this.getTeamService(team)
      .all("teammembers")
      .customPOST(member);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IPlayer[]>}
   *
   * @memberOf TeamService
   */
  getMembers(team: ITeam): ng.IPromise<IPlayer[]> {
    let filter: IFilter = {
      include: ["guardians"]
    } as IFilter;
    return this.getMembersResource(team).getList({ filter });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<IPlayer[]>}
   *
   * @memberOf TeamService
   */
  getMembersWithAvailability(
    team: ITeam,
    eventId: string
  ): ng.IPromise<IPlayer[]> {
    let filter: IFilter = {
      include: [
        "guardians",
        {
          relation: "availability",
          scope: {
            where: {
              eventId
            }
          }
        }
      ]
    } as IFilter;

    return this.getMembersResource(team)
      .getList({ filter })
      .then((players) => {
        _.each(players, (player) => {
          player.availability = _.filter(player.availability, (value) => {
            return value.eventId === eventId;
          });
        });
        return players;
      });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IPlayer} member
   * @returns {ng.IPromise<IPlayer>}
   *
   * @memberOf TeamService
   */
  updateMember(team: ITeam, member: IPlayer): ng.IPromise<IPlayer> {
    return this.getMembersResource(team, member).customPUT(member);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {IPlayer} member
   * @returns {ng.IPromise<IPlayer>}
   *
   * @memberOf TeamService
   */
  removeMember(team: ITeam, member: IPlayer): ng.IPromise<IPlayer> {
    return (this.getMembersResource(team, member) as IElement).remove();
  }

  memberExists(team: ITeam, member: IPlayer): ng.IPromise<any> {
    return this.getMembersResource(team)
      .one("rel", member.id)
      .head();
  }

  /**
   *
   *
   * @private
   * @param {ITeam} team
   * @param {ISponsor} [sponsor]
   * @returns {IElement}
   *
   * @memberOf TeamService
   */
  private getSponsorResource(
    team: ITeam,
    sponsor?: ISponsor
  ): IElement | ICollection {
    if (sponsor) {
      return this.getTeamService(team).one("sponsors", sponsor.id);
    }
    return this.getTeamService(team).all("sponsors");
  }

  /**
   *
   *
   * @param {ITeam} team
   * @returns {ng.IPromise<ISponsor[]>}
   *
   * @memberOf TeamService
   */
  getSponsors(team: ITeam): ng.IPromise<ISponsor[]> {
    let filter: IFilter = { include: "image" };
    return this.getSponsorResource(team).getList({ filter });
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {string} id
   * @returns {ng.IPromise<ISponsor>}
   *
   * @memberOf TeamService
   */
  getSponsor(team: ITeam, id: string): ng.IPromise<ISponsor> {
    return (this.getSponsorResource(team, {
      id
    } as ISponsor) as IElement).get();
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {ISponsor} sponsor
   * @returns {ng.IPromise<ISponsor>}
   *
   * @memberOf TeamService
   */
  addSponsor(team: ITeam, sponsor: ISponsor): ng.IPromise<ISponsor> {
    return this.getSponsorResource(team).customPOST(sponsor);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {ISponsor} sponsor
   * @returns {ng.IPromise<ISponsor>}
   *
   * @memberOf TeamService
   */
  updateSponsor(team: ITeam, sponsor: ISponsor): ng.IPromise<ISponsor> {
    return this.getSponsorResource(team, sponsor).customPUT(sponsor);
  }

  /**
   *
   *
   * @param {ITeam} team
   * @param {ISponsor} sponsor
   * @returns {ng.IPromise<ISponsor>}
   *
   * @memberOf TeamService
   */
  removeSponsor(team: ITeam, sponsor: ISponsor): ng.IPromise<ISponsor> {
    return (this.getSponsorResource(team, sponsor) as IElement).remove();
  }

  /**
   *
   */
  getTeamRoles(team: ITeam) {
    return this.getTeamService(team).customGET("roles");
  }

  getGames(team: ITeam) {
    let filter: IFilter = {
      include: {
        relation: "registrations",
        scope: {
          fields: ["id", "seasonId", "agegroupId", "divisionId", "teamlevelId"],
          include: [
            "ageGroup",
            "teamLevel",
            "division",
            { awayGames: ["date", { homeTeam: "team" }, { awayTeam: "team" }] },
            { homeGames: ["date", { homeTeam: "team" }, { awayTeam: "team" }] }
          ]
        }
      }
    };
    return this.getTeamService(team).get({ filter });
  }

  notify(team: ITeam, notification: INotification): ng.IPromise<any> {
    return this.$http
      .post(`/api/teams/${team.id}/notify`, notification)
      .then(this.unwrapResponse);
  }

  updateSettings(team: ITeam, settings: ITeamSettings): ng.IPromise<ITeam> {
    return this.$http
      .patch<ITeam>(`/api/teams/${team.id}`, settings)
      .then(this.unwrapResponse);
  }

  getPlans(): ng.IPromise<ITeamPlan[]> {
    return this.$http
      .get<ITeamPlan[]>("/api/teams/plans")
      .then(this.unwrapResponse);
  }

  getPlan(id: string): ng.IPromise<ITeamPlan> {
    return this.$http
      .get<ITeamPlan>(`/api/teams/plans/${id}`)
      .then(this.unwrapResponse);
  }

  loadMyTeams(user: IUser): ng.IPromise<ITeam[]> {
    if (user && user.id) {
      let filter: IFilter = {
        include: [
          {
            relation: "positions",
            scope: {
              fields: ["id", "userId", "teamId", "positionType"],
              include: {
                relation: "team",
                scope: {
                  fields: ["id", "name", "urlKey", "leagueId"],
                  include: {
                    relation: "registrations",
                    scope: {
                      include: [
                        "ageGroup",
                        "teamLevel",
                        "league",
                        "season",
                        "roster"
                      ]
                    }
                  }
                }
              }
            }
          },
          {
            relation: "teams",
            scope: {
              fields: ["id", "name", "urlKey", "leagueId"],
              include: {
                relation: "registrations",
                scope: {
                  include: [
                    "ageGroup",
                    "teamLevel",
                    "league",
                    "season",
                    "roster"
                  ]
                }
              }
            }
          }
        ]
      };
      return this.$http
        .get<IUser>(`api/users/${user.id}?filter=${JSON.stringify(filter)}`)
        .then(this.unwrapResponse)
        .then((u) => {
          let myTeams: ITeam[] = [];
          _.each(u.teams, (team) => {
            myTeams.push(team);
          });
          _.each(u.positions, (position) => {
            if (position.positionType === "team administrator") {
              myTeams.push(position.team);
            }
          });
          return myTeams;
        });
    }
  }

  getTeamByKey(season: ISeason, key: string) {
    return this.$http
      .get<ITeamRegistration>(`/api/teams/${key}/${season.id}/home`)
      .then(this.unwrapResponse);
  }

  private unwrapResponse<T>(response: IHttpPromiseCallbackArg<T>) {
    return response && response.data;
  }
}

applyMixins(TeamService, [GameDetailService]);
