import { Component, Input, Output } from "angular-ts-decorators";
import template from "./template.html";

@Component({
  selector: "stTextInput",
  template,
})
export class TextInput {
  @Input("@")
  label: string;
  @Input("=")
  value: any;
  @Input("@")
  required: string;
  @Input("@")
  type: string;
  @Input("@")
  pattern: string;
  @Input("@")
  placeholder: string;
  @Input("@")
  helpTemplate: string;
  @Input("@")
  helpTitle: string;
  @Input()
  hasError: any;
  @Output()
  onChange: ({ value }) => {};

  constructor() {}

  textChanged() {
    this.onChange({ value: this.value });
  }

  isRequired(): boolean {
    return this.required === "true";
  }

  getPattern() {
    return this.pattern || "";
  }

  $onInit() {
    let me = this;
    me.type = "text";
  }

  $onChanges(changesObj) {
    let me = this;
    if (changesObj && changesObj.type) {
      me.type = changesObj.type.currentValue;
    }
  }
}
