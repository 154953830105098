import { StateService } from "@uirouter/core";
import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import moment from "moment";
import "../../../lib/DateExtensions";
import { IAddress } from "../../../models/IAddress";
import { IEvent } from "../../../models/IEvent";
import { IEventType } from "../../../models/IEventType";
import { IGameDetail } from "../../../models/IGameDetail";
import { ILeagueWindowService } from "../../../models/ILeagueWindowService";
import { IPlayer } from "../../../models/IPlayer";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { GameService } from "../../../services/GameService";
import { IGameDetailService } from "../../../services/IGameDetailService";
import { TeamService } from "../../../services/TeamService";
import { UserService } from "../../../services/UserService";
import template from "./TeamCalendar.detail.template.html";

@Component({
  selector: "stTeamCalendarDetail",
  template,
})
export class TeamCalendarDetail implements IComponentController {
  static $inject: string[] = [
    "TeamService",
    "UserService",
    "GameService",
    "$state",
    "$window",
    "$sce",
  ];
  static Name: string = "stTeamCalendarDetail";
  @Input()
  team: ITeam;
  @Input()
  user: IUser;
  @Input()
  roles: IRoleInfo;
  event: IEvent;
  myPlayers: IPlayer[] = [];
  eventId: string;
  gameDetails: IGameDetail;

  constructor(
    private teamService: TeamService,
    private userService: UserService,
    private gameService: GameService,
    private $state: StateService,
    private $window: ILeagueWindowService,
    private $sce: angular.ISCEService
  ) {
    let me = this;
    if (me.$window && me.$window.teamId && $state.params.id) {
      let eventId = $state.params.id;
      me.eventId = eventId;
      if (eventId.indexOf("game:") > -1) {
        let gameId = eventId.split(":")[1];
        me.gameService.getGame(gameId).then((game) => {
          me.event = {
            id: game.id,
            startDate: game.date && moment(game.date.datetime).toDate(),
            endDate:
              game.date && moment(game.date.datetime).toDate().addHours(2),
            title: game.awayTeam.team.name + " @ " + game.homeTeam.team.name,
            location:
              game.date && game.date.facility && game.date.facility.location,
            type: {
              id: 5,
              type: "scheduled game",
            } as IEventType,
          } as IEvent;
        });
        (this.teamService as IGameDetailService)
          .getGameDetail({ id: me.$window.teamId }, gameId)
          .then((gameDetails) => {
            this.gameDetails = gameDetails;
          });
      } else {
        me.teamService
          .getEventById({ id: me.$window.teamId } as ITeam, $state.params.id)
          .then((event: IEvent) => {
            me.event = event;
          });
      }
    }
  }

  $onInit() {}

  private stripGameKey(id: string) {
    return id.substr("game:".length);
  }

  mapUrl(location: IAddress) {
    let addressString: string = "{street} {city} {state}, {zip}"
      .replace("{street}", location.street || "")
      .replace("{city}", location.city || "")
      .replace("{state}", location.state || "")
      .replace("{zip}", location.zip);
    let url: string = "https://www.google.com/maps/embed/v1/search?q={address}&key=AIzaSyBmLgI11kbyhMm3ggHgV9_Ged41jFWTQcc".replace(
      "{address}",
      encodeURIComponent(addressString)
    );
    return this.$sce.trustAsResourceUrl(url);
  }
}
