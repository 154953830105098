import { NgModule } from "angular-ts-decorators";
import { LineupGenerator } from "./lineupgenerator/lineupgenerator";
import { StateProvider } from "@uirouter/angularjs";
import { TeamService } from "../../services/TeamService";

@NgModule({
  id: "LineupTool",
  declarations: [LineupGenerator],
  imports: [],
  providers: []
})
export class LineupTool {
  public static config($stateProvider: StateProvider) {
    $stateProvider.state({
      name: "team.lineupgenerator",
      url: "/lineups",
      component: LineupGenerator.Name,
      resolve: {
        teamService: [
          "TeamService",
          function(teamService: TeamService) {
            return teamService;
          }
        ]
      },
      bindings: {
        restResource: "team"
      }
    });
  }
}
