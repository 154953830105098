import { Component, Input } from "angular-ts-decorators";
import { UIComponent } from "../../../core/UIComponent";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import template from "./payment.table.template.html";

const name = "stPaymentTable";

@Component({
  selector: name,
  template,
})
export class PaymentTable extends UIComponent {
  static $inject: string[] = [];
  static Name: string = name;
  predicate = "payments.created";
  @Input() payments: ITeamRegistration;
  constructor() {
    super();
  }

  sum(list: ITeamRegistration[]) {
    if (list) {
      return list.reduce((p, c) => {
        return p + c.payments?.amount_total;
      }, 0);
    }
    return 0;
  }
}
