import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import { ITeam } from "../../../models/ITeam";
import { IUser } from "../../../models/IUser";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import template from "./paymentresponse.template.html";
import { IComponentController } from "angular";
import { StateService } from "@uirouter/angularjs";

@Component({
  selector: "stPaymentResponse",
  template,
})
export class PaymentResponse implements IComponentController {
  static $inject = ["$state"];

  public static Name: string = "stPaymentResponse";

  @Input()
  team: ITeam;
  @Input()
  registration: ITeamRegistration;
  @Input()
  settings: ILeagueSetting;
  @Input()
  user: IUser;

  constructor(private $state: StateService) {}

  $onInit() {
    console.log(this.$state.params.sessionId);
  }
}
