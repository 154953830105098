import { format, Url } from 'url';
import { IFilter } from '../models/IFilter';
import { IGameDetail } from '../models/IGameDetail';
import { IHttpService } from 'angular';
import { IRestResource } from '../models/IRestResource';
import { IService } from 'restangular';
export interface IGameDetailService {
    getGameDetails?(resource: IRestResource): ng.IPromise<IGameDetail[]>;
    getGameDetail?(resource: IRestResource, id: string): ng.IPromise<IGameDetail>;
    addGameDetail?(resource: IRestResource, gamedetail: IGameDetail): ng.IPromise<IGameDetail>;
    updateGameDetail?(resource: IRestResource, gamedetail: IGameDetail): ng.IPromise<IGameDetail>;
    removeGameDetail?(resource: IRestResource, gamedetail: IGameDetail): ng.IPromise<IGameDetail>;
}

export interface IServiceMixin {
    Restangular: IService;
    $http: IHttpService;
    base: string;
}

export class GameDetailService implements IGameDetailService {

    Restangular: IService;
    base: string;
    $http: IHttpService;

    getGameDetails?(resource: IRestResource): ng.IPromise<IGameDetail[]> {
        return this.$http.get('api/' + this.base + '/' + resource.id + '/' + 'gamedetails')
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                return [];
            });
    }

    getGameDetail?(resource: IRestResource, id: string): ng.IPromise<IGameDetail> {
        let filter: IFilter = {
            where: {
                gameId: id
            }
        } as IFilter;
        return this.$http.get('api/' + this.base + '/' + resource.id + '/gamedetails?filter=' + JSON.stringify(filter))
            .then((response) => {
                return response && response.data && response.data[0];
            })
            .catch(() => {
                return { title: 'Page not found' };
            });
    }

    addGameDetail?(resource: IRestResource, gamedetail: IGameDetail): ng.IPromise<IGameDetail> {
        return this.Restangular.one(this.base, resource.id).all('gamedetails').customPOST(gamedetail);
    }

    updateGameDetail?(resource: IRestResource, gamedetail: IGameDetail): ng.IPromise<IGameDetail> {
        return this.Restangular.one(this.base, resource.id).one('gamedetails', gamedetail.id).customPUT(gamedetail);
    }

    removeGameDetail?(resource: IRestResource, gamedetail: IGameDetail): ng.IPromise<IGameDetail> {
        return this.Restangular.one(this.base, resource.id).one('gamedetails', gamedetail.id).remove();
    }
}
