import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { IInvitation } from "../../../../models/IInvitation";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import template from "./index.html";

@Component({
  selector: "stRoundRobinInvitations",
  template,
})
export class RoundRobinInvitations implements IComponentController {
  static Name = "stRoundRobinInvitations";
  static $inject = ["TeamRegistrationService"];
  @Input()
  registration: ITeamRegistration;

  @Input()
  roundRobin: IRoundRobin;

  public invitations: IInvitation[];

  constructor(private registrationService: TeamRegistrationService) { }

  $onInit() {
    this.refresh();
  }

  async refresh() {
    this.invitations = await this.registrationService.getRoundRobinInvitations(
      this.registration
    );
  }

  async accept(invitation: IInvitation) {
    invitation.accepted = true;
    invitation.declined = false;
    await this.registrationService.updateInvitation({
      id: invitation.id,
      accepted: true,
      declined: false,
    } as IInvitation);
  }

  async decline(invitation: IInvitation) {
    invitation.declined = true;
    invitation.accepted = false;
    await this.registrationService.updateInvitation({
      id: invitation.id,
      declined: true,
      accepted: false,
    } as IInvitation);
  }
}
