import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./payment.dashboard.template.html";
import { ILeague } from "../../../models/ILeague";
import { LeagueService } from "../../../services/LeagueService";
import { ISeason } from "../../../models/ISeason";
import { SeasonService } from "../../../services/SeasonService";
import { filter } from "lodash";
import {
  eachMonthOfInterval,
  endOfMonth,
  format,
  parseJSON,
  startOfMonth,
  subMonths,
} from "date-fns";
import { IFilterService } from "angular";
import { throwStatement } from "@babel/types";
import { ITeamRegistration } from "../../../models/ITeamRegistration";

const name = "stPaymentDashboard";

interface DateOption {
  id: number;
  label: string;
  value: Date;
}

@Component({
  selector: name,
  template,
})
export class PaymentDashboard extends UIComponent {
  static $inject: string[] = ["SeasonService", "$filter"];
  static Name: string = name;

  title = "Payment Dashboard";

  @Input() league: ILeague;

  @Input() season: ISeason;

  @Input() seasons: ISeason[];

  displayMonths: DateOption[] = eachMonthOfInterval({
    start: subMonths(Date.now(), 15),
    end: new Date(),
  }).map((month) => ({
    id: month.getTime(),
    label: this.$filter("date")(month, "MMMM yyyy"),
    value: month,
  }));

  workingMonth: DateOption = this.displayMonths[this.displayMonths.length - 1];

  paymentsInRange: ITeamRegistration[];
  paymentsLater: ITeamRegistration[];
  nonePayments: ITeamRegistration[];
  public csvContent: string;

  constructor(
    private seasonService: SeasonService,
    private $filter: IFilterService
  ) {
    super();
  }
  registrations: ITeamRegistration[];
  payments: ITeamRegistration[];

  $onInit() {}

  format(value: DateOption) {
    return value.label;
  }

  formatSeason(value: ISeason) {
    return value?.name;
  }

  onSeasonChange(value: ISeason) {
    this.season = value;
    this.loadRegistrations();
  }

  onChange(value: DateOption) {
    if (value) {
      this.filterPayments(value);
    }
  }

  async filterPayments(dateFilter: DateOption) {
    const monthStart = startOfMonth(dateFilter.value);
    const monthEnd = endOfMonth(dateFilter.value);

    this.paymentsInRange = filter(this.registrations, (reg) => {
      return (
        reg.payments?.amount_total > 0 &&
        parseJSON(reg.payments?.created) > monthStart &&
        parseJSON(reg.payments?.created) < monthEnd
      );
    });

    this.paymentsLater = filter(this.registrations, (reg) => {
      return (
        reg.payments?.amount_total > 0 &&
        parseJSON(reg.payments?.created) > monthEnd
      );
    });

    this.nonePayments = filter(this.registrations, (reg) => {
      return !reg.payments;
    });
  }

  async loadRegistrations() {
    this.registrations = await this.seasonService.paymentsBySeason(
      this.season?.id
    );
    this.filterPayments(this.workingMonth);
  }

  $onChanges() {
    if (this.league && this.season) {
      this.loadRegistrations();
    }
  }

  buildCsv(list: ITeamRegistration[]) {
    let cols = [
      "agegroup",
      "teamlevel",
      "team",
      "registered",
      "payment_date",
      "payment_amount",
    ];
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += cols.join(",") + "\r\n";
    const csvData = list.map((value) => {
      return {
        agegroup: value.ageGroup?.name,
        teamlevel: value.teamLevel?.name,
        team: value.team?.name,
        registered: format(parseJSON(value.created), "M/d/yyyy"),
        payment_date: value.payments?.created
          ? format(parseJSON(value.payments?.created), "M/d/yyyy")
          : "",
        payment_amount: value.payments?.amount_total
          ? value.payments?.amount_total / 100
          : 0,
      };
    });
    const output = csvData.reduce((prev, current) => {
      return prev + "\r\n" + Object.values(current).join(",");
    }, csvContent);
    return encodeURI(output);
  }

  download(list: ITeamRegistration[], name: string) {
    const cvsContent = this.buildCsv(list);
    let link = document.createElement("a");
    link.setAttribute("href", cvsContent);
    link.setAttribute("download", `export_${this.season.name}_${name}.csv`);
    document.body.appendChild(link);

    link.click();
  }
}
