import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

/**
 *
 *
 * @export
 * @class WellTemplate
 */
@Component({
  selector: "stHelpBlock",
  template,
  transclude: true,
})
export class HelpBlock {
  static $inject = [];

  /**
   *
   */
  constructor() {}

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
