import * as angular from "angular";
import { AgeGroupService } from "../../../services/AgeGroupService";
import { Component, Input, Output } from "angular-ts-decorators";
import { IAgeGroup } from "../../../models/IAgeGroup";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { ISeason } from "../../../models/ISeason";
import { ITeamLevel } from "../../../models/ITeamLevel";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { IUser } from "../../../models/IUser";
import { PermissionsService } from "../../../services/PermissionsService";
import { SeasonService } from "../../../services/SeasonService";
import { TeamRegistrationService } from "../../../services/TeamRegistrationService";
import { UIComponent } from "../../../core/UIComponent";
import template from "./TeamRegistrationEditor.template.html";

@Component({
  selector: "stTeamRegistrationEditor",
  template,
})
export class TeamRegistrationEditor extends UIComponent {
  static $inject: string[] = [
    "TeamRegistrationService",
    "SeasonService",
    "AgeGroupService",
    "PermissionsService",
    "$uibModal",
    "notify",
  ];
  @Input() registration: ITeamRegistration;
  @Input() user: IUser;
  @Input() roles: IRoleInfo;
  @Input() settings: ILeagueSetting;
  @Input() mode: string;

  season: ISeason;
  ageGroups: IAgeGroup[];
  teamLevels: ITeamLevel[];
  /**
   * Creates an instance of StaffManager.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf StaffManager
   */
  constructor(
    private registrationService: TeamRegistrationService,
    private seasonService: SeasonService,
    private ageGroupService: AgeGroupService,
    private permissionsService: PermissionsService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private notify: angular.cgNotify.INotifyService
  ) {
    super();
  }

  /**
   *
   *
   * @param {ITeamRegistration} registration
   *
   * @memberOf TeamRegistrationEditor
   */
  update(registration: ITeamRegistration) {
    let me = this;
    me.registrationService
      .update({
        id: registration.id,
        teamlevelId: registration.teamLevel.id,
        divisionPreference: registration.divisionPreference,
      } as ITeamRegistration)
      .then(() => {
        me.notify({ message: "settings updated", classes: "message-success" });
      });
  }

  /**
   *
   *
   * @param {any} changesObj
   *
   * @memberOf TeamRegistrationEditor
   */
  $onChanges(changesObj) {
    let me = this;
    if (me.registration) {
      me.seasonService
        .get(me.registration.seasonId)
        .then((season: ISeason) => {
          me.season = season;
          return me.seasonService.getAgeGroups(season);
        })
        .then((ageGroups: IAgeGroup[]) => {
          me.ageGroups = ageGroups;
          return me.ageGroupService.getTeamLevels(me.registration.ageGroup);
        })
        .then((teamLevels: ITeamLevel[]) => {
          me.teamLevels = teamLevels;
        });
    }
  }

  ageGroupLabel(value: IAgeGroup) {
    return value && value.name;
  }

  teamLevelLabel(value: ITeamLevel) {
    return value && value.name;
  }
}
