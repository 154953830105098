import { Injectable } from "angular-ts-decorators";
import { IHttpService, IPromise } from "angular";
import { IPracticePlans } from "./ipracticeplans";
import { ITeam } from "../../models/ITeam";
import { IPlans } from "./iplans";
import { IFile } from "../../models/IFile";
import { IFilter } from "../../models/IFilter";
import _ from "lodash";
import { IMap } from "../../models/IMap";
import { IPracticePlanCategory } from "./ipracticeplancategory";

@Injectable("PracticePlansService")
export class PracticePlansService {
  static $inject: string[] = ["$http"];

  constructor(private $http: IHttpService) { }

  public plans(): IPromise<IPracticePlans[]> {
    return this.$http
      .get<IPracticePlans[]>("/api/practiceplans")
      .then((response) => response.data);
  }

  public plansGroupedByCategory(): IPromise<IPracticePlanCategory[]> {
    let filter: IFilter = {
      include: {
        relation: "practiceplans",
        scope: {
          include: "category",
          where: {
            active: true
          }
        }
      }
    };

    return this.$http
      .get<IPracticePlanCategory[]>(
      `/api/practiceplancategories/?filter=${JSON.stringify(filter)}`
      )
      .then((response) => response.data);
  }

  public createPlan(plan: IPracticePlans): IPromise<IPracticePlans> {
    return this.$http
      .post<IPracticePlans>(`/api/practiceplans`, plan)
      .then((response) => response.data);
  }

  public updatePlan(plan: IPracticePlans): IPromise<IPracticePlans> {
    return this.$http
      .patch<IPracticePlans>(`/api/practiceplans/${plan.id}`, plan)
      .then((response) => response.data);
  }

  public removePlan(plan: IPracticePlans): IPromise<IPracticePlans> {
    plan.active = false;
    return this.$http
      .patch<IPracticePlans>(`/api/practiceplans/${plan.id}`, plan)
      .then((response) => response.data);
  }

  public getCategories(): IPromise<IPracticePlanCategory> {
    return this.$http
      .get<IPracticePlanCategory>(`/api/practiceplancategories`)
      .then((response) => response.data);
  }

  public getTeamPlans(team: ITeam): IPromise<IPlans[]> {
    let filter: IFilter = {
      include: "practiceplans"
    } as IFilter;
    return this.$http
      .get<IPlans[]>(
      `/api/teams/${team.id}/plans?filter=${JSON.stringify(filter)}`
      )
      .then((response) => response.data);
  }

  public savePlan(team: ITeam, plan: IPlans) {
    return this.$http
      .post<IPlans>(`/api/teams/${team.id}/plans`, plan)
      .then((response) => response.data);
  }

  public deletePlan(team: ITeam, plan: IPlans) {
    return this.$http.delete(`/api/teams/${team.id}/plans/${plan.id}`);
  }

  public addPracticeToPlan(plan: IPlans, practice: IPracticePlans) {
    return this.$http
      .put(`/api/plans/${plan.id}/practiceplans/rel/${practice.id}`, {})
      .then((response) => response.data);
  }
}
