import {SportsService} from '../services/SportsService';
import {ISport} from '../models/ISport';

export class SportsController {
    static $inject: string[] = ['SportsService'];

    sports: ISport[];
    newSport: ISport;
    selectedSport: ISport;

    constructor(private sportsService: SportsService) {
        this.newSport = {positions: [], attributes: []} as ISport;
        this.refresh();
    }

    refresh() {
        let me = this;
        me.sportsService.list()
        .then((sports: ISport[]) => { me.sports = sports; });
    }

    add(newSport: ISport) {
        let me = this;
        me.sportsService.add(newSport)
        .then((addedSport: ISport) => {
            me.sports.push(addedSport);
            me.newSport = {positions: [], attributes: []} as ISport;
        });
    }

    save(sport: ISport) {
        let me = this;
        me.sportsService.save(sport)
        .then(() => { me.selectedSport = null; });
    }

    delete(sport: ISport) {
        if (!confirm('are you sure you want to delete this sport?')) return false;
        let me = this;
        me.sportsService.delete(sport)
        .then(() => {
            let idx = me.sports.indexOf(sport);
            if (idx >= 0) me.sports.splice(idx, 1);
        });
    }

    addPosition(sport: ISport, position: string) {
        sport.positions.push(position);
    }

    addAttribute(sport: ISport, attribute: any) {
        sport.attributes.push(attribute);
    }
}
