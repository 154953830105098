import { IVanityUrl } from '../../../models/IVanityUrl';
import { Injectable } from 'angular-ts-decorators';
import { IService } from 'restangular';

@Injectable('VanityUrlService')
export class VanityUrlService {
    static $inject: string[] = ['Restangular'];

    constructor(private restangular: IService) {

    }

    getUrls(): ng.IPromise<any> {
        return this.restangular.all('vanityurls').getList();
    }

    add(url: IVanityUrl): ng.IPromise<IVanityUrl> {
        return this.restangular.all('vanityurls').customPOST(url);
    }
}
