import { applyMixins } from '../lib/Mixins';
import { ICarousel } from '../models/ICarousel';
import { ICarouselService } from './ICarouselService';
import { IFilter } from '../models/IFilter';
import { IForm } from '../models/IForm';
import { IFormService } from '../components/forms/forms.service';
import { IHttpService } from 'angular';
import { INews } from '../models/INews';
import { INewsService } from './INewsService';
import { Injectable } from 'angular-ts-decorators';
import { IOrganization } from '../models/IOrganization';
import { IPageService, IServiceMixin, PageService } from './IPagesService';
import { IRestResource } from '../models/IRestResource';
import { IService, IElement, ICollection } from 'restangular';
import { IVideo } from '../models/IVideo';
import { IVideoService } from './IVideoService';

@Injectable('OrganizationService')
export class OrganizationService implements INewsService, IVideoService, ICarouselService, IFormService, IPageService, IServiceMixin, PageService {

    static $inject = ['Restangular', '$http'];

    base: string = 'organizations';
    
    constructor(public Restangular: IService, public $http: IHttpService) {
    }   

    getAll(): ng.IPromise<IOrganization[]> {
        return this.Restangular.all('organizations').getList();
    }

    get(id: string): ng.IPromise<IOrganization> {
        let me = this;
        return me.Restangular.one('organizations', id).get<IOrganization>()
            .then(function(org) {
                return org;
            });
    }

    list(): ng.IPromise<IOrganization[]> {
        return this.Restangular.all('organizations').getList<IOrganization>()
            .then((orgs: IOrganization[]) => orgs);
    }

    add(org: IOrganization): ng.IPromise<IOrganization> {
        return this.Restangular.all('organizations').post(org)
            .then((neworg: IOrganization) => neworg);
    }

    save(org: IOrganization): ng.IPromise<IOrganization> {
        return this.Restangular.one('organizations', org.id).customPATCH(org)
            .then((response: IOrganization) => response);
    }

    delete(org: IOrganization): ng.IPromise<IOrganization> {
        return this.Restangular.one('organizations', org.id).remove()
            .then((response: IOrganization) => response);
    }

    roles(org: IOrganization) {
        return this.Restangular.one('organizations', org.id).customGET('roles');
    }

    private getNewsResource(org: IOrganization, news?: INews): IElement | ICollection {
        if (news) {
            return this.Restangular.one('organizations', org.id).one('news', news.id);
        }
        return this.Restangular.one('organizations', org.id).all('news');

    }

    getNews(org: IOrganization): ng.IPromise<INews[]> {
        return (this.getNewsResource(org) as ICollection).getList<INews>();
    }

    getNewsById(org: IOrganization, id: string): ng.IPromise<INews> {
        return (this.getNewsResource(org, { id } as INews) as IElement).get();
    }

    addNews(org: IOrganization, news: INews): ng.IPromise<INews> {
        let imageCopy = news.banner;
        news.banner = null;
        return (this.getNewsResource(org) as ICollection).post(news)
            .then((added: INews) => {
                added.banner = imageCopy;
                return added;
            });
    }

    updateNews(org: IOrganization, news: INews): ng.IPromise<INews> {
        let imageCopy = news.banner;
        news.banner = null;
        return (this.getNewsResource(org, news) as IElement).customPUT(news)
            .then((updated: INews) => {
                updated.banner = imageCopy;
                return updated;
            });
    }

    removeNews(org: IOrganization, news: INews): ng.IPromise<INews> {
        return (this.getNewsResource(org, news) as IElement).remove();
    }

    private getVideoResource(org: IOrganization, video?: IVideo): IElement | ICollection {
        if (video) {
            return this.Restangular.one('organizations', org.id).one('videos', video.id);
        }
        return this.Restangular.one('organizations', org.id).all('videos');
    }

    getVideos(org: IOrganization): ng.IPromise<IVideo[]> {
        return this.getVideoResource(org).getList();
    }

    addVideo(org: IOrganization, video: IVideo): ng.IPromise<IVideo> {
        return this.getVideoResource(org).customPOST(video);
    }

    updateVideo(org: IOrganization, video: IVideo): ng.IPromise<IVideo> {
        return this.getVideoResource(org, video).customPUT(video);
    }

    removeVideo(org: IOrganization, video: IVideo): ng.IPromise<IVideo> {
        return (this.getVideoResource(org, video) as IElement).remove();
    }

    private getCarouselResource(org: IOrganization, carousel?: ICarousel): IElement | ICollection {
        if (carousel) {
            return this.Restangular.one('organizations', org.id).one('carousels', carousel.id);
        }
        return this.Restangular.one('organizations', org.id).all('carousels');
    }

    getCarousels(org: IOrganization): ng.IPromise<ICarousel[]> {
        return this.getCarouselResource(org).getList();
    }

    addCarousel(org: IOrganization, carousel: ICarousel): ng.IPromise<ICarousel> {
        let imageCopy = carousel.image;
        carousel.image = null;
        return (this.getCarouselResource(org) as ICollection).post(carousel)
            .then((addedCarousel: ICarousel) => {
                if (!org.carousels) {
                    org.carousels = [];
                }
                addedCarousel.image = imageCopy;
                return addedCarousel;
            });
    }

    updateCarousel(org: IOrganization, carousel: ICarousel): ng.IPromise<ICarousel> {
        let imageCopy = carousel.image;
        carousel.image = null;
        return this.getCarouselResource(org, carousel).customPUT(carousel)
            .then((updatedCarousel) => {
                updatedCarousel.image = imageCopy;
                return updatedCarousel;
            });
    }

    removeCarousel(org: IOrganization, carousel: ICarousel): ng.IPromise<ICarousel> {
        return (this.getCarouselResource(org, carousel) as IElement).remove();
    }

    private getFormResource(org: IRestResource, form?: IForm): IElement | ICollection {
        if (form) {
            return this.Restangular.one('organizations', org.id).one('forms', form.id);
        }
        return this.Restangular.one('organizations', org.id).all('forms');
    }

    getForms(resource: IRestResource): ng.IPromise<IForm[]> {
        return this.getFormResource(resource).getList();
    }

    getFeaturedForms(resource: IRestResource): ng.IPromise<IForm[]> {
        let filter: IFilter = {
            where: {
                featured: true
            }
        } as IFilter;
        return this.getFormResource(resource).getList({filter});
    }

    getForm(resource: IRestResource, id: string): ng.IPromise<IForm> {
        return (this.getFormResource(resource, {id} as IForm) as IElement).get();
    }

    addForm(resource: IRestResource, form: IForm): ng.IPromise<IForm> {
        return this.getFormResource(resource, form).customPOST(form);
    }

    updateForm(resource: IRestResource, form: IForm): ng.IPromise<IForm> {
        return this.getFormResource(resource, form).customPUT(form);
    }

    removeForm(resource: IRestResource, form: IForm): ng.IPromise<IForm> {
        return (this.getFormResource(resource, form) as IElement).remove();
    }
}

applyMixins(OrganizationService, [PageService]);
