import { Component } from "angular-ts-decorators";
import { IDivision } from "../../models/IDivision";
import { TeamLevelService } from "../../services/TeamLevelService";
import { LeagueValueSelector, template } from "../base/LeagueValueSelector";

@Component({
    selector: 'stDivisionSelector',
    template
})
export class DivisionSelector extends LeagueValueSelector<IDivision> {
    static $inject: string[] = ["TeamLevelService"];

    constructor(private teamLevelService: TeamLevelService) {
        super("division", "select a division", "teamLevelId");
    }

    label(value: IDivision) {
        return value?.name;
    }

    async load(teamLevelId: string) {
        this.values = await this.teamLevelService.getDivisions(teamLevelId);
        this.values.forEach((division) => {
            if (division?.id === this.divisionId) {
                this.selected = division;
            }
        })
    }
}