import {ISport} from '../models/ISport';
import {OrganizationService} from '../services/OrganizationService';
import {SportsService} from '../services/SportsService';
import {IOrganization} from '../models/IOrganization';
import {IFilter} from '../models/IFilter';
import {IUser} from '../models/IUser';

export class OrganizationAdminController {
    static $inject: string[] = ['OrganizationService', 'SportsService'];

    allOrgs: IOrganization[];
    allSports: ISport[];

    newOrg: IOrganization;
    selectedOrg: IOrganization;

    constructor(private organizationService: OrganizationService, private sportsService: SportsService) {
        let me = this;
        me.newOrg = {domains: []} as IOrganization;
        me.refresh();
    }

    refresh() {
        let me = this;
        me.organizationService.list()
        .then((organizations: IOrganization[]) => { me.allOrgs = organizations; });
        me.sportsService.list()
        .then((sports: ISport[]) => { me.allSports = sports; });
    }

    add(org: IOrganization) {
        let me = this;
        me.organizationService.add(org)
        .then((addedOrg: IOrganization) => {
            me.allOrgs.push(addedOrg);
            me.newOrg = {domains: []} as IOrganization;
        });
    }

    save(org: IOrganization) {
        let me = this;
        org.sportId = org.sport && org.sport.id;
        org.sport = null;
        me.organizationService.save(org)
        .then((updatedOrg) => { me.selectedOrg = null; });
    }

    delete(org: IOrganization) {
        if (!confirm('are you sure you want to delete this org?')) return false;
        let me = this;
        me.organizationService.delete(org)
        .then(() => {
            let idx = me.allOrgs.indexOf(org);
            if (idx >= 0) me.allOrgs.splice(idx, 1);
        });
    }

    sportsLabel(sport: ISport) {
        return sport.name;
    }
}
