import template from "./template.html";
import { ITeam } from "../../../models/ITeam";
import { Component, Input, Output } from "angular-ts-decorators";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import { IUser } from "../../../models/IUser";
import { TeamRegistrationService } from "../../../services/TeamRegistrationService";
import _ from "lodash";
import { StandingsService } from "../../../services/StandingsService";
import { IGame } from "../../../models/IGame";
import { IDateTime } from "../../../models/IDateTime";
import { GameService } from "../../../services/GameService";
import { IRosterEntry } from "../../../models/IRosterEntry";

const selector = "stTeamScheduling";

@Component({
  selector,
  template,
})
export class TeamScheduling {
  static $inject = [
    "TeamRegistrationService",
    "StandingsService",
    "GameService",
    "notify",
    "$filter",
  ];
  static Name = selector;
  @Input()
  team: ITeam;
  @Input()
  registration: ITeamRegistration;
  @Input()
  settings: ILeagueSetting;
  @Input()
  user: IUser;

  //allOtherTeams: ITeamRegistration[];
  otherTeams: ITeamRegistration[];
  otherTeamsCopy: ITeamRegistration[];
  otherTeamsInDivision: ITeamRegistration[];
  public predicate: string = "team.name";
  /**
   *
   */
  constructor(
    private registrationService: TeamRegistrationService,
    private standingsService: StandingsService,
    private gameService: GameService,
    private notify: angular.cgNotify.INotifyService,
    private $filter: angular.IFilterService
  ) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    this.refresh();
  }

  refresh() {
    this.registrationService
      .getTeamsWhoHaveInvited(this.registration)
      .then((teams) => {
        this.otherTeams = _.filter(
          this.standingsService.calculateStandingsOfTeamRegistrations(teams),
          (team: ITeamRegistration) => {
            return team.id !== this.registration.id; // only other teams
          }
        );
      });

    this.registrationService
      .getTeamsInDivision(this.registration)
      .then((teams) => {
        this.otherTeamsInDivision = _.filter(
          this.standingsService.calculateStandingsOfTeamRegistrations(teams),
          (team: ITeamRegistration) => {
            return team.id !== this.registration.id; // only other teams
          }
        );
      });
  }

  dateList(teamRegistration: ITeamRegistration) {
    return _.filter(teamRegistration.homeDates, (tr: IDateTime) => {
      return !tr.taken && tr.datetime;
    });
  }

  hasMatch(games: IGame[]) {
    let idx = _.findIndex(games, (game: IGame) => {
      return game.awayTeamId === this.registration.id;
    });
    return idx >= 0;
  }

  hasReachedLimit() {
    return (
      this.registration.homeDates.length <= this.registration.awayGames.length
    );
  }

  async createGame(
    homeTeam: ITeamRegistration,
    date: IDateTime,
    skipUnvite: boolean
  ) {
    if (
      !confirm(
        `Are you sure you want to create a game with ${
          homeTeam.team.name
        } on ${this.$filter("date")(date.datetime, "EEE, MMMM dd, yyyy h:mma")}`
      )
    ) {
      return;
    }
    try {
      this.otherTeamsCopy = this.otherTeams;
      this.otherTeams = [];
      let game = await this.gameService.scheduleGame({
        homeTeamId: homeTeam.id,
        awayTeamId: this.registration.id,
        dateId: date.id,
        gameType: "open",
        seasonId: this.registration.seasonId,
        agegroupId: this.registration.ageGroupId,
        teamlevelId: this.registration.teamlevelId,
      } as IGame);
      let fullGame = await this.gameService.getGame(game.id);
      this.registration.awayGames.push(fullGame);
      if (skipUnvite) {
        this.notify({
          message:
            "The game was created successfully, hang on while we refresh the list",
          classes: "message-success",
        });
        this.refresh();
      } else {
        this.registrationService
          .uninviteTeam(homeTeam, homeTeam.invite[0])
          .then((result) => {
            this.refresh();
            this.notify({
              message:
                "The game was created successfully, hang on while we refresh the list",
              classes: "message-success",
            });
          });
      }
    } catch (ex) {
      console.log(ex);
      this.notify({
        message:
          "There was an error creating the game, it's most likely that the date was already taken by someone else.",
        classes: "message-danger",
      });
      this.refresh();
    }
  }

  canSchedule() {
    return this.canScheduleHomeDates() && this.canScheduleRoster();
  }

  canScheduleRoster() {
    if (this.settings.restrictScheduling) {
      if (this.registration && this.registration.earlyScheduling) {
        return true;
      }
      return (
        this.registration &&
        this.registration.roster.length >= this.settings.minimumRoster &&
        this.allRostersSigned(this.registration.roster)
      );
    }
    return true;
  }

  canScheduleHomeDates() {
    if (this.settings.restrictScheduling) {
      if (this.registration && this.registration.earlyScheduling) {
        // short circuit the logic if early scheduling is enabled
        return true;
      }
      return (
        this.registration &&
        this.registration.homeDates.length >=
          (this.settings.minimumHomeDates || -1)
      );
    }
    return true;
  }

  signedRosters(rosters: IRosterEntry[]) {
    return _.filter(rosters, (roster) => {
      return roster.waiver;
    });
  }
  allRostersSigned(rosters: IRosterEntry[]) {
    let rostersWithWaivers = this.signedRosters(rosters);
    return rostersWithWaivers.length >= rosters.length;
  }
}
