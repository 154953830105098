import { IFile } from "../../../models/IFile";
import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

@Component({
  selector: "stInlineImageUpload",
  template,
})
export class InlineImageUpload {
  static $inject: string[] = ["$timeout", "Upload"];
  @Input("=")
  image: IFile;
  @Input("@")
  iconSize: string = "lg";
  @Input("@")
  defaultText: string = "drop logo here";
  @Input("@")
  disabled: string;
  @Output()
  onImageChange: ({ image }) => {};

  disabledParsed: boolean;
  constructor(
    private $timeout: ng.ITimeoutService,
    private Upload: angular.angularFileUpload.IUploadService
  ) {}

  uploadPhoto(file) {
    if (!file) return;
    let me = this;
    let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
      method: "POST",
      data: { file },
      url: "/api/files/upload",
    };
    file.upload = me.Upload.upload(fileUploadConfig);
    file.upload.then(function (response) {
      me.$timeout(function () {
        me.image = response.data;
        me.onImageChange({ image: response.data });
      });
    });
  }

  $onChanges(changeObj) {
    let me = this;
    if (changeObj && changeObj.disabled && changeObj.disabled.currentValue) {
      me.disabledParsed = changeObj.disabled.currentValue === "true";
    }
  }
}
