import _ from "lodash";
import { IAgeGroup } from "../models/IAgeGroup";
import { IFilter } from "../models/IFilter";
import { IGame } from "../models/IGame";
import { ILeague } from "../models/ILeague";
import { ILeagueWindowService } from "../models/ILeagueWindowService";
import { IMessage } from "../models/IMessage";
import { IRoleInfo } from "../models/IRoleInfo";
import { IRootStateService } from "../models/IRootStateService";
import { ISeason } from "../models/ISeason";
import { IService, IElement, ICollection } from "restangular";
import { ISortingRules } from "../models/ISortingRules";
import { ITeam } from "../models/ITeam";
import { ITeamLevel } from "../models/ITeamLevel";
import { ITournament } from "../models/ITournament";
import { IUser } from "../models/IUser";
import { LeagueService } from "../services/LeagueService";
import { SeasonService } from "../services/SeasonService";
import { StandingsService } from "../services/StandingsService";
import { TournamentsService } from "../services/TournamentsService";
import { UserService } from "../services/UserService";
import { StateService } from "@uirouter/angularjs";
import { LeagueWaiverService } from "../modules/leauge/league.waiver.service";
import moment from "moment";

// import moment from 'moment';

export class LeagueController {
  static $inject = [
    "$rootScope",
    "$window",
    "$scope",
    "$state",
    "$timeout",
    "Restangular",
    "Upload",
    "localStorageService",
    "StandingsService",
    "TournamentsService",
    "SeasonService",
    "LeagueService",
    "UserService",
    "LeagueWaiverService",
    "leagueId"
  ];
  sliderInterval: number = 5000;
  id: string;
  userId: string;
  name: string;
  leagueData: ILeague;
  seasons: ISeason[];
  currentSeason: ISeason;
  ageGroups: IAgeGroup[];
  leagueService: IElement;
  messagesService: ICollection;
  seasonService: IElement;
  newMessage: IMessage;
  selectedMessage: IMessage;
  userInfo: IUser;
  roleInfo: IRoleInfo;
  newUser: IUser = {} as IUser;
  upcomingGames: IGame[];
  tournaments: ITournament[];
  hasTournament: boolean;

  openSeasons: ISeason[];
  themeUrl: string;

  constructor(
    private $rootScope: IRootStateService,
    private $window: ILeagueWindowService,
    private $scope: ng.IScope,
    private $state: StateService,
    private $timeout: ng.ITimeoutService,
    private Restangular: IService,
    private Upload: angular.angularFileUpload.IUploadService,
    private localStorageService: angular.local.storage.ILocalStorageService,
    private standingsService: StandingsService,
    private tournamentsService: TournamentsService,
    private seasontService: SeasonService,
    private leagueSvc: LeagueService,
    private userService: UserService,
    public leagueWaiverService: LeagueWaiverService,
    private leagueId: string
  ) {
    this.id = leagueId;
    this.themeUrl = `./theme.css?leagueId=${leagueId}`
    this.userId = $window.userId;
    this.leagueService = Restangular.one("leagues", this.id);
    this.messagesService = this.leagueService.all("messages");
    this.refreshSeasons();
    this.newMessage = {} as IMessage;
    this.newUser.leagueId = leagueId;
  }

  selectedAgeGroup: IAgeGroup;
  setAgeGroup(ageGroup: IAgeGroup) {
    this.selectedAgeGroup = ageGroup;
    this.localStorageService.set("ageGroupId", ageGroup && ageGroup.id);
    this.refreshUpcomingGames();
  }

  getAgeGroup(): IAgeGroup {
    let me = this;
    if (!this.selectedAgeGroup) {
      let ageGroupId = this.localStorageService.get("ageGroupId");
      if (ageGroupId && this.ageGroups) {
        _.each(this.ageGroups, function (ag) {
          if (ag.id === ageGroupId) {
            me.setAgeGroup(ag);
          }
        });
      } else {
        this.setAgeGroup(this.ageGroups[0]);
      }
    }
    return this.selectedAgeGroup;
  }

  loggedIn(user: IUser) {
    let me = this;
    me.userInfo = user;
    me.userId = user.id;
    me.loadRoles();
  }

  logout(user: IUser) {
    let me = this;
    me.userService.logout().then(() => {
      me.userInfo = null;
      me.roleInfo = null;
      me.$state.go(".", { user: null }, { notify: true, reload: true });
    });
  }

  async loadUser() {
    if (this.userId) {
      let me = this;
      let user = await this.userService.get(this.userId);
      me.userInfo = user;
      me.loadRoles();
    }
  }

  async loadRoles() {
    let me = this;
    let roles = await me.userService.roles(me.userInfo, me.leagueData);
    me.roleInfo = me.$rootScope.roleInfo = roles;
  }

  isInRole(role: string) {
    if (!this.roleInfo || this.roleInfo.roles) return false;
    if (role === "admin") {
      return this.roleInfo.isAdmin;
    } else {
      return this.roleInfo.isAdmin || this.roleInfo.roles.indexOf(role) > -1;
    }
  }

  generateOpenSeasons() {
    let now = new Date();
    this.openSeasons = _.filter(this.seasons, (season: ISeason) => {
      season.registrationClose =
        season.registrationClose && moment(season.registrationClose).toDate();
      season.registrationOpen =
        season.registrationOpen && moment(season.registrationOpen).toDate();
      return (
        now > season.registrationOpen &&
        (season.registrationClose > now || !season.registrationClose)
      );
    });
  }

  refreshSeasons() {
    let me = this;
    let filter: IFilter = {
      include: [
        {
          carousels: "image"
        },
        {
          sponsors: "image"
        },
        "logo",
        {
          relation: "pages",
          scope: {
            fields: ["id", "title"]
          }
        }
      ]
    };
    this.leagueService.get<ILeague>({ filter }).then(function (theLeague) {
      me.leagueData = theLeague;
      me.loadUser();
    });

    this.leagueService.getList<ISeason>("seasons").then(function (result) {
      me.$rootScope.seasons = me.seasons = result;
      me.generateOpenSeasons();
      let urlSeason = me.$state.params.seasonOverride;
      // for (let r = 0; r < result.length; r++) {
      for (let s of result) {
        // let s = result[r];
        if (
          (urlSeason && urlSeason === s.id) ||
          (!urlSeason && new Date(s.end.toString()) > new Date())
        ) {
          me.$rootScope.currentSeason = me.currentSeason = s;
          me.seasonService = me.Restangular.one("seasons", s.id);
          me.refreshAgeGroups();
          me.refreshUpcomingGames();
          me.refreshTournaments();
          me.$scope.$watch(
            function () {
              return me.currentSeason;
            },
            function (newValue, oldValue) {
              if (newValue) {
                me.seasonService = me.Restangular.one("seasons", newValue.id);
                me.$rootScope.currentSeason = newValue;
                me.refreshAgeGroups();
                me.refreshUpcomingGames();
                me.refreshTournaments();
              }
            },
            true
          );
          return;
        }
      }
    });
  }

  refreshTournaments() {
    let me = this;
    me.tournamentsService
      .getSeasonTournamnets(me.currentSeason.id)
      .then(function (tournaments) {
        if (tournaments && tournaments.length) {
          me.tournaments = tournaments;
          me.hasTournament = true;
        }
      });
  }

  registerUser() {
    let me = this;
    this.leagueService
      .customPOST(this.newUser, "register")
      .then(function (user: IUser) {
        me.$state.go("league.verify");
      });
  }

  selectMessage(message: IMessage) {
    this.selectedMessage = message;
    if (this.$state.is("league.home")) {
      this.$state.go("league.messages.edit", { id: message.id });
    } else {
      this.$state.go("league.messages.edit", { id: message.id });
    }
  }

  removeMessage(message: IMessage) {
    if (!confirm("Are you sure you want to delete this message?")) return;
    let me = this;
    let messageService: IElement = me.leagueService.one("messages", message.id);
    messageService.remove().then(function (result) {
      let idx = me.leagueData.messagesList.indexOf(message);
      me.leagueData.messagesList.splice(idx, 1);
    });
  }

  addMessage() {
    let me = this;
    this.messagesService.post(this.newMessage).then(function (message) {
      me.leagueData.messagesList.push(message);
      me.newMessage = {} as IMessage;
      if (me.$state.is("league.home.addmessage")) {
        me.$state.go("league.home");
      } else {
        me.$state.go("league.messages");
      }
    });
  }

  saveMessage() {
    let me = this;
    let messageService = this.leagueData.one(
      "messages",
      this.selectedMessage.id
    );
    messageService
      .customPUT(this.selectedMessage)
      .then(function (message) {
        if (me.$state.is("league.home.editmessage")) {
          me.$state.go("league.home");
        } else {
          me.$state.go("league.messages");
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  saveSettings() {
    this.leagueSvc.saveSettings(this.leagueData, this.leagueData.leaguesettings).then(() => {
      this.$state.go("league.home");
    });
  }

  refreshAgeGroups() {
    let me = this;
    this.seasonService
      .getList<IAgeGroup>("agegroups", { filter: { include: "teamlevels" } })
      .then(function (result) {
        me.ageGroups = result;
        me.refreshStandings(me.getAgeGroup());
      });
  }
  curPage: number = 0;
  allGames: IGame[];
  refreshUpcomingGames() {
    let me = this;
    me.seasontService
      .getUpcomingGames(
        me.currentSeason.id,
        me.selectedAgeGroup && me.selectedAgeGroup.id
      )
      .then(function (games) {
        me.allGames = games;
      });
  }

  standings: ITeamLevel[];

  refreshStandings(ageGroup: IAgeGroup) {
    this.setAgeGroup(ageGroup);
    let me = this;
    let filter: IFilter = {
      include: [
        {
          relation: "divisions",
          scope: {
            include: [
              {
                relation: "teamregistrations",
                scope: {
                  include: [
                    {
                      relation: "awayGames",
                      scope: {
                        include: [
                          "date",
                          {
                            relation: "awayTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          },
                          {
                            relation: "homeTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      relation: "homeGames",
                      scope: {
                        include: [
                          "date",
                          {
                            relation: "awayTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          },
                          {
                            relation: "homeTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      relation: "team",
                      scope: {
                        fields: ["name", "teamPhotoId", "urlKey"],
                        include: {
                          relation: "teamPhoto",
                          scope: {
                            fields: ["url"]
                          }
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
    me.Restangular.one("agegroups", ageGroup.id)
      .getList<ITeamLevel>("teamlevels", { filter })
      .then(function (teamLevels) {
        me.standings = me.standingsService.calculateStandingsOfLevels(
          teamLevels,
          { excludeTournaments: true } as ISortingRules
        );
      });
  }

  updateMe() {
    let me = this;
    this.userInfo.save().then(function (user) {
      me.$state.go("league.home");
    });
  }

  teamAcronym(team: ITeam) {
    if (!team || !team.name) return "tbd";
    let parts = team.name.split(" ");
    let acronym = "";
    _.each(parts, function (p) {
      acronym = acronym + p.substr(0, 1);
    });
    return acronym;
  }

  otherTeamName(team, game) {
    if (team.id === game.awayTeam.id) {
      return "@ " + this.teamAcronym(game.homeTeam && game.homeTeam.team);
    } else if (team.id === game.homeTeam.id) {
      return "vs " + this.teamAcronym(game.awayTeam && game.awayTeam.team);
    }
  }

  uploadLogo(file, destination: ILeague) {
    if (!file) return;
    let me = this;

    let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
      method: "POST",
      data: { file },
      url: "api/files/upload"
    };
    file.upload = me.Upload.upload(fileUploadConfig);
    file.upload.then(function (response) {
      me.$timeout(function () {
        destination.logo = response.data;
        destination.logoId = response.data.id;
      });
    });
  }

  public selectedCurrentSeason: ISeason;
  changeSeason(season: ISeason) {
    this.currentSeason = this.selectedCurrentSeason;
    this.$state.go(this.$state.current.name, {
      seasonOverride: this.selectedCurrentSeason.id
    });
  }
}
