import _ from "lodash";
import { Component, Input, Output } from "angular-ts-decorators";
import { IGame } from "../../../models/IGame";
import { ILeagueSetting } from "../../../models/ILeagueSetting";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IService } from "restangular";
import { ITeam } from "../../../models/ITeam";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { IUser } from "../../../models/IUser";
import { PermissionsService } from "../../../services/PermissionsService";
import { PostPone } from "./postpone.modal";
import { StateService } from "@uirouter/angularjs";
import { UIComponent } from "../../../core/UIComponent";
import template from "./game.display.template.html";
import { GameService } from "../../../services/GameService";

@Component({
  selector: "stGameDisplay",
  template,
})
export class GameDisplay extends UIComponent {
  static $inject = [
    "$state",
    "$sce",
    "PermissionsService",
    "Restangular",
    "$uibModal",
    "GameService",
  ];
  @Input()
  game: IGame;
  @Input()
  roles: IRoleInfo;
  @Input()
  user: IUser;
  @Input()
  team: ITeam;
  @Input()
  registration: ITeamRegistration;
  @Input()
  settings: ILeagueSetting;
  @Input()
  mode: string;
  /**
   *
   */
  constructor(
    private $state: StateService,
    private $sce: angular.ISCEService,
    private permissionsService: PermissionsService,
    private restangular: IService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private gameService: GameService
  ) {
    super();
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}

  gameHasScore(game: IGame) {
    return (
      game.homeTeamScore > 0 ||
      game.awayTeamScore > 0 ||
      game.homeTeamForfeit ||
      game.awayTeamForfeit
    );
  }

  private openModal(resolver: any): ng.IPromise<string> {
    return this.modal<string>(
      this.$uibModal,
      require("./postpone.modal.html").default,
      PostPone,
      resolver
    );
  }

  rescheduleGame(game: IGame) {
    if (!confirm("are you sure you would like to reschedule this game?"))
      return;
    let me = this;

    let date = game.date;
    me.restangular
      .one("games", game.id)
      .customPATCH({ dateId: game.dateId }, "unsetdate")
      .then(function () {
        _.each(game.homeTeam.homeDates, function (theDate) {
          if (theDate.id === game.date.id) {
            theDate.taken = false;
          }
        });
        game.date.taken = false;
        game.date = null;
        me.$state.go("league.team.game.choosedate");
      });
  }

  postponeGame(game: IGame) {
    let me = this;
    me.openModal({
      item() {
        return "";
      },
    }).then((reason) => {
      let date = game.date;
      me.restangular
        .one("games", game.id)
        .customPATCH({ dateId: game.dateId, reason }, "postpone")
        .then(function () {
          _.each(game.awayTeam.homeDates, function (theDate) {
            if (theDate.id === game.date.id) {
              theDate.taken = false;
            }
          });
          game.date.taken = false;
          game.date = null;
        });
    });
  }

  isManager() {
    return this.permissionsService.isManager(this.roles, this.team, this.user);
  }

  isTeamDirector() {
    return this.permissionsService.isTeamDirector(
      this.roles,
      this.team,
      this.user
    );
  }

  isScoreKeeper() {
    return this.permissionsService.isScoreKeeper(
      this.roles,
      this.team,
      this.user
    );
  }

  mapUrl() {
    let game = this.game;
    let addressString: string = "{street} {city} {state}, {zip}"
      .replace("{street}", game.date.facility.location.street || "")
      .replace("{city}", game.date.facility.location.city || "")
      .replace("{state}", game.date.facility.location.state || "")
      .replace("{zip}", game.date.facility.location.zip);
    let url: string =
      "https://www.google.com/maps/embed/v1/search?q={address}&key=AIzaSyBmLgI11kbyhMm3ggHgV9_Ged41jFWTQcc".replace(
        "{address}",
        encodeURIComponent(addressString)
      );
    return this.$sce.trustAsResourceUrl(url);
  }

  offerToHost(game: IGame) {
    let me = this;
    me.restangular
      .one("games", game.id)
      .customPATCH({ awayTeamOffersToHost: true })
      .then(function () {
        game.awayTeamOffersToHost = true;
      });
  }

  deleteGame(game: IGame) {
    if (confirm("Are you sure you would like to delete this game?")) {
      this.gameService
        .deleteAwayGame(this.registration, game)
        .then((response) => {
          this.$state.go("league.team.home", {}, { reload: true });
        });
    }
  }
}
