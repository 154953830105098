import { NgModule } from "angular-ts-decorators";
import { LineupTool } from "../lineuptool/lineuptool";
import { ImportRoster } from "./importroster/importroster";
import { StateProvider } from "@uirouter/angularjs";

@NgModule({
  id: "TeamModule",
  declarations: [ImportRoster],
  imports: [LineupTool],
  providers: []
})
export class TeamModule {
  public static config($stateProvider: StateProvider) {
    $stateProvider.state({
      name: "league.team.rostermanager.import",
      url: "/import",
      component: ImportRoster.Name
    });
  }
}
