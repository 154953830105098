import { Injectable } from "angular-ts-decorators";
import { IHttpService } from "angular";
import { IRestResource } from "../../models/IRestResource";
import { IEmergencyContact } from "./emergencycontact/iemergencycontact";
import { IRosterEntry } from "../../models/IRosterEntry";

@Injectable("LeagueRosterService")
export class LeagueRosterService {
  static $inject: string[] = ["$http"];

  constructor(private $http: IHttpService) {}

  updateEmergencyContact(
    resource: IRosterEntry
  ): ng.IPromise<IEmergencyContact> {
    return this.$http
      .put<IEmergencyContact>(
        `/api/rosterentries/${resource.id}/emergencycontact`,
        resource.emergencyContact
      )
      .then((response) => response && response.data);
  }

  getRosterEntry(id: string): ng.IPromise<IRosterEntry> {
    return this.$http
      .get<IRosterEntry>(`/api/rosterentries/${id}`)
      .then((response) => response && response.data);
  }
}
