import { Component, Input } from "angular-ts-decorators";
import template from "./practiceplans.html";
import { PracticePlansService } from "./praciteplans.service";
import { IPracticePlans } from "./ipracticeplans";
import _ from "lodash";
import { ISCEService } from "angular";
import { IPlans } from "./iplans";
import { ITeam } from "../../models/ITeam";

const name: string = "practicePlans";

@Component({
  selector: name,
  bindings: {},
  template,
})
export class PracticePlans {
  static Name: string = name;
  static $inject: string[] = ["PracticePlansService", "$sce"];

  public plans: IPlans[];

  @Input() public team: ITeam;

  @Input() public createPath;
  @Input() mode: string;
  constructor(
    private practicePlansService: PracticePlansService,
    private $sce: ISCEService
  ) {}

  $onInit() {}

  $onChanges() {
    if (this.team) {
      this.practicePlansService
        .getTeamPlans(this.team)
        .then((plans: IPlans[]) => {
          this.plans = plans;
        });
    }
  }

  public trust(url: string) {
    return this.$sce.trustAsResourceUrl(url);
  }

  deletePlan(plan: IPlans) {
    if (!confirm("are you sure you want to delete this practice plan?")) return;
    this.practicePlansService.deletePlan(this.team, plan).then(() => {
      const idx = this.plans.indexOf(plan);
      if (idx >= 0) this.plans.splice(idx, 1);
    });
  }
}
