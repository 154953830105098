import { NgModule } from "angular-ts-decorators";
import { VanityUrl } from "./vanityurl/component";
import { VanityUrlAdd } from "./vanityurl/add/component";
import { VanityUrlService } from "./services/vanityurl.service";

export const ComponentsModuleName: string = "league-manager-admin";

@NgModule({
  id: ComponentsModuleName,
  declarations: [VanityUrl, VanityUrlAdd],
  providers: [VanityUrlService],
  imports: []
})
export class AdminModule {}
