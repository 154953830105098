import { GridModule } from '../grid/grid.module';
import { PagesViewer } from './viewer/component';
import { PagesModalService } from './pages.modal.service';
import { PagesEditor } from './editor/component';
import { PagesComponent } from './list/component';
import { NgModule } from 'angular-ts-decorators';

export const ComponentsModuleName: string = 'league-manager-pages-components';

@NgModule({
    id: ComponentsModuleName,
    declarations: [
        PagesComponent,
        PagesEditor,
        PagesViewer
    ],
    providers: [
        PagesModalService
    ],
    imports: [
        GridModule
    ]
})
export class PagesModule {

}
