import _ from "lodash";
import { IPromise } from "angular";
import { IService } from "restangular";

import { ITeamRegistration } from "../models/ITeamRegistration";
import { ILeagueScope } from "../models/ILeagueScope";
import { IRosterEntry } from "../models/IRosterEntry";
import { StateService } from "@uirouter/angularjs";
import { ISeason } from "../models/ISeason";
import { IFilter } from "../models/IFilter";

export class RosterExportController {
  static $inject = ["$state", "$scope", "Restangular", "$timeout"];
  constructor(
    private $state: StateService,
    private $scope: ILeagueScope,
    private Restangular: IService,
    private $timeout: ng.ITimeoutService
  ) {
    let me = this;
    if ($scope.league.currentSeason) {
      this.refresh($scope.league.currentSeason);
    }
  }

  public cols = [
    "number",
    "waiver",
    "waiversignature",
    "waiverdate",
    "firstName",
    "lastName",
    "birthday",
    "phoneNumber",
    "grade",
    "guardianemail",
  ];
  public rosterentries: IRosterEntry[] = [];
  public selctedSeason: string;
  public predicate: string = "number";
  public order = {
    number: "number",
    waiver: "waiver",
    waiversignature: "waiversignature",
    waiverdate: "waiverdate",
    firstName: "player.firstName",
    lastName: "player.lastName",
    birthday: "player.birthday",
    phoneNumber: "player.phoneNumber",
    grade: "player.grade",
    guardianemail: "player.guardianemail",
  };
  public csvContent: string;
  private resolveRosterentries: Array<IPromise<void>> = [];

  refresh(season: ISeason) {
    let me = this;
    me.rosterentries = [];
    const teamRegistrationsFilter: IFilter = {
      fields: {
        id: true,
      },
      where: {
        seasonId: season.id,
      },
    };
    me.selctedSeason = season.name;
    me.Restangular.all("teamregistrations")
      .getList<ITeamRegistration>({ filter: teamRegistrationsFilter })
      .then((teamregistrations: ITeamRegistration[]) => {
        _.forEach(teamregistrations, (teamregistration: ITeamRegistration) => {
          const rosterEntriesFilter: IFilter = {
            where: {
              teamregistrationId: teamregistration.id,
            },
          };
          me.resolveRosterentries.push(me.Restangular
            .all("rosterentries")
            .getList<IRosterEntry>({ filter: rosterEntriesFilter })
            .then((rosterentries: IRosterEntry[]) => {
              _.forEach(rosterentries, (rosterentrie: IRosterEntry) => {
                if (!me.rosterentries.find((el: IRosterEntry) => el.id === rosterentrie.id)) {
                  me.rosterentries.push(rosterentrie);
                }
              });
            })
            .catch(console.log))
        });
      })
      .then(() => {
        Promise.all(me.resolveRosterentries).then(() => {
          this.buildCsv();
        }).catch(console.log)
      })
      .catch(console.log);
  }

  buildCsv() {
    let csvContent = "data:attachment/csv;charset=utf-8,";
    csvContent += this.cols.join(",") + "\r\n";
    this.rosterentries.forEach((rosterentry) => {
      let values = [];
      this.cols.forEach((field) => {
        values.push(rosterentry[field] || rosterentry.player[field] || "null");
      });
      csvContent += values.join(",") + "\r\n";
    });
    this.csvContent = encodeURI(csvContent);
  }

  download() {
    if (!this.csvContent) return;
    let link = document.createElement("a");
    link.href = this.csvContent;
    link.target = "_blank";
    link.download = `Roster-export_${this.selctedSeason}.csv`;
    link.click();
  }
}
