import _ from 'lodash';
import moment from 'moment';
import { IPlayer } from '../../../models/IPlayer';
import { IRosterEntry } from '../../../models/IRosterEntry';
import { ITeam } from '../../../models/ITeam';
import { IUser } from '../../../models/IUser';
// import moment from 'moment';

export class MembersUploadModal {
    static $inject = ['Upload', 'item', 'team'];
    item: IPlayer;
    team: ITeam;
    uploadedRosters: IPlayer[];
    errors: string[] = [];
    constructor(private Upload: angular.angularFileUpload.IUploadService, item: IPlayer, team: ITeam) {
        this.item = item;
        this.team = team;
    } 

    uploadRoster(file) {
        if (!file) return;
        
        let me = this;
        me.errors = [];
        me.uploadedRosters = [];
        let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
            method: 'POST',
            data: { file },
            url: 'api/files/convertcsv'
        };
        file.upload = me.Upload.upload(fileUploadConfig);
        file.upload.then(function(response) {
            _.each(response.data, function(r: any) {
                if (!r.birthday) {
                    me.errors.push('birthday required for {firstName}'.replace('{firstName}', r.firstname));
                    return;
                }
                let playerEntry = {
                        firstName: r.firstname,
                        lastName: r.lastname,
                        guardianemail: r.guardianemail,
                        birthday: moment(r.birthday, ['MM-DD-YYYY', 'MM/DD/YYYY', 'MM/DD/YY', 'MMM-DD-YY', 'MMM-DD-YYYY']).toDate(),
                        phoneNumber: r.phonenumber,
                        grade: r.grade
                } as IPlayer;

                playerEntry.guardians = [];
                for (let i = 1; i <= 2; i++) {
                    let gp = 'guardian' + i;
                    if (r[gp + 'email'] && r[gp + 'email'].trim() && r[gp + 'email'].trim().length) {
                        playerEntry.guardians.push(
                        {
                            email: r[gp + 'email'].trim(), 
                            firstName: r[gp + 'firstname'],
                            lastName: r[gp + 'lastname'],
                            cellPhone: r[gp + 'phone']
                        } as IUser);
                    }
                }
                me.uploadedRosters.push(playerEntry);
            });
        });
    } 
}
