import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

@Component({
  selector: "stStringList",
  template,
})
export class StringList {
  @Input("@")
  label: string;
  @Input("=")
  value: string[];
  newItem: string;
  selectedItem: string;
  selectedIndex: number;

  constructor() {}

  add(item: string) {
    this.value.push(item);
    this.newItem = null;
  }

  select(item: string, index: number) {
    this.selectedItem = item;
    this.selectedIndex = index;
  }

  update(item: string) {
    this.value[this.selectedIndex] = item;
    this.selectedItem = null;
    this.selectedIndex = null;
  }

  undo() {
    this.selectedItem = null;
    this.selectedIndex = null;
  }

  delete(item: string) {
    let idx = this.value.indexOf(item);
    if (idx >= 0) this.value.splice(idx, 1);
  }
}
