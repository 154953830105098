import { ILeague } from "../models/ILeague";
import { ILeagueNotification } from "../models/ILeagueNotification";
import { ILeagueSetting } from "../models/ILeagueSetting";
import { IMessage } from "../models/IMessage";
import { Injectable } from "angular-ts-decorators";
import { IService } from "restangular";
import { IHttpService } from "angular";
import { ISeason } from "../models/ISeason";
import { IRegistrationProgram } from "../models/IRegistrationProgram";
import { IFilter } from "../models/IFilter";

@Injectable("LeagueService")
export class LeagueService {
  static $inject = ["Restangular", "$http"];
  constructor(private Restangular: IService, private $http: IHttpService) { }

  getAll(): ng.IPromise<ILeague[]> {
    return this.Restangular.all("leagues").getList();
  }

  get(id: string): ng.IPromise<ILeague> {
    return this.Restangular.one("leagues", id).get();
  }

  getMessages(league: ILeague): ng.IPromise<IMessage[]> {
    return this.Restangular.one("leagues", league.id)
      .all("messages")
      .getList();
  }

  addMessage(league: ILeague, message: IMessage): ng.IPromise<IMessage> {
    return this.Restangular.one("leagues", league.id)
      .all("messages")
      .post(message);
  }

  saveMessage(league: ILeague, message: IMessage): ng.IPromise<IMessage> {
    return this.Restangular.one("leagues", league.id)
      .one("messages", message.id)
      .customPUT(message);
  }

  removeMessage(league: ILeague, message: IMessage): ng.IPromise<IMessage> {
    return this.Restangular.one("leagues", league.id)
      .one("messages", message.id)
      .remove();
  }

  notify(league: ILeague, notification: ILeagueNotification): ng.IPromise<any> {
    return this.Restangular.one("leagues", league.id)
      .all("notify")
      .customPOST(notification);
  }

  getSeasons(league: ILeague): ng.IPromise<ISeason[]> {
    return this.$http
      .get<ISeason[]>(`/api/leagues/${league.id}/seasons`)
      .then((response) => {
        return response.data;
      });
  }

  saveSettings(league: ILeague, settings: ILeagueSetting) {
    return this.$http.patch<ILeague>(`/api/leagues/${league.id}`, { leaguesettings: settings })
      .then((response) => {
        return response.data;
      });
  }

  getCurrentSeason(league: ILeague): ng.IPromise<ISeason> {
    return this.getSeasons(league).then((seasons) => {
      for (let s of seasons) {
        if (new Date(s.end.toString()) > new Date()) {
          return s;
        }
      }
    });
  }

  getRegistrationPrograms(
    league: ILeague
  ): ng.IPromise<IRegistrationProgram[]> {
    return this.$http
      .get<IRegistrationProgram[]>(
        `/api/leagues/${league.id}/registrationprograms`
      )
      .then((response) => response.data);
  }
}
