import { Component, Input, Output } from "angular-ts-decorators";
import { IComponentController } from "angular";
import { IRestResource } from "../../../models/IRestResource";
import { IVanityUrl } from "../../../models/IVanityUrl";
import { StateService } from "@uirouter/core";
import { VanityUrlService } from "../services/vanityurl.service";
import template from "./template.html";

const name = "stVanityUrl";

@Component({
  selector: "name",
  template,
})
export class VanityUrl implements IComponentController {
  static $inject = ["VanityUrlService"];
  static Name: string = name;

  @Input()
  $transition$;

  @Input()
  restResource: IRestResource;

  @Input()
  urls: any;

  /**
   *
   */
  constructor(private vanityUrlService: VanityUrlService) {}

  onSave(value: IVanityUrl) {
    const me = this;
    if (value.team) {
      value.teamId = value.team.id;
      delete value.team;
    } else if (value.organization) {
      value.organizationId = value.organization.id;
      delete value.organization;
    } else if (value.league) {
      value.leagueId = value.league.id;
      delete value.league;
    }
    me.vanityUrlService.add(value);
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
