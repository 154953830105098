import { LeagueService } from "../../../../services/LeagueService";
import { TeamService } from "../../../../services/TeamService";
import { OrganizationService } from "../../../../services/OrganizationService";
import { IOrganization } from "../../../../models/IOrganization";
import { ITeam } from "../../../../models/ITeam";
import { ILeague } from "../../../../models/ILeague";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./template.html";

interface IUrlType {
  id: number;
  type: string;
}

@Component({
  selector: "stVanityUrlAdd",
  template,
})
export class VanityUrlAdd {
  static $inject = ["OrganizationService", "TeamService", "LeagueService"];
  static Name: string = "stVanityUrlAdd";

  @Output()
  onSave: ({ value }) => void;

  organization: IOrganization;
  organizations: IOrganization[];
  team: ITeam;
  teams: ITeam[];
  league: ILeague;
  leagues: ILeague[];

  types: IUrlType[] = [
    {
      id: 1,
      type: "League",
    },
    {
      id: 2,
      type: "Team",
    },
    {
      id: 3,
      type: "Organization",
    },
  ];

  /**
   *
   */
  constructor(
    private organizationService: OrganizationService,
    private teamService: TeamService,
    private leagueService: LeagueService
  ) {}

  label(value: IUrlType): string {
    return value.type;
  }

  nameLabel(value): string {
    return value.name;
  }

  save(vanityUrl) {
    this.onSave({ value: vanityUrl });
  }

  async onChange(value: IUrlType) {
    const me = this;
    switch (value.id) {
      case 1:
        me.leagues = await me.leagueService.getAll();
        break;
      case 2:
        me.teams = await me.teamService.getAll();
        break;
      case 3:
        me.organizations = await me.organizationService.getAll();
        break;
    }
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {}

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
