import { ILeagueScope } from '../models/ILeagueScope';
import { IAgeGroup } from '../models/IAgeGroup';
import { ITeamLevel } from '../models/ITeamLevel';
import { IDivision } from '../models/IDivision';
import { IFilter } from '../models/IFilter';
import { GameCreatorController } from './GameCreatorController';
import { IGame } from '../models/IGame';
import { GameEditorController } from './GameEditorController';
import { IService, IElement } from 'restangular';
import _ from 'lodash';
import moment from 'moment';
import { IFacility } from '../models/IFacility';
import { IDateTime } from '../models/IDateTime';

export class GameManagerController {
    static $inject = ['$scope', 'Restangular', '$uibModal'];
    dataService: IElement;

    constructor(private $scope: ILeagueScope, private Restangular: IService, private $uibModal: angular.ui.bootstrap.IModalService) {
    }

    currentAgeGroup: string;
    ageGroup: IAgeGroup;
    selectedLevel: ITeamLevel;
    selectedDivision: IDivision;
    showStringDates: boolean = false;
    homeFields: IFacility[];

    refresh(ageGroupId: string) {
        this.currentAgeGroup = ageGroupId;
        this.selectedLevel = null;
        this.selectedDivision = null;
        let me = this;
        let filter: IFilter = {
            include:
                [
                    {
                        relation: 'teamlevels',
                        scope: {
                            include: [
                                {
                                    relation: 'divisions',
                                    scope: {
                                        include: [
                                            {
                                                relation: 'teamregistrations',
                                                scope: {
                                                    include: [
                                                        { 'team': 'homeFields' },
                                                        { awayGames: ['date', { homeTeam: 'team' }, { awayTeam: 'team' }] },
                                                        { homeGames: ['date', { homeTeam: 'team' }, { awayTeam: 'team' }] }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }

                ]
        };

        this.dataService = this.Restangular.one('agegroups', ageGroupId);
        this.dataService.get<IAgeGroup>({ filter })
            .then(function (ageGroup) {
                me.homeFields = [];
                _.each(ageGroup.teamlevels, function (tl) {
                    let allTeams = [];

                    _.each(tl.divisions, function (d) {
                        _.each(d.teamregistrations, function (tr) {
                            allTeams.push(tr);
                            me.homeFields.push(...tr.team.homeFields)
                        });
                    });
                    tl.allTeams = allTeams;
                });
                me.ageGroup = ageGroup;
            });
    }

    add(teamLevel: ITeamLevel, division: IDivision) {
        let me = this;
        me.$uibModal.open({
            template: require('../templates/admin/game.add.html').default,
            size: 'lg',
            resolve: {
                game() { return {}; },
                teamLevel() { return teamLevel; },
                division() { return division; }
            },
            controller: GameCreatorController,
            controllerAs: 'gamecreator'
        }).result.then(function (result: IGame) {
            let newGame: IGame = {
                homeTeamId: result.homeTeam.id,
                awayTeamId: result.awayTeam.id,
                teamlevelId: teamLevel.id,
                seasonId: me.$scope.league.currentSeason.id,
                divisionId: division.id,
                agegroupId: me.ageGroup.id,
            } as IGame;
            me.Restangular.all('games').post(newGame)
                .then(function (response: IGame) {
                    me.refresh(me.ageGroup.id);
                });
        });
    }

    edit(teamLevel: ITeamLevel, division: IDivision, game: IGame) {
        let me = this;
        me.$uibModal.open({
            template: require('../templates/admin/game.editor.html').default,
            size: 'lg',
            resolve: {
                game() { return game; },
                teamLevel() { return teamLevel; },
                division() { return division; },
                homeFields() { return me.homeFields },
            },
            controller: GameEditorController,
            controllerAs: 'gameeditor'
        }).result.then(async function (result: IGame) {
            let gameUpdate: IGame = { homeTeamId: result.homeTeam.id, awayTeamId: result.awayTeam.id } as IGame;
            if (game.dateId) {
                game.date.datetime = moment(game.date.date + ' ' + game.date.time, 'YYYY-MM-DD h:mmA').toDate();
                if (game.__uistate && game.__uistate.editing) {
                    me.Restangular.one('datetimes', game.dateId).customPATCH({ date: game.date.date, time: game.date.time, datetime: game.date.datetime, taken: true })
                        .then(function () {

                        });
                }
            } else if (game.date && game.date.date && game.date.time) {
                game.date.datetime = moment(game.date.date + ' ' + game.date.time, 'YYYY-MM-DD h:mmA').toDate();
                const newDate: IDateTime = await me.Restangular.all('datetimes').customPOST({ date: game.date.date, time: game.date.time, datetime: game.date.datetime, taken: true, facilityId: game.facility?.id } as IDateTime);
                gameUpdate.dateId = newDate.id;
            }
            me.Restangular.one('games', result.id).customPATCH(gameUpdate)
                .then(function () {

                });
        });
    }

    delete(game: IGame) {
        if (!confirm('are you sure you want to delete this game')) return;
        let me = this;
        me.Restangular.one('games', game.id).remove()
            .then(function (response) {
                if (game.date && game.date.taken) {
                    return me.Restangular.one('datetimes', game.date.id).customPATCH({ taken: false });
                }
                return true;
            })
            .then(function (success) {
                me.refresh(me.ageGroup.id);
            });
    }
}
