import {ILeagueScope} from '../models/ILeagueScope';
import {IGame} from '../models/IGame';
import {ITeamLevel} from '../models/ITeamLevel';
import {IDivision} from '../models/IDivision';
import { IService } from 'restangular';

export class GameCreatorController {
    static $inject = ['$scope', 'Restangular', '$uibModalInstance', 'game', 'teamLevel', 'division'];

    constructor(private $scope: ILeagueScope, private Restangular: IService, private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private game: IGame, private teamLevel: ITeamLevel, private division: IDivision) {
    }

    setEditing(game: IGame, editing: boolean) {
        if (!game.__uistate) game.__uistate = {};
        game.__uistate.editing = editing;
    }

    editGameDate(game: IGame) {
        this.setEditing(game, true);
    }
}
