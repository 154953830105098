import _ from 'lodash';
import { IAgeGroup } from '../models/IAgeGroup';
import { IFilter } from '../models/IFilter';
import { ILeagueScope } from '../models/ILeagueScope';
import { IService } from 'restangular';
import { ITeamLevel } from '../models/ITeamLevel';
import { StateService } from '@uirouter/angularjs';

export class TeamLevelController {
    static $inject = ['$state', '$scope', 'Restangular', '$timeout'];
    list: ITeamLevel[];
    newItem: ITeamLevel;
    selectedItem: ITeamLevel;
    ageGroups: IAgeGroup[];
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService) {
        this.list = [];
        let me = this;
        me.$scope.$watch(
            function() { return me.$scope.league.currentSeason; },
            function(newValue, oldValue) {
                if (newValue) {
                    me.refresh();
                }
            },
            true
        );
    }

    refresh() {
        let me = this;
        me.Restangular.one('seasons', me.$scope.league.currentSeason.id).getList<IAgeGroup>('agegroups')
            .then(function(result) {
                me.ageGroups = result;
                let filter: IFilter = {
                    order: 'name ASC'
                };
                _.each(me.ageGroups, function(agegroup) {
                    let agc = me.Restangular.one('agegroups', agegroup.id);
                    agc.getList('teamlevels', { filter })
                        .then(function(teamLevels) {
                            agegroup.teamlevels = teamLevels;
                        });
                });
            });
    }

    add(agegroup: IAgeGroup) {
        let me = this;
        this.Restangular.one('agegroups', agegroup.id).all('teamlevels').post({ name: 'level' })
            .then(function(level) {
                me.setEditing(level, true);
                agegroup.teamlevels.push(level);
            });
    }

    edit(model: ITeamLevel) {
        this.setEditing(model, true);
    }

    setEditing(teamLevel: ITeamLevel, editing: boolean) {
        if (!teamLevel.__uistate) teamLevel.__uistate = { editing: false };
        teamLevel.__uistate.editing = editing;
        if (editing) {
            this.$timeout(function() {
                $('input.form-control:last').focus();
                $('input.form-control:last').select();
            });
        }
    }

    delete(agegroup: IAgeGroup, model: ITeamLevel) {
        if (!confirm('are you sure you want to delete this team level?')) return;
        let me = this;
        model.remove()
            .then(function() {
                let idx = agegroup.teamlevels.indexOf(model);
                agegroup.teamlevels.splice(idx, 1);
            });
    }

    save(teamLevel: ITeamLevel) {
        let me = this;
        teamLevel.save()
            .then(function() {
                me.setEditing(teamLevel, false);
            });
    }
}
