import { IFilter } from '../models/IFilter';
import { IRosterEntry } from '../models/IRosterEntry';
import { Injectable } from 'angular-ts-decorators';
import { IEmailLog } from '../models/IEmailLog';
import { IService } from 'restangular';

@Injectable('EmailLogService')
export class EmailLogService {
    static $inject = ['Restangular'];
    constructor(private Restangular: IService) {

    }

    getAllRosterLogs(roster: IRosterEntry[]): ng.IPromise<IEmailLog[]> {
        let filter: IFilter = {
            where: {
                rosterId: {
                    inq: roster.map((entry) => entry.id)
                }
            }
        } as IFilter;
        return this.Restangular.all('emaillogs').getList({filter});
    }
}
