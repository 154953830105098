import { Component, Input } from "angular-ts-decorators";
import template from "./plancreator.html";
import { PracticePlansService } from "../praciteplans.service";
import { IPracticePlans } from "../ipracticeplans";
import _ from "lodash";
import { ISCEService } from "angular";
import { IPlans } from "../iplans";
import { ITeam } from "../../../models/ITeam";
import { StateService } from "@uirouter/angularjs";
import { IPracticePlanCategory } from "../ipracticeplancategory";

const name: string = "planCreator";

@Component({
  selector: name,
  bindings: {},
  template,
})
export class PlanCreator {
  static Name: string = name;
  static $inject: string[] = ["PracticePlansService", "$sce", "$state"];

  public newPlan: IPlans;

  public plans: IPracticePlanCategory[];

  @Input() public parentPath: string;

  @Input() public team: ITeam;
  constructor(
    private practicePlansService: PracticePlansService,
    private $sce: ISCEService,
    private $state: StateService
  ) {
    this.newPlan = {} as IPlans;
    this.newPlan.practiceplans = [];
  }

  $onInit() {
    this.practicePlansService.plansGroupedByCategory().then((plans) => {
      this.plans = plans;
    });
  }

  public keys(obj) {
    return obj ? Object.keys(obj) : [];
  }

  public addPlan(plan: IPracticePlans) {
    const found = _.find(this.newPlan.practiceplans, (p) => {
      return p.id === plan.id;
    });
    if (found) return;
    this.newPlan.practiceplans.push(plan);
  }

  public savePlan(plan: IPlans) {
    this.practicePlansService
      .savePlan(this.team, plan)
      .then((savedPlan) => {
        // return Promise.each(plan.practiceplans, (practice) => {
        //   this.practicePlansService.addPracticeToPlan(savedPlan, practice);
        // });
      })
      .then(() => {
        this.$state.go(this.parentPath);
      });
  }

  public removePlan(plan: IPracticePlans) {
    const idx = this.newPlan.practiceplans.indexOf(plan);
    if (idx >= 0) this.newPlan.practiceplans.splice(idx, 1);
  }
}
