import { Injectable } from 'angular-ts-decorators';
import { IFilter } from '../models/IFilter';
import { IHttpResponse, IHttpService } from 'angular';
import { ISKU } from '../models/ISKU';
import { IPrice } from '../models/IPrice';
import { String } from 'aws-sdk/clients/cloudhsm';

@Injectable('PaymentService')
export class PaymentService {
    static $inject = ['$http'];
    constructor(private $http: IHttpService) {
    }

    getPaymentSession(sku: string, fk: string, url: string) {
        return this.$http.post<any>(`api/payments/session?sku=${sku}&fk=${fk}&url=${url}`, {}).then(this.unwrapResponse);
    }

    getSkuInformation(sku: string) {
        return this.$http.get<IPrice>(`api/payments/sku?sku=${sku}`).then(this.unwrapResponse);
    }

    getKey() {
        return this.$http.get<{ stripe_key: String }>('api/payments/key').then(this.unwrapResponse);
    }

    private unwrapResponse<T>(response: IHttpResponse<T>): T {
        return response && response.data as T;
    }

}
