import { Component, Input } from "angular-ts-decorators";
import { Wizard } from "./wizard";
import template from "./previous.html";

@Component({
  selector: "previousButton",
  require: {
    wizard: "^wizard",
  },
  template,
})
export class PreviousButton {
  @Input("@") public label: string;

  private wizard: Wizard;
  $onInit() {}

  click() {
    this.wizard.previousStep();
  }
}
