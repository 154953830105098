import { ILeagueScope } from "../models/ILeagueScope";
import { ISeason } from "../models/ISeason";
import { IService, ICollection } from "restangular";
import { StateService } from "@uirouter/angularjs";
import moment from "moment";

export class SeasonsController {
  static $inject = ["$state", "$scope", "Restangular"];
  newSeason: ISeason;
  selectedSeason: ISeason;
  seasons: ICollection;
  constructor(
    private $state: StateService,
    private $scope: ILeagueScope,
    private Restangular: IService
  ) {
    this.newSeason = {} as ISeason;
    this.seasons = Restangular.one("leagues", $scope.league.id).all("seasons");
  }

  editSeason(season: ISeason) {
    this.selectedSeason = season;
    this.selectedSeason.start = season.start
      ? moment(season.start).toDate()
      : null;
    this.selectedSeason.end = season.end ? moment(season.end).toDate() : null;
    this.selectedSeason.registrationOpen = season.registrationOpen
      ? moment(season.registrationOpen).toDate()
      : null;
    this.selectedSeason.registrationClose = season.registrationClose
      ? moment(season.registrationClose).toDate()
      : null;

    this.$state.go("league.seasons.edit", { seasonId: season.id });
  }

  deleteSeason(season: ISeason) {
    let me = this;
    season.remove().then(() => {
      let idx = me.$scope.league.seasons.indexOf(season);
      if (idx >= 0) me.$scope.league.seasons.splice(idx, 1);
    });
  }

  saveSeason() {
    let state = this.$state;
    this.selectedSeason.save().then(function() {
      state.go("league.seasons");
    });
  }

  createSeason() {
    let me = this;
    this.seasons.post(this.newSeason).then(function(newObj) {
      me.newSeason = {} as ISeason;
      me.$scope.league.refreshSeasons();
      me.$state.go("league.seasons");
    });
  }
}
