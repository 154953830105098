import { IStandingsAlgorithm } from './IStandingsAlgorithm';
import { StandingsAlgorithmWLHTHPA } from './StandingsAlgorithmWLHTHPA';
import { StandingsAlgorithmWTHTH2OAPA } from './StandingsAlgorithmWTHTH20APA';
import { DynamicStandingsAlgorithm } from './DynamicStandingsAlgorithm';
import { ISortingRules, ISortingRule } from '../models/ISortingRules';
import { Injectable } from 'angular-ts-decorators';

export const enum SortingType {
    WLHTHPA = 1,
    WL,
    WTHTH20APA
}

@Injectable('SortingAlgorithmFactory')
export class SortingAlgorithmFactory {
    public createSortingAlgorithm(type: SortingType): IStandingsAlgorithm {
        switch (type) {
            case SortingType.WLHTHPA:
                return new StandingsAlgorithmWLHTHPA();
            case SortingType.WL:
                return null;
            case SortingType.WTHTH20APA:
                return new StandingsAlgorithmWTHTH2OAPA();
        }
    }

    public createDyanmicSortingAlgorithm(sortingRules: ISortingRules): IStandingsAlgorithm {
        return new DynamicStandingsAlgorithm(sortingRules);
    }
}
