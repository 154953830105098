import {IACL} from '../models/IACL';
import {IRootStateService} from '../models/IRootStateService';
import {ILeagueScope} from '../models/ILeagueScope';
import {IFilter} from '../models/IFilter';
import {StateService} from '@uirouter/angularjs';
import {IService, ICollection} from 'restangular';

export class PermissionsController {
    static $inject = ['$rootScope', '$state', '$scope', 'Restangular', '$timeout'];
    dataService: ICollection;
    acls: IACL[];
    selected: IACL;
    newACL: IACL = {} as IACL;
    constructor(private $rootScope: IRootStateService, private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService) {
        let me = this;
        me.dataService = Restangular.all('ACLS');
        me.refresh();
    }
    refresh() {
        let me = this;
        let filter: IFilter = { order: ['model ASC', 'principalId ASC'] };
        this.dataService.getList<IACL>({ filter })
            .then(function(response) {
                me.acls = response;
            });
        if (this.$state.params.aclId) {
            this.Restangular.one('ACLS', this.$state.params.aclId).get()
                .then(function(acl) {
                    me.selected = acl;
                });
        }
    }

    edit(acl: IACL) {
        this.selected = acl;
        this.$state.go('league.admin.permissions.edit', { aclId: acl.id });
    }

    add() {
        let me = this;
        this.dataService.post(this.newACL)
            .then(function(acl) {
                me.acls.push(acl);
                me.newACL = {} as IACL;
                me.$state.go('league.admin.permissions');
            });
    }

    delete(acl: IACL) {
        if (!confirm('are you sure you want to delete this acl?')) return;
        let me = this;
        acl.remove()
            .then(function() {
                let idx = me.acls.indexOf(acl);
                me.acls.splice(idx, 1);
            });
    }

    save(acl: IACL) {
        let me = this;
        acl.patch()
            .then(function(response) {
                me.$state.go('league.admin.permissions');
            });
    }
}
