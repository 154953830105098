import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { IDateTime } from "../../../../models/IDateTime";
import { IFacility } from "../../../../models/IFacility";
import { IGame } from "../../../../models/IGame";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import { TeamService } from "../../../../services/TeamService";
import template from './index.html';
import moment from 'moment';
import { GameService } from "../../../../services/GameService";

@Component({
    selector: 'stScheduleRoundRobin',
    template
})
export class ScheduleRoundRobin implements IComponentController {
    static $inject = ['TeamService', 'TeamRegistrationService', 'GameService'];

    public facilities: IFacility[];

    @Input()
    registration: ITeamRegistration;

    @Input()
    roundRobin: IRoundRobin;

    public saving = false;

    public commonDate: string;

    constructor(private teamService: TeamService, private registrationService: TeamRegistrationService, private gameService: GameService) {

    }

    async $onInit() {
        this.facilities = await this.teamService.getFacilities(this.registration.team);
        this.roundRobin.games?.forEach((game) => {
            if (!game.date) {
                game.date = {} as IDateTime;
            }
        })
    }

    changeAllFacilities(facility: IFacility) {
        this.roundRobin.games?.forEach((game) => {
            game.date.facilityId = facility.id;
            game.facilityId = facility.id;
            game.facility = facility;
        })
    }

    assignAllDates() {
        this.roundRobin.games?.forEach((game: IGame) => {
            game.date.date = this.commonDate;
        })
    }

    facilityChange(facility: IFacility, game: IGame) {
        game.facilityId = facility.id;
        game.date.facilityId = facility.id;
    }

    async save() {
        this.saving = true;
        for await (let game of this.roundRobin.games as IGame[]) {
            if (!game.dateId) {
                game.date.taken = true;
                game.date.datetime = moment(
                    game.date.date + " " + game.date.time, "YYYY-MM-DD h:mmA"
                ).toDate();
                game.date = await this.registrationService.addHomeDate(this.registration, game.date);
                game.dateId = game.date.id;
            } else {
                await this.registrationService.updateHomeDate(this.registration, game.date);
            }
            await this.gameService.updateGame(game);
            this.saving = false;
        }
    }
}