import _ from "lodash";
import { IAgeGroup } from "../models/IAgeGroup";
import { ICollection, IElement, IService } from "restangular";
import { IFilter } from "../models/IFilter";
import { ILeagueScope } from "../models/ILeagueScope";
import { ITeam } from "../models/ITeam";
import { ITeamRegistration } from "../models/ITeamRegistration";
import { StateService } from "@uirouter/angularjs";

export class TeamController {
  static $inject = ["$state", "$scope", "Restangular"];
  dataService: ICollection;
  teamService: IElement;
  list: ITeamRegistration[];
  newTeam: ITeam;
  selectedTeam: ITeamRegistration;
  groupedTeams: _.Dictionary<ITeamRegistration[]>;
  currentAgeGroup: IAgeGroup;
  constructor(
    private $state: StateService,
    private $scope: ILeagueScope,
    private Restangular: IService
  ) {
    this.newTeam = { homeFields: [] } as ITeam;
    if (this.$state.is("league.teams.edit")) {
      this.loadTeam(this.$state.params.teamId);
    }
    let me = this;
    me.$scope.$watch(
      function() {
        return me.$scope.league.currentSeason;
      },
      function(newValue, oldValue) {
        if (newValue) {
          me.refresh(me.$scope.league.selectedAgeGroup || null);
        }
      },
      true
    );
  }

  refresh(ageGroup?: IAgeGroup) {
    this.$scope.league.setAgeGroup(ageGroup);
    let me = this;
    let filter: IFilter = {
      fields: {
        id: true,
        teamId: true,
        ageGroupId: true,
        teamlevelId: true,
        earlyScheduling: true
      },
      include: [
        {
          relation: "team",
          scope: {
            fields: ["id", "name", "urlKey"]
          }
        },
        {
          relation: "ageGroup",
          scope: {
            fields: ["id", "name", "schedulingOpen", "sortOrder"]
          }
        },
        {
          relation: "teamLevel",
          scope: {
            fields: ["id", "name", "sortOrder"]
          }
        }
      ]
    };
    if (me.$scope.league.selectedAgeGroup) {
      filter.where = { ageGroupId: me.$scope.league.selectedAgeGroup.id };
    }
    this.dataService = this.Restangular.one(
      "seasons",
      me.$scope.league.currentSeason.id
    ).all("teamregistrations");
    this.dataService
      .getList<ITeamRegistration>({ filter })
      .then(function(teams) {
        if (teams) {
          me.groupedTeams = _.groupBy(
            _.orderBy(teams, ["ageGroup.sortOrder", "ageGroup.name"]),
            function(team: ITeamRegistration) {
              return team.ageGroup.name;
            }
          );
          _.each(me.groupedTeams, function(value: any, key, collection) {
            collection[key].groupedTeamLevels = _.groupBy(
              _.orderBy(value, ["teamLevel.sortOrder", "teamLevel.name"]),
              function(team: ITeamRegistration) {
                return team.teamLevel && team.teamLevel.name;
              }
            );
          });
          me.list = teams;
        } else {
          me.list = [];
        }
      });
  }

  loadTeam(id: string) {
    let me = this;
    let filter: IFilter = {
      include: [{ team: "manager" }, { ageGroup: "teamlevels" }, "teamLevel"]
    };
    this.teamService = this.Restangular.one("teamregistrations", id);
    this.teamService.get<ITeamRegistration>({ filter }).then(function(team) {
      me.selectedTeam = team;
      me.$state.go("league.teams.edit", { teamId: team.id });
    });
  }

  edit(team: ITeamRegistration) {
    this.loadTeam(team.id);
  }

  earlyScheduling(team: ITeamRegistration) {
    team.customPUT({ earlyScheduling: !team.earlyScheduling }).then(function() {
      team.earlyScheduling = !team.earlyScheduling;
    });
  }

  delete(registration: ITeamRegistration) {
    if (!confirm("are you sure you want to delete this team and registration?"))
      return;
    let me = this;
    me.Restangular.one("teams", registration.team.id)
      .remove()
      .then(function(result) {
        registration.remove().then(function(deletedTeam) {
          me.refresh(me.currentAgeGroup);
        });
      });
  }

  unregister(registration: ITeamRegistration) {
    if (!confirm("are you sure you want to unregister this team?")) return;
    let me = this;
    registration.remove().then(function(result) {
      me.refresh(me.currentAgeGroup);
    });
  }

  saveTeam(team: ITeam) {
    let me = this;
    let svc = this.Restangular.one("teams", team.id);
    svc.customPATCH(team).then(function(savedTeam) {
      me.refresh(me.currentAgeGroup);
      me.$state.go("league.teams");
    });
  }

  save(teamRegistration: ITeamRegistration) {
    let me = this;
    let svc = this.Restangular.one("teamregistrations", teamRegistration.id);
    svc
      .customPATCH({
        ageGroupId: teamRegistration.ageGroup.id,
        teamlevelId: teamRegistration.teamLevel.id,
        divisionPreference: teamRegistration.divisionPreference
      })
      .then(function() {
        me.refresh(me.currentAgeGroup);
        me.$state.go("league.teams");
      });
  }

  keys(obj) {
    return obj ? Object.keys(obj) : [];
  }
}
