import { IElement } from 'restangular';
import { IFilter } from '../models/IFilter';
import { ILeague } from '../models/ILeague';
import { ILoginResponse } from '../models/ILoginResponse';
import { Injectable } from 'angular-ts-decorators';
import { IPlayer } from '../models/IPlayer';
import { IRoleInfo } from '../models/IRoleInfo';
import { IService } from 'restangular';
import { IUser } from '../models/IUser';
import { IUserPreferences } from '../models/IUserPreferences';
import { IWindowService, IHttpService, IHttpPromiseCallbackArg } from 'angular';

@Injectable('UserService')
export class UserService {
    static $inject: string[] = ['Restangular', '$window', '$http'];

    users: IUser[];
    constructor(private Restangular: IService, private $window: IWindowService, private $http: IHttpService) {
    }

    private unwrapResponse<T>(response: IHttpPromiseCallbackArg<T>): T {
        return response && response.data as T;
    }

    me(): ng.IPromise<IUser> {
        return this.$http.get<IUser>('/api/users/me').then(this.unwrapResponse);
    }

    accessToken(): ng.IPromise<ILoginResponse> {
        return this.$http.get<ILoginResponse>('/api/users/me/accessToken').then(this.unwrapResponse);
    }

    resetPassword(email: string): ng.IPromise<any> {
        return this.Restangular.all('users').all('reset').customPOST({email, hostname: this.$window.location.host});
    }

    login(email: string, password: string): ng.IPromise<ILoginResponse> {
        return this.Restangular.all('users').customPOST({ email, password }, 'login');
    }

    logout(): ng.IPromise<any> {
        return this.Restangular.all('users').customPOST(null, 'logout');
    }

    list(): ng.IPromise<IUser[]> {
        return this.Restangular.all('users').getList<IUser>();
    }

    get(id: string): ng.IPromise<IUser> {
        const filter: IFilter = {
            include: ['photo', 'preferences']
        } as IFilter;
        return this.Restangular.one('users', id).get({ filter });
    }

    add(user: IUser): ng.IPromise<IUser> {
        return this.Restangular.all('users').post(user);
    }

    save(user: IUser): ng.IPromise<IUser> {
        return this.Restangular.one('users', user.id).customPATCH(user);
    }

    delete(user: IUser): ng.IPromise<IUser> {
        return this.Restangular.one('users', user.id).remove();
    }

    roles(user: IUser, leagueData?: ILeague): ng.IPromise<IRoleInfo> {
        const me = this;
        return me.Restangular.one('users', user.id).customGET('roles')
            .then(function(roles) {
                if (roles.roles) {
                    roles.isAuthenticated = roles.roles.indexOf('$authenticated') > -1;
                    roles.isLeagueAdmin = roles.isAdmin || (leagueData && roles.roles.indexOf(leagueData.adminrole) > -1);
                    roles.isOfficial = roles.isAdmin || roles.isLeagueAdmin || (leagueData && roles.roles.indexOf(leagueData.officialrole) > -1);
                    roles.isAgeGroupDirector = roles.isAdmin || roles.isLeagueAdmin || (leagueData && roles.roles.indexOf(leagueData.adgrole) > -1);
                }
                return roles;
            });
    }

    register(email: string, password: string, returnUrl?: string): ng.IPromise<IUser> {
        const me = this;
        return me.Restangular.all('users').all('register').customPOST({ email, password, returnUrl });
    }

    getPreferences(user: IUser): ng.IPromise<IUserPreferences> {
        return this.Restangular.one('users', user.id).customGET('preferences');
    }

    addPreferences(user: IUser, preferences: IUserPreferences): ng.IPromise<IUserPreferences> {
        return this.Restangular.one('users', user.id).customPOST(preferences, 'preferences');
    }

    updatePreferences(user: IUser, preferences: IUserPreferences): ng.IPromise<IUserPreferences> {
        return this.Restangular.one('users', user.id).customPUT(preferences, 'preferences');
    }

    getPlayers(user: IUser): ng.IPromise<IPlayer[]> {
        return this.Restangular.one('users', user.id).all('players').getList();
    }

    getPlayersIncludeAvailability(user: IUser, eventId: string): ng.IPromise<IPlayer[]> {
        const filter: IFilter = {
            include: [{
                relation: 'availability',
                scope: {
                    where: {
                        eventId
                    }
                }
            }]
        } as IFilter;
        return this.Restangular.one('users', user.id).all('players').getList({filter});
    }

    getTeams(user: IUser): ng.IPromise<IUser> {
        const filter: IFilter = {
            include: [
                {
                    relation: 'positions',
                    scope: {
                        fields: ['id', 'userId', 'teamId', 'positionType'],
                        include: {
                            relation: 'team',
                            scope: {
                                fields: ['id', 'name', 'urlKey', 'leagueId'],
                                include: {
                                    relation: 'registrations',
                                    scope: {
                                        fields: ['id', 'seasonId', 'agegroupId', 'divisionId', 'teamlevelId'],
                                        include: ['ageGroup', 'teamLevel', 'division']
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    relation: 'teams',
                    scope: {
                        fields: ['id', 'name', 'urlKey', 'leagueId'],
                        include: {
                            relation: 'registrations',
                            scope: {
                                fields: ['id', 'seasonId', 'agegroupId', 'divisionId', 'teamlevelId'],
                                include: ['ageGroup', 'teamLevel', 'division']
                            }
                        }
                    }
                }
            ]
        };
        return this.Restangular.one('users', user.id).get({ filter });
    }
}
