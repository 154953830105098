import { NgModule } from "angular-ts-decorators";
import { PracticePlansModule } from "./modules/practiceplans/practiceplans.module";

@NgModule({
  id: "team-sites",
  declarations: [],
  imports: [PracticePlansModule],
  providers: []
})
export class TeamSites {}
