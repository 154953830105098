import template from "./template.html";
import { IPlayer } from "../../../models/IPlayer";
import { IUser } from "../../../models/IUser";
import { ITeam } from "../../../models/ITeam";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IEventAvailability } from "../../../models/IEventAvailability";
import { UserService } from "../../../services/UserService";
import { TeamService } from "../../../services/TeamService";
import { PermissionsService } from "../../../services/PermissionsService";
import { AvailabilityService } from "../../../services/AvailabilityService";
import { Component, Input, Output } from "angular-ts-decorators";
import _ from "lodash";

@Component({
  selector: "stAvailability",
  template,
})
export class AvailabilityComponent {
  static $inject = [
    "UserService",
    "TeamService",
    "AvailabilityService",
    "PermissionsService",
  ];
  @Input() eventId: string;
  @Input() team: ITeam;
  @Input() user: IUser;
  @Input() roles: IRoleInfo;
  myPlayers: IPlayer[] = [];

  /**
   * Creates an instance of AvailabilityComponent.
   * @param {UserService} userService
   * @param {TeamService} teamService
   * @param {AvailabilityService} availabilityService
   *
   * @memberOf AvailabilityComponent
   */
  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private availabilityService: AvailabilityService,
    private permissionService: PermissionsService
  ) {}

  /**
   * event handler when any bound property changes
   */
  async $onChanges(changesObj) {
    let me = this;
    if (me.team && me.roles && me.user) {
      if (me.permissionService.isManager(me.roles, me.team, me.user)) {
        let players = await me.teamService.getMembersWithAvailability(
          me.team,
          me.eventId
        );
        _.each(players, (player) => {
          me.myPlayers.push(player);
        });
      } else {
        let players = await me.userService.getPlayersIncludeAvailability(
          { id: me.user.id } as IUser,
          me.eventId
        );
        _.each(players, async (player) => {
          try {
            let memberExists = await me.teamService.memberExists(
              { id: me.team.id } as ITeam,
              player
            );
            me.myPlayers.push(player);
          } catch (e) {
            console.error(e);
          }
        });
      }
    } else {
      me.myPlayers = [];
    }
  }

  async setAvailable(player: IPlayer, available: boolean) {
    let me = this;
    try {
      if (player.availability && player.availability.length) {
        player.availability[0].available = available;
        let response = await me.availabilityService.update(
          player.availability[0]
        );
      } else {
        let response = await me.availabilityService.add({
          playerId: player.id,
          eventId: me.eventId,
          available,
        } as IEventAvailability);
        player.availability.push(response);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
