/* tslint:disable-next-line:interface-name */
interface Date {
    addHours: (int) => Date;
    addMinutes: (int) => Date;
    clone: () => Date;
}

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000)); 
    return this;   
};

Date.prototype.addMinutes = function(m) {
    this.setTime(this.getTime() + (m * 60 * 1000));
    return this;
};

Date.prototype.clone = function() {
    return new Date(this.getTime());
};
