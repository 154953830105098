import { Component, Input, Output } from "angular-ts-decorators";
import { ICarousel } from "../../models/ICarousel";
import { IComponentController } from "angular";
import { ITeam } from "../../models/ITeam";
import { TeamService } from "../../services/TeamService";
import template from "./CarouselManager.template.html";

@Component({
  selector: "stCarouselManager",
  template,
})
export class CarouselManagerComponent implements IComponentController {
  static $inject: string[] = ["TeamService", "$timeout", "Upload"];
  @Input()
  team: ITeam;
  sliderInterval: number = 5000;
  newCarousel: ICarousel;

  constructor(
    private teamService: TeamService,
    private $timeout: ng.ITimeoutService,
    private Upload: angular.angularFileUpload.IUploadService
  ) {
    this.newCarousel = {} as ICarousel;
  }

  $onInit() {}

  uploadPhoto(file, destination: ICarousel) {
    if (!file) {
      return;
    }
    const me = this;

    const fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
      method: "POST",
      data: { file },
      url: "/api/files/upload",
    };
    file.upload = me.Upload.upload(fileUploadConfig);
    file.upload.then(function (response) {
      me.$timeout(function () {
        destination.image = response.data;
        destination.imageId = response.data.id;
      });
    });
  }

  setSlideEditing(carousel: ICarousel, editing: boolean) {
    if (!carousel.__uistate) {
      carousel.__uistate = {};
    }
    carousel.__uistate.editing = editing;
  }

  editSlide(carousel: ICarousel) {
    this.setSlideEditing(carousel, true);
  }

  saveSlide(team: ITeam, carousel: ICarousel) {
    const me = this;
    me.teamService.updateCarousel(team, carousel).then(() => {
      me.setSlideEditing(carousel, false);
    });
  }

  addSlide(team: ITeam, carousel: ICarousel) {
    const me = this;
    me.teamService.addCarousel(team, carousel);
  }

  deleteSlide(team: ITeam, carousel: ICarousel) {
    const me = this;
    me.teamService.removeCarousel(team, carousel);
  }
}
