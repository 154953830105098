import { Component } from "angular-ts-decorators";
import { IAgeGroup } from "../../models/IAgeGroup";
import { SeasonService } from "../../services/SeasonService";
import { LeagueValueSelector, template } from "../base/LeagueValueSelector";

@Component({
    selector: 'stAgeGroupSelector',
    template
})
export class AgeGroupSelector extends LeagueValueSelector<IAgeGroup> {
    static $inject: string[] = ["SeasonService"];

    constructor(private seasonService: SeasonService) {
        super("age group", "select an age group", "seasonId");
    }

    label(value: IAgeGroup) {
        return value && value.name;
    }

    async load(seasonId: string) {
        this.values = await this.seasonService.getAgeGroups(seasonId);
        this.values.forEach((ageGroup) => {
            if (ageGroup?.id === this.ageGroupId) {
                this.selected = ageGroup;
            }
        })
    }
}