import { ITeam } from "../../../models/ITeam";
import { IGame } from "../../../models/IGame";
import { IRound } from "../../../models/IRound";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ITeamPromise } from "../../../models/ITeamPromise";
import { StandingsService } from "../../../services/StandingsService";
import { IScheduleConfig } from "../../../models/IScheduleConfig";
import { SchedulingService } from "../../../services/SchedulingService";
import { IFlight } from "../../../models/IFlight";
import { ILeagueScope } from "../../../models/ILeagueScope";
import { ITournament } from "../../../models/ITournament";
import { IDateTime } from "../../../models/IDateTime";
import { IFacility } from "../../../models/IFacility";
import { IAvailability } from "../../../models/IAvailability";
import { IFilter } from "../../../models/IFilter";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IUser } from "../../../models/IUser";
import { GameEditorService } from "../../../services/GameEditorService";
import { PermissionsService } from "../../../services/PermissionsService";
import {
  SortingAlgorithmFactory,
  SortingType,
} from "../../../services/SortingAlgorithmFactory";
import { TournamentsService } from "../../../services/TournamentsService";
import { IService } from "restangular";
import _ from "lodash";
import moment from "moment";
import template from "./template.html";

import { Component, Input, Output } from "angular-ts-decorators";
@Component({
  selector: "stFlightsSingleElimination",
  template,
})
export class FlightsSingleElimination {
  static $inject = [
    "SchedulingService",
    "StandingsService",
    "Restangular",
    "$scope",
    "$uibModal",
    "GameEditorService",
    "PermissionsService",
    "SortingAlgorithmFactory",
    "TournamentsService",
  ];
  @Input()
  tournament: ITournament;
  @Input()
  teams: ITeamRegistration[];
  @Input("@")
  leagueId: number;
  @Input("@")
  runMode: string;
  @Input()
  roleInfo: IRoleInfo;
  @Input()
  userInfo: IUser;
  @Output()
  onSave: () => {};

  localTeams: ITeamRegistration[];
  facilities: IFacility[];
  newFacility: IFacility = {} as IFacility;
  selectedFacility: IFacility;
  newAvailability: IAvailability = {} as IAvailability;

  constructor(
    private schedulingService: SchedulingService,
    private standingsService: StandingsService,
    private Restangular: IService,
    private $scope: ILeagueScope,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private gameEditorService: GameEditorService,
    private permissionsService: PermissionsService,
    private sortingAlgorithmFactory: SortingAlgorithmFactory,
    private tournamentService: TournamentsService
  ) {}

  addFlight() {
    this.tournament.flights.push({
      teamregistrations: [],
      name: "flight " + (this.tournament.flights.length + 1),
      games: [],
      flight_number: this.tournament.flights.length + 1,
    } as IFlight);
  }

  randomAssign() {
    let t = _.shuffle(
      _.remove(this.localTeams, function () {
        return true;
      })
    );
    let max = this.tournament.flights.length;
    let c = Math.floor(t.length / max);
    let parts = _.chunk(t, c);
    if (parts.length > max) {
      let teamsToDistribute = parts.pop();
      for (let i = 0; i < teamsToDistribute.length; i++) {
        parts[i].push(teamsToDistribute[i]);
      }
    }
    for (let i = 0; i < max; i++) {
      this.tournament.flights[i].teamregistrations.push.apply(
        this.tournament.flights[i].teamregistrations,
        parts[i]
      );
    }
  }

  removeFlight(flight: IFlight) {
    let t = _.remove(flight.teamregistrations, function () {
      return true;
    });
    this.localTeams.push.apply(this.localTeams, t);
    let idx = this.tournament.flights.indexOf(flight);
    this.tournament.flights.splice(idx, 1);
  }

  matches: IGame[];
  runSchedule() {
    let me = this;
    _.each(me.tournament.flights, function (flight: IFlight, index: number) {
      flight.games = me.schedulingService.createNumberOfGames(
        flight.teamregistrations,
        flight.gamesEach
      );
      let teamPromises: ITeamRegistration[] = [];
      for (let i = 1; i <= flight.bracketTeams; i++) {
        teamPromises.push({
          awayGames: [],
          homeGames: [],
          promise_name: "seed #" + i,
          promise_type: "flight",
          flight_index: index,
          seed: i,
        } as ITeamRegistration);
      }
      flight.rounds = me.schedulingService.buildBracket(
        teamPromises.length,
        teamPromises
      );
    });
    if (me.tournament.includeChampionship) {
      me.tournament.championship = [];
      let teamPromises: ITeamRegistration[] = [];
      _.each(me.tournament.flights, function (flight, index) {
        teamPromises.push({
          awayGames: [],
          homeGames: [],
          promise_name: flight.name + " winner",
          promise_type: "bracket_champion",
          flight_index: index,
          gameId: "",
        } as ITeamRegistration);
      });
      me.tournament.championship = me.schedulingService.buildBracket(
        teamPromises.length,
        teamPromises
      );
    }
  }

  onGameChange() {
    this.resolvePromises();
  }

  canEnterScore(game: IGame) {
    let me = this;
    return (
      me.permissionsService.isScoreKeeper(
        me.roleInfo,
        game.awayTeam && game.awayTeam.team,
        me.userInfo
      ) ||
      me.permissionsService.isScoreKeeper(
        me.roleInfo,
        game.homeTeam && game.homeTeam.team,
        me.userInfo
      )
    );
  }

  onDropFlight(event, drag: ITeamRegistration, drop: IFlight) {
    drop.teamregistrations.push(drag);
  }

  onDropTeam(event, drag: ITeamRegistration, drop: ITeamRegistration[]) {
    drop.push(drag);
  }

  onAssignTeam(event, index: number, source: ITeamRegistration[]) {
    source.splice(index, 1);
  }

  onTeamUnassign(event, index: number, source: IFlight) {
    source.teamregistrations.splice(index, 1);
  }

  editGame(game: IGame) {
    let me = this;
    me.gameEditorService.editLocation(game, me.facilities);
  }

  enterScore(game: IGame) {
    let me = this;
    me.gameEditorService.scoreGame(game).then(function (result) {
      me.resolvePromises();
    });
  }

  saveGame(game: IGame) {
    if (game && game.id) {
      let me = this;
      me.Restangular.one("games", game.id)
        .customPATCH({
          homeTeamScore: game.homeTeamScore,
          awayTeamScore: game.awayTeamScore,
        })
        .then(function (result) {
          me.resolvePromises();
        });
    }
  }

  resolvePromises() {
    let me = this;
    me.tournamentService.resolvePromises(me.tournament);
  }

  sortData() {
    let me = this;
    me.tournament.flights = _.sortBy(me.tournament.flights, "flight_number");
    _.each(me.tournament.flights, function (flight: IFlight) {
      flight.rounds = _.sortBy(flight.rounds, "roundNumber");
      _.each(flight.rounds, function (round, roundIndex) {
        round.games = _.sortBy(round.games, "game_number");
      });
    });
    me.tournament.championship = _.sortBy(
      me.tournament.championship,
      "roundNumber"
    );
    _.each(me.tournament.championship, function (round, roundIndex) {
      round.games = _.sortBy(round.games, "game_number");
    });
  }

  assignGames() {
    let me = this;
    _.each(me.tournament.flights, function (flight: IFlight) {
      _.each(
        flight.teamregistrations,
        function (teamregistration: ITeamRegistration) {
          teamregistration.awayGames = _.filter(
            flight.games,
            function (game: IGame) {
              return game.awayTeamId === teamregistration.id;
            }
          );
          teamregistration.homeGames = _.filter(
            flight.games,
            function (game: IGame) {
              return game.homeTeamId === teamregistration.id;
            }
          );
        }
      );
    });
  }

  availableSlots: IDateTime[];
  gameTime: number = 45;
  calculateFacilityAvailability(facilities: IFacility[]) {
    let me = this;
    me.availableSlots = [];
    _.each(facilities, function (facility: IFacility) {
      facility.__uistate = facility.__uistate || { include: true };
      if (!facility.__uistate || !facility.__uistate.include) {
        return true;
      }
      facility.slots = [];
      _.each(facility.availability, function (avail: IAvailability) {
        avail.startdate = moment(avail.startdate).toDate();
        avail.enddate = moment(avail.enddate).toDate();
        let diffMs = (avail.enddate as any) - (avail.startdate as any);
        let diffMins = Math.round(diffMs / 1000 / 60);
        let slots = Math.floor(diffMins / me.gameTime);
        for (let i = 0; i < diffMins; i += me.gameTime) {
          let newSlot = {
            datetime: me.addMinutes(avail.startdate, i),
            facility,
          } as IDateTime;

          let alreadyTaken = _.find(facility.dates, function (date: IDateTime) {
            date.datetime = moment(date.datetime).toDate();
            let endRange = me.addMinutes(date.datetime, me.gameTime);
            return (
              newSlot.datetime >= date.datetime && newSlot.datetime < endRange
            );
          });
          if (alreadyTaken) {
            newSlot.taken = true;
            facility.slots.push(newSlot);
          } else {
            me.availableSlots.push(newSlot);
            facility.slots.push(newSlot);
          }
        }
      });
    });
    me.availableSlots = _.sortBy(me.availableSlots, function (slot) {
      return slot.datetime;
    });
  }

  getAvailability(tournament: ITournament) {
    let me = this;
    _.each(tournament.flights, function (flight: IFlight) {
      _.each(flight.games, function (game: IGame) {
        if (
          (game.homeTeam && game.homeTeam.byeTeam) ||
          (game.awayTeam && game.awayTeam.byeTeam)
        )
          return true;
        let slotIndex = me.findFirstAvailableTime(
          flight,
          me.availableSlots,
          game,
          me.gameTime
        );
        let slot = me.availableSlots.splice(slotIndex, 1);
        game.date = slot && slot[0];
        me.setTaken(game.date);
      });
    });
    _.each(tournament.flights, function (flight: IFlight) {
      _.each(flight.rounds, function (round: IRound) {
        _.each(round.games, function (game: IGame) {
          if (
            (game.homeTeam && game.homeTeam.byeTeam) ||
            (game.awayTeam && game.awayTeam.byeTeam)
          )
            return true;
          let slotIndex = me.findFirstAvailableBracketTime(
            flight,
            round,
            me.availableSlots,
            game,
            me.gameTime
          );
          let slot = me.availableSlots.splice(slotIndex, 1);
          game.date = slot && slot[0];
          me.setTaken(game.date);
        });
      });
    });
    _.each(tournament.championship, function (round: IRound) {
      _.each(round.games, function (game: IGame) {
        if (
          (game.homeTeam && game.homeTeam.byeTeam) ||
          (game.awayTeam && game.awayTeam.byeTeam)
        )
          return true;
        let slotIndex = me.findFirstAvailableChampionshipTime(
          tournament,
          round,
          me.availableSlots,
          game,
          me.gameTime
        );
        let slot = me.availableSlots.splice(slotIndex, 1);
        game.date = slot && slot[0];
        me.setTaken(game.date);
      });
    });
  }

  setTaken(date: IDateTime) {
    if (!date) return;
    date.taken = true;
  }

  findFirstAvailableBracketTime(
    flight: IFlight,
    round: IRound,
    availableSlots: IDateTime[],
    game: IGame,
    rest: number
  ): number {
    let me = this;
    return _.findIndex(availableSlots, function (slot: IDateTime) {
      if (round.roundNumber === 1) {
        let lastGame = me.findLastGameTime(flight.games);
        let finalDate = me.addMinutes(lastGame && lastGame.datetime, rest);
        return slot.datetime > finalDate;
      } else {
        let previousGames = [
          flight.rounds[round.roundNumber - 2].games[
            (game.game_number - 1) * 2
          ],
          flight.rounds[round.roundNumber - 2].games[
            (game.game_number - 1) * 2 + 1
          ],
        ];
        let lastGame = me.findLastGameTime(
          _.concat(previousGames, flight.games)
        );
        let finalDate = me.addMinutes(lastGame && lastGame.datetime, rest);
        return slot.datetime > finalDate;
      }
    });
  }

  findFirstAvailableChampionshipTime(
    tournament: ITournament,
    round: IRound,
    availableSlots: IDateTime[],
    game: IGame,
    rest: number
  ): number {
    let me = this;
    return _.findIndex(availableSlots, function (slot: IDateTime) {
      if (round.roundNumber === 1) {
        let allGames = [];
        _.each(tournament.flights, function (flight) {
          allGames = _.concat(
            allGames,
            flight.rounds[flight.rounds.length - 1].games
          );
        });
        let lastGame = me.findLastGameTime(allGames);
        let finalDate = me.addMinutes(lastGame && lastGame.datetime, rest);
        return slot.datetime > finalDate;
      } else {
        let lastGame = me.findLastGameTime(
          tournament.championship[round.roundNumber - 2].games
        );
        let finalDate = me.addMinutes(lastGame && lastGame.datetime, rest);
        return slot.datetime > finalDate;
      }
    });
  }

  findFirstAvailableTime(
    flight: IFlight,
    availableSlots: IDateTime[],
    game: IGame,
    rest: number
  ): number {
    let me = this;
    return _.findIndex(availableSlots, function (slot: IDateTime) {
      let homeTeam = me.findTeamById(
        flight.teamregistrations,
        game.homeTeamId || (game.homeTeam && game.homeTeam.id)
      );
      let awayTeam = me.findTeamById(
        flight.teamregistrations,
        game.awayTeamId || (game.awayTeam && game.awayTeam.id)
      );
      let homeTeamGames = _.filter(flight.games, function (otherGames: IGame) {
        return (
          (otherGames.homeTeam && otherGames.homeTeam.id) ===
            (game.homeTeam && game.homeTeam.id) ||
          (otherGames.awayTeam && otherGames.awayTeam.id) ===
            (game.homeTeam && game.homeTeam.id)
        );
      });
      let awayTeamGames = _.filter(flight.games, function (otherGames: IGame) {
        return (
          (otherGames.homeTeam && otherGames.homeTeam.id) ===
            (game.awayTeam && game.awayTeam.id) ||
          (otherGames.awayTeam && otherGames.awayTeam.id) ===
            (game.awayTeam && game.awayTeam.id)
        );
      });
      let lastGame = me.findLastGameTime(
        _.concat(homeTeamGames, awayTeamGames)
      );
      let finalDate = me.addMinutes(lastGame && lastGame.datetime, rest);
      return slot.datetime > finalDate;
    });
  }

  findTeamById(teamRegistrations: ITeamRegistration[], id): ITeamRegistration {
    return _.find(teamRegistrations, function (tr: ITeamRegistration) {
      return tr.id === id;
    });
  }

  findLastGameTime(combinedGames: IGame[]): IDateTime {
    let gamesWithDates = _.filter(combinedGames, function (game: IGame) {
      return game && game.date && game.date.datetime;
    });
    gamesWithDates = _.orderBy(gamesWithDates, function (game: IGame) {
      return game && game.date && game.date.datetime;
    });
    return gamesWithDates.length ? gamesWithDates.pop().date : null;
  }

  cloneDate(date: Date): Date {
    if (!date) return null;
    return new Date(date.getTime());
  }

  addMinutes(date: Date, minutes: number): Date {
    if (!date) return null;
    let cd = this.cloneDate(date);
    cd.setMinutes(cd.getMinutes() + minutes);
    return cd;
  }

  loadFacilities() {
    let me = this;
    let facilityFilter: IFilter = {
      include: ["availability", "dates"],
    } as IFilter;
    me.Restangular.one("leagues", me.leagueId)
      .all("facilities")
      .getList<IFacility>({ filter: facilityFilter })
      .then(function (facilities) {
        me.facilities = facilities;
        me.calculateFacilityAvailability(me.facilities);
      });
  }

  addAvailability(
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) {
    let me = this;
    let availability = {} as IAvailability;
    availability.startdate = moment(
      startDate + " " + startTime,
      "YYYY-MM-DD h:mmA"
    ).toDate();
    availability.enddate = moment(
      endDate + " " + endTime,
      "YYYY-MM-DD h:mmA"
    ).toDate();
    this.Restangular.one("facilities", me.selectedFacility.id)
      .all("availability")
      .post(availability)
      .then(function (availabilityResult) {
        if (!me.selectedFacility.availability) {
          me.selectedFacility.availability = [];
        }
        availabilityResult.startdate = moment(
          availabilityResult.startdate.datetime
        ).toDate();
        availabilityResult.enddate = moment(
          availabilityResult.enddate.datetime
        ).toDate();

        me.selectedFacility.availability.push(availabilityResult);
        me.newAvailability = {} as IAvailability;
      });
  }

  deleteAvailability(facility: IFacility, availability: IAvailability) {
    let me = this;
    me.Restangular.one("facilities", facility.id)
      .one("availability", availability.id)
      .remove()
      .then(function (result) {
        let idx = facility.availability.indexOf(availability);
        if (idx >= 0) facility.availability.splice(idx, 1);
      });
  }

  selectFacility(facility: IFacility) {
    this.selectedFacility = facility;
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    // this.tournament.flights = [];
    this.localTeams = _.clone(this.tournament.participatingTeams);
    this.sortData();
    this.assignGames();
    this.resolvePromises();
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    if (this.runMode === "create" || this.runMode === "edit") {
      this.loadFacilities();
    }
    // this.localTeams = _.clone(this.tournament.participatingTeams);
    // this.resolvePromises();
  }
}
