import { Component } from "angular-ts-decorators";
import { ITeamLevel } from "../../models/ITeamLevel";
import { AgeGroupService } from "../../services/AgeGroupService";
import { LeagueValueSelector, template } from "../base/LeagueValueSelector";

@Component({
    selector: 'stTeamLevelSelector',
    template
})
export class TeamLevelSelector extends LeagueValueSelector<ITeamLevel> {
    static $inject: string[] = ["AgeGroupService"];

    constructor(private ageGroupService: AgeGroupService) {
        super("team level", "select a team level", "ageGroupId");
    }

    label(value: ITeamLevel) {
        return value?.name;
    }

    async load(ageGroupId: string) {
        this.values = await this.ageGroupService.getTeamLevels(ageGroupId);
        this.values.forEach((teamLevel) => {
            if (teamLevel?.id === this.teamLevelId) {
                this.selected = teamLevel;
            }
        })
    }
}