import {IStandingsAlgorithm} from './IStandingsAlgorithm';
import {ITeamRegistration} from '../models/ITeamRegistration';
import _ from 'lodash';

export class StandingsAlgorithmWTHTH2OAPA implements IStandingsAlgorithm {

    public sortStandings(teamregistrations: ITeamRegistration[]): ITeamRegistration[] {
        return  _.orderBy(teamregistrations, ['wins', 'hth2o', 'paa'], ['desc', 'desc', 'asc']); 
    }    
}
