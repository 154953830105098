import { ITournament } from '../models/ITournament';
import { IFilter } from '../models/IFilter';
import { IGame } from '../models/IGame';
import { IDateTime } from '../models/IDateTime';
import { IFacility } from '../models/IFacility';
import { IRoleInfo } from '../models/IRoleInfo';
import { IUser } from '../models/IUser';
import { ITeam } from '../models/ITeam';
import _ from 'lodash';
import { Injectable } from 'angular-ts-decorators';

@Injectable('PermissionsService')
export class PermissionsService {
    static $inject = [];
    constructor() {

    }

    generateRoleInfo(roleInfo: IRoleInfo) {
        roleInfo.isAdmin = roleInfo && roleInfo.roles && roleInfo.roles.indexOf('admin') > -1;
    }

    isManager(roleInfo: IRoleInfo, team: ITeam, userInfo: IUser) {
        if (!roleInfo || !team || !userInfo) return false;
        this.generateRoleInfo(roleInfo);
        return (roleInfo && roleInfo.isAdmin)
            || (roleInfo && roleInfo.isLeagueAdmin)
            || (roleInfo && roleInfo.isAgeGroupDirector)
            || (userInfo && team && userInfo.id === team.managerId)
            || this.isTeamDirector(roleInfo, team, userInfo);
    }

    isTeamDirector(roleInfo: IRoleInfo, team: ITeam, userInfo: IUser) {
        if (!roleInfo || !team || !userInfo) return false;
        this.generateRoleInfo(roleInfo);
        let me = this;
        let teamDirector = false;
        if (!team) return teamDirector;
        _.each(team.positions, function(position) {
            if (userInfo && userInfo.id === position.userId && position.positionType === 'team administrator') {
                teamDirector = true;
            }
        });
        return teamDirector;
    }

    isScoreKeeper(roleInfo: IRoleInfo, team: ITeam, userInfo: IUser) {
        if (!roleInfo || !team || !userInfo) return false;
        this.generateRoleInfo(roleInfo);
        let me = this;
        let scoreKeeper = false;
        if (!team) return scoreKeeper;
        _.each(team.positions, function(position) {
            if (userInfo && userInfo.id === position.userId && position.positionType === 'scorekeeper') {
                scoreKeeper = true;
            }
        });
        return me.isManager(roleInfo, team, userInfo) || scoreKeeper;
    }

    /**
     * 
     */
    isTeamMember(roleInfo: IRoleInfo, team: ITeam, userInfo: IUser) {
        if (!roleInfo || !team || !userInfo) return false;
        this.generateRoleInfo(roleInfo);
        let me = this;
        let isStaff = false;
        if (roleInfo.roles && roleInfo.roles.indexOf('teamMember') > -1) {
            return true;
        }
        _.each(team.positions, function(position) {
            if (userInfo && userInfo.id === position.userId) {
                isStaff = true;
            }
        });
        if (isStaff) return true;
        return this.isManager(roleInfo, team, userInfo);
    }

    canSeeContactInformation(roleInfo: IRoleInfo, team: ITeam, userInfo: IUser) {
        if (!roleInfo || !team || !userInfo) return false;
        this.generateRoleInfo(roleInfo);
        return this.isManager(roleInfo, team, userInfo);
    }
}
