import { IPlayer } from "../../../models/IPlayer";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IUser } from "../../../models/IUser";
import { PermissionsService } from "../../../services/PermissionsService";
import { MembersModal } from "./Members.modal.controller";
import { MembersUploadModal } from "./Members.upload.modal.controller";
import * as angular from "angular";
import { IPosition } from "../../../models/IPosition";
import { ITeam } from "../../../models/ITeam";
import { TeamService } from "../../../services/TeamService";
import { UIComponent } from "../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import { ITeamMember } from "../../../models/ITeamMember";
import _ from "lodash";
import moment from "moment";
// import moment from 'moment';
import template from "./Members.template.html";

@Component({
  selector: "stMembersManager",
  template,
})
export class MembersManager extends UIComponent {
  static $inject: string[] = [
    "TeamService",
    "PermissionsService",
    "$uibModal",
    "$q",
  ];
  @Input()
  team: ITeam;
  @Input()
  user: IUser;
  @Input()
  roles: IRoleInfo;
  @Input()
  mode: string;

  members: IPlayer[];

  /**
   * Creates an instance of StaffManager.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf StaffManager
   */
  constructor(
    private teamService: TeamService,
    private permissionsService: PermissionsService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $q: angular.IQService
  ) {
    super();
  }

  private openModal(resolver: any): ng.IPromise<IPlayer> {
    return this.modal<IPlayer>(
      this.$uibModal,
      require("./Members.modal.template.html"),
      MembersModal,
      resolver
    );
  }

  private openUploadModal(resolver: any): ng.IPromise<IPlayer[]> {
    return this.modal<IPlayer[]>(
      this.$uibModal,
      require("./Members.upload.modal.template.html").default,
      MembersUploadModal,
      resolver
    );
  }

  /**
   *
   *
   *
   * @memberOf MembersManager
   */
  add() {
    let me = this;
    me.openModal({
      item() {
        return {};
      },
    }).then((result: IPlayer) => {
      me.teamService.addMember(me.team, result).then(function () {
        me.loadMembers();
      });
    });
  }

  /**
   * Crazy method to take the uploaded roster and run them sequentially
   *
   *
   * @memberOf MembersManager
   */
  upload() {
    let me = this;
    me.openUploadModal({
      item() {
        return {};
      },
      team() {
        return me.team;
      },
    }).then((result: IPlayer[]) => {
      let runSequentially = function (r: IPlayer[]) {
        let previous = me.$q.when(null); // need an empty promise to start
        _.each(r, function (player) {
          // loop over each player
          previous = previous.then(function () {
            // chain the previous promise to the next player
            return me.teamService.addMember(me.team, player); // return the promise
          });
        });
        return previous;
      };
      runSequentially(result).then((r) => me.loadMembers());
    });
  }

  edit(item: IPlayer) {
    let me = this;
    me.openModal({
      item() {
        return item;
      },
    }).then((result: IPlayer) => {
      me.teamService.updateMember(me.team, result).then(function () {
        me.loadMembers();
      });
    });
  }

  /**
   *
   *
   * @param {IPosition} position
   * @returns
   *
   * @memberOf MembersManager
   */
  remove(member: IPlayer) {
    if (!confirm("are you sure you would like to remove this team member?"))
      return false;
    let me = this;
    me.teamService.removeMember(me.team, member).then(function () {
      me.loadMembers();
    });
  }

  /**
   *
   *
   * @private
   *
   * @memberOf MembersManager
   */
  private loadMembers() {
    let me = this;
    let resolveMembers = (positions: IPlayer[]) => {
      _.each(positions, (position) => {
        position.birthday = moment(position.birthday).toDate();
      });
      me.members = positions;
    };
    if (
      me.team &&
      me.user &&
      me.roles &&
      me.permissionsService.isTeamMember(me.roles, me.team, me.user)
    ) {
      me.teamService.getMembers(me.team).then(resolveMembers);
    }
  }

  /**
   *
   *
   * @param {any} changesObj
   *
   * @memberOf MembersManager
   */
  $onChanges(changesObj) {
    this.loadMembers();
  }
}
