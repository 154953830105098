import { Component, Input } from "angular-ts-decorators";
import { IRestResource } from "../../../models/IRestResource";
import { IWaiverService } from "../iwaiverservice";
import { IUser } from "../../../models/IUser";
import { IWaiver } from "../iwaiver";
import template from "./personalwaivers.html";
import _ from "lodash";
import { IModalService } from "angular-ui-bootstrap";
import { StateService } from "@uirouter/core";

const name: string = "personalWaivers";

@Component({
  selector: name,
  template,
})
export class PersonalWaivers {
  static $inject: string[] = ["$state", "$uibModal"];

  @Input() resource: IRestResource;
  @Input() waiverService: IWaiverService;
  waivers: IWaiver[];
  teamWaivers: IWaiver[];
  @Input() user: IUser;

  modalOpened: boolean;

  constructor(private $state: StateService, private $uibModal: IModalService) {}

  $onInit() {}

  $onChanges() {
    if (this.waiverService && this.resource && this.user) {
      this.waiverService
        .getMyWaivers(this.resource, this.user)
        .then((waivers) => {
          this.waivers = _.filter(waivers, (waiver: IWaiver) => {
            return (
              !waiver.waiversignatures || waiver.waiversignatures.length === 0
            );
          });

          let waiversScoped = _.filter(this.waivers, (waiver: IWaiver) => {
            return waiver.required;
          });

          if (waiversScoped && waiversScoped.length) {
            if (this.modalOpened) return;
            this.$uibModal
              .open({
                size: "md",
                templateUrl: "waivers_notification.html",
                controller: [
                  "waivers",
                  function (waiversInput) {
                    this.waivers = waiversInput;
                  },
                ],
                controllerAs: "$ctrl",
                resolve: {
                  waivers() {
                    return waiversScoped;
                  },
                },
              })
              .result.then((result) => {});
            this.modalOpened = true;
          }
        });

      this.waiverService.getMyTeamWaivers().then((waivers) => {
        this.teamWaivers = _.filter(waivers, (waiver) => {
          return waiver.waiver !== true;
        });
      });
    }
  }
}
