import { ICollection, IService } from 'restangular';
import { IFilter } from '../models/IFilter';
import { ILeagueScope } from '../models/ILeagueScope';
import { ISponsor } from '../models/ISponsor';
import { StateService } from '@uirouter/angularjs';

export class SponsorsController {
    static $inject = ['$state', '$scope', 'Restangular', '$timeout', 'Upload'];
    newSponsor: ISponsor;
    dataService: ICollection;
    constructor(private $state: StateService, private $scope: ILeagueScope, private Restangular: IService, private $timeout: ng.ITimeoutService, private Upload: angular.angularFileUpload.IUploadService) {
        this.newSponsor = {} as ISponsor;
        this.dataService = this.Restangular.one('leagues', $scope.league.id).all('sponsors');
    }

    add(sponsor: ISponsor) {
        let me = this;
        let filter: IFilter = {
            include: ['image']
        };
        me.dataService.post(sponsor)
            .then(function(savedSponsor) {
                me.Restangular.one('leagues', me.$scope.league.id).one('sponsors', savedSponsor.id).get({ filter })
                    .then(function(updatedSponsor) {
                        me.$scope.league.leagueData.sponsors.push(updatedSponsor);
                    });
            });
    }

    edit(sponsor: ISponsor) {
        this.setEditing(sponsor, true);
    }

    setEditing(sponsor: ISponsor, editing: boolean) {
        if (!sponsor.__uistate) sponsor.__uistate = { editing: false };
        sponsor.__uistate.editing = editing;
    }

    delete(sponsor: ISponsor) {
        let me = this;
        me.Restangular.one('leagues', me.$scope.league.id).one('sponsors', sponsor.id).remove()
            .then(function(result) {
                let idx = me.$scope.league.leagueData.sponsors.indexOf(sponsor);
                me.$scope.league.leagueData.sponsors.splice(idx, 1);
            });
    }

    save(sponsor: ISponsor) {
        let me = this;
        me.Restangular.one('leagues', me.$scope.league.id).one('sponsors', sponsor.id).customPUT(sponsor)
            .then(function(response) {
                me.setEditing(sponsor, false);
            });
    }

    uploadPhoto(file, destination: ISponsor) {
        if (!file) return;
        let me = this;

        let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
            method: 'POST',
            data: { file },
            url: 'api/files/upload'
        };
        file.upload = me.Upload.upload(fileUploadConfig);
        file.upload.then(function(response) {
            me.$timeout(function() {
                destination.image = response.data;
                destination.imageId = response.data.id;
            });
        });
    }
}
