import { VideosModalController } from "./Videos.modal.controller";
import { IListComponent } from "../../core/IListComponent";
import { UIComponent } from "../../core/UIComponent";
import { IVideo } from "../../models/IVideo";
import { IRestResource } from "../../models/IRestResource";
import { IVideoService } from "../../services/IVideoService";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./Videos.template.html";

@Component({
  selector: "stVideos",
  template,
})
export class VideosComponent
  extends UIComponent
  implements IListComponent<IVideo>
{
  static $inject: string[] = ["$uibModal", "$sce"];
  @Input()
  restResource: IRestResource;
  @Input()
  mode: string;
  @Input()
  videoService: IVideoService;
  videos: IVideo[];

  /**
   * Creates an instance of VideosComponent.
   *
   * @param {TeamService} teamService
   * @param {angular.ui.bootstrap.IModalService} $uibModal
   *
   * @memberOf VideosComponent
   */
  constructor(
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $sce: angular.ISCEService
  ) {
    super();
  }

  /**
   *
   *
   * @private
   * @param {*} resolver
   * @returns {ng.IPromise<IVideo>}
   *
   * @memberOf VideosComponent
   */
  private openModal(resolver: any): ng.IPromise<IVideo> {
    return this.modal<IVideo>(
      this.$uibModal,
      require("./Videos.modal.template.html").default,
      VideosModalController,
      resolver
    );
  }

  /**
   *
   *
   *
   * @memberOf VideosComponent
   */
  add() {
    let me = this;
    me.openModal({
      item() {
        return {};
      },
    }).then(function (result: IVideo) {
      me.videoService.addVideo(me.restResource, result).then((news: IVideo) => {
        me.videos.push(news);
      });
    });
  }

  /**
   *
   *
   * @param {IVideo} video
   *
   * @memberOf VideosComponent
   */
  edit(video: IVideo) {
    let me = this;
    me.openModal({
      item() {
        return video;
      },
    }).then(function (result: IVideo) {
      me.videoService.updateVideo(me.restResource, result);
    });
  }

  /**
   *
   *
   * @param {IVideo} video
   *
   * @memberOf VideosComponent
   */
  remove(video: IVideo) {
    let me = this;
    me.videoService.removeVideo(me.restResource, video);
    let idx = me.videos.indexOf(video);
    if (idx >= 0) me.videos.splice(idx, 1);
  }

  /**
   *
   *
   * @param {IVideo} video
   * @returns
   *
   * @memberOf VideosComponent
   */
  negotiate(video: IVideo) {
    switch (video.type.type) {
      case "youtube":
        return this.trust(
          "https://www.youtube.com/embed/" + this.parseYoutube(video.url)
        );
      case "vimeo":
        return this.trust(
          "https://player.vimeo.com/video/" + this.parseVimeo(video.url)
        );
    }
  }

  parseYoutube(url: string): string {
    if (!url) return url;
    if (url.indexOf("?v=") >= 0) {
      return url.substr(url.lastIndexOf("?v=") + 3);
    } else if (url.indexOf("/") >= 0) {
      return url.substr(url.lastIndexOf("/") + 1);
    }
  }

  parseVimeo(url: string): string {
    if (!url) return url;
    if (url.indexOf("/") >= 0) {
      return url.substr(url.lastIndexOf("/") + 1);
    }
  }

  /**
   *
   *
   * @private
   * @param {string} url
   * @returns
   *
   * @memberOf VideosComponent
   */
  private trust(url: string) {
    return this.$sce.trustAsResourceUrl(url);
  }

  /**
   * Event called when there are model property changes
   *
   * @param {any} changesObj
   * @returns
   *
   * @memberOf VideosComponent
   */
  $onChanges(changesObj) {
    let me = this;
    if (!me.restResource || !me.restResource.id) return;
    me.videoService
      .getVideos(me.restResource)
      .then((videos: IVideo[]) => {
        me.videos = videos;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   *
   *
   *
   * @memberOf VideosComponent
   */
  $onInit() {}
}
