import { IFilter } from "../models/IFilter";
import { IGame } from "../models/IGame";
import { Injectable } from "angular-ts-decorators";
import { IService } from "restangular";
import { IHttpService, IHttpPromiseCallbackArg, IPromise } from "angular";
import { ITeamRegistration } from "../models/ITeamRegistration";

@Injectable("GameService")
export class GameService {
  static $inject = ["Restangular", "$http"];
  constructor(private Restangular: IService, private $http: IHttpService) {}

  getGame(id: string): ng.IPromise<IGame> {
    let filter: IFilter = {
      include: [
        { date: "facility" },
        { homeTeam: "team" },
        { awayTeam: "team" },
      ],
    } as IFilter;
    return this.Restangular.one("games", id).get({ filter });
  }

  updateGame(game: IGame) {
    return this.$http
      .patch<IGame>(`/api/games/${game.id}`, game)
      .then(this.unwrapResponse);
  }

  scheduleGame(game: IGame): ng.IPromise<IGame> {
    return this.$http
      .post<IGame>("/api/games/schedule", game)
      .then(this.unwrapResponse);
  }

  deleteAwayGame(
    awayTeamRegistration: ITeamRegistration,
    game: IGame
  ): IPromise<IGame> {
    if (!game || !game.id) {
      return Promise.resolve(game);
    }

    return this.$http
      .delete<IGame>(
        `/api/teamregistrations/${awayTeamRegistration?.id}/awayGames/${game?.id}`
      )
      .then(this.unwrapResponse)
      .then((response) => {
        return this.$http.patch<IGame>(`/api/datetimes/${game.dateId}`, {
          taken: false,
        });
      })
      .then((response) => {
        return game;
      });
  }

  batchSchedule(games: IGame[]): IPromise<IGame[]> {
    return this.$http
      .post<IGame[]>(`/api/games/batch`, games)
      .then(this.unwrapResponse);
  }

  private unwrapResponse<T>(response: IHttpPromiseCallbackArg<T>) {
    return response && response.data;
  }
}
