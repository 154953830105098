import { Component, Input, Output } from "angular-ts-decorators";
import template from "./wizard.html";
import { Step } from "./step";
import "./wizard.scss";

@Component({
  selector: "wizard",
  transclude: true,
  template,
})
export class Wizard {
  private steps: Step[];
  private currentStep: number;
  private selectedStep: Step;

  /**
   *
   *
   * @memberof Wizard
   */
  @Output() public onFinish: () => {};

  /**
   *
   *
   * @type {string}
   * @memberof Wizard
   */
  @Input("@") nextLabel: string;

  /**
   *
   *
   * @type {string}
   * @memberof Wizard
   */
  @Input("@") previousLabel: string;

  /**
   *
   *
   * @type {string}
   * @memberof Wizard
   */
  @Input("@") finishLabel: string;
  constructor() {
    this.currentStep = 0;
    this.steps = [];
  }

  $onInit() {
    this.nextLabel = this.nextLabel || "next";
    this.previousLabel = this.previousLabel || "previous";
    this.finishLabel = this.finishLabel || "finish";
  }

  $onChanges(value) {}
  /**
   *
   *
   * @param {Step} step
   * @memberof Wizard
   */
  public addStep(step: Step) {
    step.index = this.steps.push(step) - 1;
    if (this.steps.length === 1) {
      // if this is the first step
      step.selected = true;
      this.selectedStep = step;
    }
  }

  /**
   *
   *
   * @memberof Wizard
   */
  public nextStep() {
    if (this.isLastStep()) {
      // guard against bounds and finish wizard
      this.finishWizard();
      return;
    }
    if (
      this.selectedStep.canExit === true ||
      this.selectedStep.canExit === undefined
    ) {
      this.currentStep++;
      this.navigateStep();
    }
  }

  /**
   *
   *
   * @memberof Wizard
   */
  public previousStep() {
    if (this.isFirstStep()) {
      // guard against bounds
      return;
    }
    this.currentStep--;
    this.navigateStep();
  }

  /**
   *
   *
   * @param {Step} step
   * @memberof Wizard
   */
  public goToStep(step: Step) {
    if (
      this.selectedStep.canExit === true ||
      this.selectedStep.canExit === undefined
    ) {
      this.currentStep = step.index;
      this.navigateStep();
    }
  }

  /**
   *
   *
   * @returns
   * @memberof Wizard
   */
  public isFirstStep() {
    return this.currentStep === 0;
  }

  /**
   *
   *
   * @returns
   * @memberof Wizard
   */
  public isLastStep() {
    return this.currentStep === this.steps.length - 1;
  }

  /**
   *
   *
   * @memberof Wizard
   */
  public finishWizard() {
    this.onFinish();
  }

  /**
   *
   *
   * @private
   * @memberof Wizard
   */
  private navigateStep() {
    this.selectedStep.completed = true;
    this.selectedStep.selected = false;
    this.steps[this.currentStep].selected = true;
    this.selectedStep = this.steps[this.currentStep];
    this.selectedStep.onEnter();
  }
}
