import { IComponentController } from "angular";
import { Component, Input } from "angular-ts-decorators";
import { IAgeGroup } from "../../../../models/IAgeGroup";
import { IDivision } from "../../../../models/IDivision";
import { IInvitation } from "../../../../models/IInvitation";
import { IRoundRobin } from "../../../../models/IRoundRobin";
import { ITeamLevel } from "../../../../models/ITeamLevel";
import { ITeamRegistration } from "../../../../models/ITeamRegistration";
import { StandingsService } from "../../../../services/StandingsService";
import { TeamRegistrationService } from "../../../../services/TeamRegistrationService";
import template from "./index.html";

@Component({
  selector: "stRoundRobinInvite",
  template,
})
export class RoundRobinInvite implements IComponentController {
  static $inject = ["TeamRegistrationService", "StandingsService"];
  @Input()
  registration: ITeamRegistration;

  @Input()
  roundRobin: IRoundRobin;

  public ageGroup: IAgeGroup;
  public teamLevel: ITeamLevel;
  public division: IDivision;
  public otherTeams: ITeamRegistration[];

  public isSearchCollapsed = true;

  constructor(
    private registrationService: TeamRegistrationService,
    private standingsService: StandingsService
  ) {}

  $onInit() {
    this.ageGroup = this.registration.ageGroup;
    this.teamLevel = this.registration.teamLevel;
    this.division = this.registration.division;
    this.refresh();
  }

  refresh() {
    this.registrationService
      .getTeamsInDivisionWithRoundRobinInvites(
        this.registration,
        this.division,
        this.roundRobin
      )
      .then((teams) => {
        this.otherTeams = _.filter(
          this.standingsService.calculateStandingsOfTeamRegistrations(teams),
          (team: ITeamRegistration) => {
            return team.id !== this.registration.id; // only other teams
          }
        );
      });
  }

  async inviteTeam(otherTeam: ITeamRegistration) {
    const invitation = await this.registrationService.inviteTeamToRoundRobin(
      this.registration,
      otherTeam,
      this.roundRobin
    );
    this.refresh();
  }

  async uninviteTeam(otherTeam: ITeamRegistration, invitation: IInvitation) {
    const removed = await this.registrationService.uninviteTeamToRoundRobin(
      otherTeam.participants[0],
      this.roundRobin
    );
    this.refresh();
  }

  divisionChange(division: IDivision) {
    this.division = division;
    this.refresh();
  }

  ageGroupChange(ageGroup: IAgeGroup) {
    this.ageGroup = ageGroup;
  }

  teamLevelChange(teamLevel: ITeamLevel) {
    this.teamLevel = teamLevel;
  }
}
