import { UIComponent } from "../../../../core/UIComponent";
import { Component, Input, Output } from "angular-ts-decorators";
import template from "./create.template.html";

const name = "stCreateRegistration";

@Component({
  selector: name,
  template,
})
export class CreateRegistration extends UIComponent {
  static $inject: string[] = [];
  static Name: string = name;

  constructor() {
    super();
  }

  $onInit() {}

  $onChanges(changeObj) {}
}
