import { IComponentController } from "angular";

export class UIComponent implements IComponentController {
  mode: string;

  $onInit() {}

  ifEditing() {
    return this.mode === "edit";
  }

  modal<T>(
    modalService: angular.ui.bootstrap.IModalService,
    templateUrl: string,
    controller: any,
    resolver: any
  ): ng.IPromise<T> {
    let modalConfig: any = {
      template: templateUrl,
      size: "lg",
    };
    if (controller) {
      modalConfig.controller = controller;
      modalConfig.controllerAs = "$ctrl";
    }
    if (resolver) {
      modalConfig.resolve = resolver;
    }
    return modalService.open(modalConfig).result;
  }
}
