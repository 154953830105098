import { UIComponent } from "../../../core/UIComponent";
import { PagesModalService } from "../pages.modal.service";
import { IPage } from "../../../models/IPage";
import { PagesEditor } from "../editor/component";
import { IRestResource } from "../../../models/IRestResource";
import { IPageService } from "../../../services/IPagesService";
import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";
import { IModalService, IModalSettings } from "angular-ui-bootstrap";

let name: string = "stPages";

@Component({
  selector: name,
  template,
})
export class PagesComponent extends UIComponent {
  static Name: string = name;
  static $inject = ["$uibModal", "PagesModalService"];

  @Input()
  restResource: IRestResource;

  @Input()
  service: IPageService;

  @Input()
  mode: string;

  pages: IPage[];

  columns: [{ field: "title" }, { field: "modified" }, { field: "created" }];

  options: {
    enableFiltering: false;
  };

  /**
   *
   */
  constructor(
    private modalService: IModalService,
    private pagesModalService: PagesModalService
  ) {
    super();
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    if (this.restResource && this.service) {
      this.service
        .getPages(this.restResource)
        .then((page) => {
          this.pages = page;
        })
        .catch(() => {
          this.pages = [];
        });
    }
  }

  add() {
    this.pagesModalService
      .openModal({} as IPage)
      .then((page: IPage) => {
        return this.service.addPage(this.restResource, page);
      })
      .then((page) => {
        this.pages.push(page);
      });
  }

  delete(page: IPage) {
    if (!confirm("are you sure you want to remove this page?")) {
      return;
    }
    this.service.removePage(this.restResource, page).then(() => {
      let idx = this.pages.indexOf(page);
      if (idx >= 0) this.pages.splice(idx, 1);
    });
  }

  edit(page: IPage) {
    this.pagesModalService.openModal(page).then((pageResult) => {
      this.service.updatePage(this.restResource, pageResult);
    });
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {}
}
