import { IComponentController, IOnChangesObject } from "angular";
import { Input, Output } from "angular-ts-decorators";

export const template = `<st-select label="{{$ctrl.fieldLabel}}" default-option="{{$ctrl.placeHolder}}" value="$ctrl.selected" label-method="$ctrl.label(value)" options="$ctrl.values" list-type="object" on-change="$ctrl.change(value)"></st-select>`;
export abstract class LeagueValueSelector<T> implements IComponentController {
    
    @Input()
    public disabled: boolean;

    @Input()
    public leagueId: string;

    @Input()
    public seasonId: string;

    @Input()
    public ageGroupId: string;

    @Input()
    public divisionId: string;

    @Input()
    public teamLevelId: string;

    @Output() 
    onChange: ({}) => {};

    change(value: T) {
        this.onChange({value});
    }

    public fieldLabel: string;
    public placeHolder: string;
    private changesField: string;

    constructor(fieldLabel: string, placeHolder: string, changesField: string) {
        this.fieldLabel = fieldLabel;
        this.placeHolder = placeHolder;
        this.changesField = changesField;
    }

    selected: T;
    public values: T[];

    $onChanges(changes: IOnChangesObject) {
        if (changes[this.changesField]?.currentValue) {
            this.load(changes[this.changesField].currentValue);
        }
    }

    abstract label(value: T): string;

    abstract load(seasonId: string): void;
}