import { NgModule } from "angular-ts-decorators";
import { PracticePlans } from "./practiceplans";
import { PracticePlansService } from "./praciteplans.service";
import { PlanCreator } from "./plancreator/plancreator";
import { PlanManager } from "./planmanager/planmanager";

@NgModule({
  id: "practice-plan-module",
  declarations: [PracticePlans, PlanCreator, PlanManager],
  imports: [],
  providers: [PracticePlansService]
})
export class PracticePlansModule {}
