import {IFile} from '../../models/IFile';
import {INews} from '../../models/INews';
export class NewsModalController {
    static $inject: string[] = ['item'];

    item: INews;

    /**
     * Creates an instance of NewsModalController.
     * 
     * @param {any} item
     * 
     * @memberOf NewsModalController
     */
    constructor(item: INews) {
        this.item = item;
    }    

    imageChange(image: IFile) {
        this.item.banner = image;
        this.item.bannerId = image.id;
    }
}
